import "../styles/academy.css"
import {useEffect, useState} from "react";
import PersonalTaskPreview from "./PersonalTaskPreview";
import CreateNewPersonalTask from "./CreateNewPersonalTask";


function PersonalTasks({userData, assignments, members, sendData, updatePersonalTasks}) {

    const [selectedCategory, setSelectedCategory] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTask, setSelectedTask] = useState({});
    const [selectedUser, setSelectedUser] = useState("Все");
    const itemsPerPage = 8;
    const [userNames, setUserNames] = useState([]);
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [personalTaskPreview, setPersonalTaskPreview] = useState(false);
    const [createNewPersonalTask, setCreateNewPersonalTask] = useState(false);

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' }; // Возвращает 'Unknown', если член не найден
    }

    const loadUserNames = () => {
        const userIds = assignments.map(task => task.receiver);
        const uniqueUserIds = Array.from(new Set(userIds));
        const names = ["Все", ...uniqueUserIds.map(id => {
            const member = getMemberByID(id);
            return member ? member.nickname : 'Unknown';
        })];
        setUserNames(names);
    }

    useEffect(() => {
        loadUserNames();
    }, [members, assignments]);


    useEffect(() => {
        const filteredTasks = selectedUser === "Все"
            ? assignments
            : assignments.filter(task => getMemberByID(task.receiver)?.nickname === selectedUser);


        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(filteredTasks.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(filteredTasks.length / itemsPerPage));
    }, [currentPage, assignments, selectedUser]);

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handleSavePersonalTask = async (data) => {
        const response = await sendData("SAVE_PERSONAL_TASK_CHANGES", "UserManager", data);
        if(response.response_code === 200){
            updatePersonalTasks(data, "edit");
            closePopups();
        }
    }

    const handlePersonalTaskDelete = async (data) => {
        const response = await sendData("DELETE_PERSONAL_TASK", "UserManager", {taskID: data.ID});
        if(response.response_code === 200){
            updatePersonalTasks({taskID: data.ID, status: 3}, "edit");
            closePopups();
        }
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    const handleClickPersonalTask = (task) => {
        setSelectedTask(task);
        setPersonalTaskPreview(true);
    }

    const closePopups = () => {
        setPersonalTaskPreview(false);
        setCreateNewPersonalTask(false);
    }

    const createPersonalTask = async (data) => {
        const response = await sendData("CREATE_NEW_PERSONAL_TASK", "UserManager", data);
        if(response.response_code === 200){
            data.ID = response.lastID;
            data.createdBy = userData.ID;
            data.createdAt = response.creationTime;
            data.status = 0;
            updatePersonalTasks(data, "add");
            closePopups();
        }
    }

    return (
        <>
            {personalTaskPreview && (
                <PersonalTaskPreview selectedTask={selectedTask} backButton={closePopups} members={members} handleSavePersonalTask={handleSavePersonalTask} handlePersonalTaskDelete={handlePersonalTaskDelete}/>
            )}
            {createNewPersonalTask && (
                <CreateNewPersonalTask members={members} backButton={closePopups} createPersonalTask={createPersonalTask}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i
                        className="bi bi-clipboard2-data-fill"></i> Персональные задания</span>
                    <select style={{marginTop: '3%'}} name="divisions" id="divisions" value={selectedUser}
                            onChange={(e) => setSelectedUser(e.target.value)}>
                        {userNames.map((user, index) => (
                            <option
                                key={index}>{user}</option>
                        ))}
                    </select>
                    <button id="new-task" style={{marginLeft: '2%'}} onClick={() => setCreateNewPersonalTask(true)}><i
                        className="bi bi-pen-fill"></i> Создать новое персональное задание
                    </button>
                    <div className="not-done-tasks-container">
                        <div className="table-label">
                            <span className="table-label-text">Academy Assignments</span>
                        </div>
                        <div className="tasks-table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th>#ID</th>
                                    <th>Назначено пользователю</th>
                                    <th>Создано пользователем</th>
                                    <th>Название</th>
                                    <th>Описание</th>
                                    <th>Причина</th>
                                    <th>Статус</th>
                                    <th>Дата создания</th>
                                    <th>Последнее изменение</th>
                                    <th>Время изменения</th>
                                </tr>
                                </thead>
                                <tbody>
                                {currentItems.filter(t => t.status < 3).map((task, index) => (
                                    <tr key={index} id="table-row" onClick={() => handleClickPersonalTask(task)}>
                                        <th>{index + 1}</th>
                                        <th>{getMemberByID(task.receiver).nickname}</th>
                                        <th>{getMemberByID(task.createdBy).nickname}</th>
                                        <th id="description">{task.name}</th>
                                        <th id="description">{task.description}</th>
                                        <th id="description">{task.reason ? task.reason : "Не указана"}</th>
                                        <th className={`status ${task.status === 0 ? "not-started" : task.status === 1 ? "on-verify" : "done"}`}>
                                            <div className="status-indicator"></div>
                                            {task.status === 0 ? "Не начато" : task.status === 1 ? "На проверке" : "Выполнено"}
                                        </th>
                                        <th>{task.createdAt}</th>
                                        <th>{task.lastEditBy == null ? "Отсутствует" : getMemberByID(task.lastEditBy).nickname}</th>
                                        <th>{task.lastEditAt == null ? "Отсутствует" : task.lastEditAt}</th>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination">
                            {currentPage > 1 && (
                                <i className="bi bi-arrow-left"
                                   onClick={handleBackPage}></i>
                            )}
                            <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                            {totalPages > 0 && (
                                <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PersonalTasks;
