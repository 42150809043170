import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function PreviewVerifyPersonalTask({backButton, sendData, selectedTask, cancelVerifyPersonalTask}) {

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Персональное задание</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <span className="point-answer">{selectedTask.name}</span>

                    <span className="point-header">Описание</span>
                    <span className="point-answer">{selectedTask.description}</span>

                    <span className="point-header">Доказательства/примечания</span>
                    <textarea id="user-proofs" disabled={true} value={selectedTask.proofs ? selectedTask.proofs : "Не указано"}/>
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => cancelVerifyPersonalTask({taskID: selectedTask.ID})}><i className="bi bi-send-fill"></i> Убрать задание с проверки</button>
                </div>
            </div>
        </>
    );
}

export default PreviewVerifyPersonalTask;
