import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function WarnPopup({backButton, sendData, selectedMember, userData}) {

    const [reason, setReason] = useState("");
    const [reasonErr, setReasonErr] = useState("");

    const handleReason = (e) => {
        setReason(e.target.value);
        setReasonErr("");
    }

    const banPfp = async () => {
        let error = false;
        if(reason.length < 4 || reason.length > 64){
            setReasonErr("Причина не может быть меньше 4 и больше 64 символов.");
            error = true;
        }
        if(error) return;

        const response = await sendData("SET_USER_WARN", "UserManager", {
            ID: selectedMember.ID,
            reason: reason
        })

        if(response.response_code === 200){
            backButton();
        }


    }

    return (
        <>
            <div className="darker"></div>
            <div id="pfp-ban-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="pfp-ban-popup-header">Выдать выговор</span>
                <div className="main-container">
                    <span className="pfp-ban-text">Вы собираетесь выдать выговор пользователю {selectedMember.nickname}. Пожалуйста, укажите причину выдачи выговора, чтобы пользователь мог понять, какие действия привели к этому. Выговор будет зарегистрирован в истории пользователя.
                    </span>
                    <div className="user-edit-input-container">
                        <span className={`user-edit-input-header`}>Причина</span>
                        <input type="text" id="user-nickname" className={reasonErr.length > 0 ? "invalid" : ""} autoComplete={"off"} value={reason}
                               onChange={(e) => handleReason(e)}/>
                        {reasonErr.length > 0 && (
                            <span className="error-text">{reasonErr}</span>
                        )}
                    </div>

                    <button id="ban-pfp" onClick={banPfp}><i className="bi bi-exclamation-triangle-fill"></i> Выдать
                        выговор
                    </button>
                </div>
            </div>
        </>
    );
}

export default WarnPopup;
