import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function UserDoneTaskAcademyPopup({backButton, selectedMember, selectedTask, handleCheckTask}) {

    const options = {
        0: "Редактировать",
        1: "Отменить задание"
    };

    const [name, setName] = useState(selectedTask.name);
    const [description, setDescription] = useState(selectedTask.description);
    const [maxPoints, setMaxPoints] = useState(selectedTask.maxPoints);
    const [proofs, setProofs] = useState(selectedTask.details.proofs);
    const [notes, setNotes] = useState(selectedTask.details.notes);
    const [status, setStatus] = useState("Редактировать");
    const [points, setPoints] = useState(selectedTask.details.points);

    const handlePoints = (e) => {
        let raw_points = e.target.value;
        if(raw_points < 0){
            raw_points = 0;
        }else if(raw_points > maxPoints){
            raw_points = maxPoints;
        }
        setPoints(raw_points);
    }

    const handleSendCheck = () => {
        const body = {
            ID: selectedTask.ID,
            userID: selectedMember.ID,
            taskID: selectedTask.details.task_id,
            done_id: selectedTask.details.ID,
            name: name,
            description: description,
            points: points,
            proofs: proofs,
            notes: notes,
            status: status === "Редактировать" ? 1 : 0,
        };

        handleCheckTask(body);
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Задание академии</span>
                <div className="task-container-scrollable">
                    <div className="task-container">
                        <span className="point-header">Название</span>
                        <span className="point-answer">{selectedTask.name}</span>

                        <span className="point-header">Описание</span>
                        <span className="point-answer">{selectedTask.description}</span>

                        <span className="point-header">Отправитель</span>
                        <span className="point-answer">{selectedMember.nickname}</span>

                        <span className="point-header">Отправлено</span>
                        <span className="point-answer">{selectedTask.details.date}</span>

                        <span className="point-header">Максимальный балл</span>
                        <span className="point-answer">{selectedTask.maxPoints}</span>

                        <span className="point-header">Доказательства/примечания</span>
                        <textarea id="user-proofs" value={proofs} disabled={true}/>

                        <span className="point-header">Примечания к заданию</span>
                        <textarea id="user-proofs" value={notes} onChange={(e) => setNotes(e.target.value)}/>

                        <span className="point-header">Оценка</span>
                        <input type={"number"} id="point-answer-input" autoComplete={"off"} value={points} onChange={(e) => handlePoints(e)}/>

                        <span className="point-header">Статус</span>
                        <select name="divisions" id="divisions" value={status}
                                onChange={(e) => setStatus(e.target.value)}>
                            {Object.entries(options).map(([index, option]) => (
                                <option
                                    key={index}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={handleSendCheck}><i className="bi bi-send-fill"></i> Проверить</button>
                </div>
            </div>
        </>
    );
}

export default UserDoneTaskAcademyPopup;
