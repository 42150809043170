import React, {useEffect, useState} from 'react';
import '../styles/utils.css'

function AutocompleteInput({ users, setUserID, selectedID }) {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    useEffect(() => {
        const user = users.find(user => user.ID === selectedID);
        if (user) {
            setInputValue(user.nickname);
        } else {
            setInputValue('');
        }
    }, [selectedID, users]);

    const handleChange = (e) => {
        const value = e.target.value.trim();
        setInputValue(value);

        if (value.length > 3) {
            const regex = new RegExp(`^${value}`, 'i');
            const suggest = users.filter(user => regex.test(user.nickname));
            setSuggestions(suggest);
            if (suggest.length === 1 && suggest[0].nickname.toLowerCase() === value.toLowerCase()) {
                handleSuggestionClick(suggest[0]);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (user) => {
        setInputValue(user.nickname);
        setUserID(user.ID);
        setSuggestions([]);
    };

    const hideEverything = () => {
        setTimeout(() => {
            setSuggestions([]);
        }, 200);
    };
    return (
        <div>
            <input
                type="text"
                value={inputValue}
                onChange={handleChange}
                onBlur={hideEverything}
                placeholder="Введите имя пользователя"
                id="user-selector-auto"
                autoComplete={"off"}
            />
            {suggestions.length > 0 && (
                <ul id="autocomplete-list">
                    {suggestions.map((user, index) => (
                        <li key={index} onClick={() => handleSuggestionClick(user)}>
                            <img src={user.avatar} className="user-pfp"/>
                            <span className="user-name">{user.nickname}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

}
export default AutocompleteInput;