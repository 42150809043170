import "../styles/academy.css"
import {useEffect, useState} from "react";
import DeletedMemberPreview from "./DeletedMemberPreview";

function DeletedUsers({members, ranks, divisions, positions, sendData, updateUsers}) {

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedMember, setSelectedMember] = useState({});
    const [deletedPreview, setDeletedPreview] = useState(false);

    const getFilteredItems = (fromMembers) => {
        return fromMembers
            .filter(member => {
                return member.nickname.toLowerCase().includes(searchQuery);
            })
            .sort((a, b) => b.rank - a.rank);
    };

    useEffect(() => {
        const filtered = getFilteredItems(members.filter(member => member.deactivated === 1));

        const newTotalPages = Math.ceil(filtered.length / itemsPerPage);

        if (currentPage >= newTotalPages) {
            setCurrentPage(newTotalPages === 0 ? 1 : newTotalPages);
        }

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const newCurrentItems = filtered.slice(indexOfFirstItem, indexOfLastItem);

        setCurrentItems(newCurrentItems);
        setTotalPages(newTotalPages);

    }, [searchQuery, currentPage, members]);

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' };
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    const handleSelectUser = (user) => {
        setSelectedMember(user);
        setDeletedPreview(true);
    }

    const closePopups = () => {
        setDeletedPreview(false);
    }

    const activateUserAccount = async (data) => {
        const response = await sendData("ACTIVATE_USER_ACCOUNT", "UserManager", data);
        if(response.response_code === 200){
            updateUsers({
                ID: data.userID,
                deactivated: 0,
                deactivateID: null
            }, "edit");
            closePopups();
        }
    }

    return (
        <>
            {deletedPreview && (
                <DeletedMemberPreview backButton={closePopups} selectedTask={selectedMember} sendData={sendData} activateAccount={activateUserAccount} ranks={ranks} divisions={divisions} positions={positions} getMemberByID={getMemberByID}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i
                        className="bi bi-person-fill-slash"></i> Удаленные пользователи</span>
                    <div className="not-done-tasks-container">
                        <div className="table-label">
                            <span className="table-label-text">Deleted Users</span>
                        </div>
                        <input type="text" autoComplete={"off"} value={searchQuery} style={{marginBottom: '2%'}}
                               onChange={(event) => handleSearchChange(event)} id="search" placeholder="Поиск.."/>
                        <div className="tasks-table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th>#ID</th>
                                    <th>Никнейм</th>
                                    <th>Ранг</th>
                                    <th>Отдел</th>
                                    <th>Должность</th>
                                </tr>
                                </thead>
                                <tbody>
                                {currentItems.map((user, index) => (
                                    <tr key={index} id="table-row" onClick={() => handleSelectUser(user)}>
                                        <th>{index + 1}</th>
                                        <th>{user.nickname}</th>
                                        <th>({user.rank}) {ranks[user.rank]}</th>
                                        <th>{divisions[user.division]}</th>
                                        <th>{positions[user.division][user.position]}</th>

                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination">
                            {currentPage > 1 && (
                                <i className="bi bi-arrow-left"
                                   onClick={handleBackPage}></i>
                            )}
                            <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                            {totalPages > 0 && (
                                <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DeletedUsers;
