import "../styles/tasks.css"
import {useEffect, useState} from "react";
import InactiveCheck from "./InactiveCheck";
import RegCheck from "./RegCheck";

function Requests({userData, updateRequests, sendData, updateRegRequests, members, shouldDisplay, openLink, inactiveRequests, regRequests}) {

    const [selectedCategory, setSelectedCategory] = useState(userData.inactiveReqCheckPerm === 0 ? 1 : 0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTask, setSelectedTask] = useState({});
    const itemsPerPage = 8;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [inactiveCheckPopup, setInactiveCheckPopup] = useState(false);
    const [regCheckPopup, setRegCheckPopup] = useState(false);


    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems((selectedCategory === 0 ? inactiveRequests : regRequests).slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil((selectedCategory === 0 ? inactiveRequests : regRequests).length / itemsPerPage));
    }, [currentPage, regRequests, inactiveRequests, selectedCategory]);

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' }; // Возвращает 'Unknown', если член не найден
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    const selectTask = (task) => {
        setSelectedTask(task);
        if(selectedCategory === 0) setInactiveCheckPopup(true);
        if(selectedCategory === 1) setRegCheckPopup(true);
    }


    const closePopups = () => {
        setInactiveCheckPopup(false);
        setRegCheckPopup(false);
    }

    const checkInactiveRequest = async (data) => {
        const response = await sendData("CHECK_INACTIVE_REQUEST", "UserManager", data);
        if(response.response_code === 200){
            updateRequests({taskID: data.ID, status: 1}, "edit");
            closePopups();
        }
    }

    const checkRegRequest = async (data) => {
        const response = await sendData("CHECK_REG_REQUEST", "UserManager", data);
        if(response.response_code === 200){
            updateRegRequests(data.ID, "delete");
            closePopups();
        }
    }

    return (
        <>
            {inactiveCheckPopup && (
                <InactiveCheck members={members} backButton={closePopups} selectedTask={selectedTask} checkRequest={checkInactiveRequest}/>
            )}
            {regCheckPopup && (
                <RegCheck selectedTask={selectedTask} backButton={closePopups} checkRequest={checkRegRequest}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i className="bi bi-file-earmark-text-fill"></i>Запросы</span>
                    <div className="task-category-list">
                        {shouldDisplay(userData.admin > 0, userData.inactiveReqCheckPerm > 0) && (
                            <div className={`category-option ${selectedCategory === 0 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(0)}>Неактивы
                            </div>
                        )}
                        {shouldDisplay(userData.admin > 0, userData.regCheck > 0) && (
                            <div className={`category-option ${selectedCategory === 1 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(1)}>Регистрация
                            </div>
                        )}
                    </div>
                    {selectedCategory === 0 && (
                        <>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Inactive Requests</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Отправитель</th>
                                            <th>Неактив от</th>
                                            <th>Неактив до</th>
                                            <th>Всего дней</th>
                                            <th>Причина</th>
                                            <th>Дата подачи</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.filter(t => t.status < 1).map((task, index) => (
                                            <tr key={index} id="table-row" onClick={() => selectTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{getMemberByID(task.user).nickname}</th>
                                                <th>{task.dateFrom}</th>
                                                <th>{task.dateTo}</th>
                                                <th>{task.totalDays}</th>
                                                <th>{task.reason ? task.reason : "Не указано"}</th>
                                                <th>{task.createdAt}</th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {selectedCategory === 1 && (
                        <>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Registration Requests</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Отправитель</th>
                                            <th>VKontakte</th>
                                            <th>Forum</th>
                                            <th>Дата подачи</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.map((task, index) => (
                                            <tr key={index} id="table-row" onClick={() => selectTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{task.nickname}</th>
                                                <th><span className="link" onClick={(e) => openLink(task.vk, e)}>{task.vk}</span></th>
                                                <th><span className="link" onClick={(e) => openLink(task.forum, e)}>{task.forum}</span></th>
                                                <th>{task.createdAt}</th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Requests;
