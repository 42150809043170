import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function StageCreatePopup({backButton, selectedTask, stages, createStage}) {

    const [name, setName] = useState("");
    const [nameErr,setNameErr] = useState("");
    const [stage, setStage] = useState(0);
    const [stageErr,setStageErr] = useState("");

    const handleStage = (e) => {
        let raw_stage = parseInt(e.target.value, 10);
        if (raw_stage < 0) {
            raw_stage = 0;
        }
        setStage(raw_stage);
        setStageErr("");
    }

    const isStageExists = (newStage) => {
        return stages.some(st => st.stage === newStage);
    }

    const handleSaveStage = () => {
        let error = false;
        if (isStageExists(stage)) {
            setStageErr("Этап уже существует.");
            error = true;
        }
        if(name.length < 4 || name.length > 64){
            setNameErr("Название не может быть меньше 4 и больше 64 символов.")
            error = true;
        }
        if(error) return;
        createStage({name: name, stage: stage});
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Создание нового этапа</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <input type="text" className={nameErr.length > 0 ? "invalid" : ""} id="point-answer-input" value={name} autoComplete={"off"}
                           onChange={(e) => setName(e.target.value)}/>
                    {nameErr.length > 0 && (
                        <span className="error-text">{nameErr}</span>
                    )}
                </div>

                <div className="task-container">
                    <span className="point-header">Этап</span>
                    <input type="text" className={stageErr.length > 0 ? "invalid" : ""} id="point-answer-input" value={stage} autoComplete={"off"}
                           onChange={(e) => handleStage(e)}/>
                    {stageErr.length > 0 && (
                        <span className="error-text">{stageErr}</span>
                    )}
                </div>

                <div className="buttons-container">
                    <button id="send-task" onClick={handleSaveStage}><i className="bi bi-send-fill"></i> Создать этап
                    </button>
                </div>
            </div>
        </>
    );
}

export default StageCreatePopup;
