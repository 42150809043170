import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function CheckPointTask({backButton, getMemberByID, selectedTask, handleCheckTask}) {

    const options = {
        0: "Одобрено",
        1: "Отказано"
    };
    const [status, setStatus] = useState("Одобрено");
    const [assignment, setAssignment] = useState({})

    const handleSendCheck = () => {
        const body = {
            taskID: selectedTask.ID,
            userID: selectedTask.sender,
            type: selectedTask.type,
            points: selectedTask.maxPoints,
            status: status === "Одобрено" ? 1 : 0,
        };

        handleCheckTask(body);
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Проверка задания на баллы</span>
                <div className="task-container-scrollable">
                    <div className="task-container">
                        <span className="point-header">Название</span>
                        <span className="point-answer">{selectedTask.name}</span>

                        <span className="point-header">Описание</span>
                        <span className="point-answer">{selectedTask.description}</span>

                        <span className="point-header">Отправитель</span>
                        <span className="point-answer">{getMemberByID(selectedTask.sender).nickname}</span>

                        <span className="point-header">Тип</span>
                        <span className="point-answer">{selectedTask.type === "warn" ? "Выговор" : "Workoff"}</span>

                        <span className="point-header">Баллы</span>
                        <span className="point-answer">{selectedTask.maxPoints}</span>


                        <span className="point-header">Доказательства/примечания</span>
                        <textarea id="user-proofs" value={selectedTask.proofs} disabled={true}/>

                        <span className="point-header">Статус</span>
                        <select name="divisions" id="divisions" value={status}
                                onChange={(e) => setStatus(e.target.value)}>
                            {Object.entries(options).map(([index, option]) => (
                                <option
                                    key={index}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={handleSendCheck}><i className="bi bi-send-fill"></i> Проверить</button>
                </div>
            </div>
        </>
    );
}

export default CheckPointTask;
