import "../styles/tasks.css"
import {useEffect, useState} from "react";
import WarrantPreview from "./WarrantPreview";
import WarrantCreatePopup from "./WarrantCreatePopup";

function Warrants({sendData, userData, members, isValidURL, shouldDisplay, openLink}) {

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTask, setSelectedTask] = useState({});
    const itemsPerPage = 8;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [warrants, setWarrants] = useState([]);


    const [warrantPreviewPopup, setWarrantPreviewPopup] = useState(false);
    const [createWarrantPopup, setCreateWarrantPopup] = useState(false);

    useEffect(() => {
        const getWarrants = async () => {
            const data = await sendData("GET_WARRANTS", "UserManager", {});
            setWarrants(data.data);
        }

        getWarrants();
    }, []);

    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(warrants.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(warrants.length / itemsPerPage));
    }, [currentPage, warrants]);

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' };
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    const updateWarants = (data, mode) => {
        if(mode === "edit") {
            setWarrants(warrants => {
                const index = warrants.findIndex(task => task.ID === data.ID);
                if (index !== -1) {
                    return [
                        ...warrants.slice(0, index),
                        {...warrants[index], ...data},
                        ...warrants.slice(index + 1)
                    ];
                }
                return warrants;
            });
        }else if(mode === "add"){
            setWarrants(previousTasks => [data, ...previousTasks]);
        }
    }

    const selectTask = (task) => {
        if(task.agent){
            if(task.agent === userData.ID || userData.admin > 0 || userData.warrantPerm > 0){
                setSelectedTask(task);
                setWarrantPreviewPopup(true);
                return;
            }
        }
        setSelectedTask(task);
        setWarrantPreviewPopup(true);
    }


    const closePopups = () => {
        setWarrantPreviewPopup(false);
        setCreateWarrantPopup(false);
    }

    const takeOrder = async (data) => {
        const response = await sendData("TAKE_ORDER", "UserManager", data);
        if(response.response_code === 200){
            closePopups();
            updateWarants({ID: data.ID, agent: userData.ID, status: 1}, "edit")
        }
    }

    const handleDoneWarrant = async (data) => {
        const response = await sendData("DONE_WARRANT", "UserManager", data);
        if(response.response_code === 200){
            updateWarants({ID: data.ID, status: 2, proofs: data.proofs}, "edit")
            closePopups();
        }
    }

    const handleCancelWarrant = async (data) => {
        const response = await sendData("CANCEL_MY_WARRANT", "UserManager", data);
        if(response.response_code === 200){
            updateWarants({ID: data.ID, agent: null, status: 0}, "edit")
            closePopups();
        }
    }

    const handleSaveOrderChanges = async (data) => {
        const response = await sendData("SAVE_ORDER_CHANGES", "UserManager", data);
        if(response.response_code === 200){
            updateWarants(data)
            closePopups();
        }
    }

    const handleOrderDelete = async (data) => {
        const response = await sendData("DELETE_ORDER", "UserManager", data);
        if(response.response_code === 200){
            updateWarants({ID: data.ID, status: 3}, "edit")
            closePopups();
        }
    }

    const handleCreateWarrant = async (data) => {
        const response = await sendData("CREATE_WARRANT", "UserManager", data);
        if(response.response_code === 200){
            data.ID = response.lastID;
            data.status = 0;
            updateWarants(data, "add");
            closePopups();
        }
    }

    return (
        <>
            {warrantPreviewPopup && (
                <WarrantPreview takeOrder={takeOrder} members={members} handleSaveOrderChanges={handleSaveOrderChanges} handleOrderDelete={handleOrderDelete} handleCancelWarrant={handleCancelWarrant} backButton={closePopups} handleDoneWarrant={handleDoneWarrant} selectedTask={selectedTask} shouldDisplay={shouldDisplay} userData={userData} getMemberByID={getMemberByID} openLink={openLink}/>
            )}
            {createWarrantPopup && (
                <WarrantCreatePopup backButton={closePopups} isValidURL={isValidURL} handleCreateWarrant={handleCreateWarrant}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i className="bi bi-star-fill"></i>Ордера</span>
                    <>
                        <div className="not-done-tasks-container">
                            {shouldDisplay(userData.admin > 0, userData.warrantPerm > 0) && (
                                <button id="new-task" onClick={() => setCreateWarrantPopup(true)} style={{marginTop: '1%'}}><i
                                    className="bi bi-pen-fill"></i> Создать новый ордер
                                </button>
                            )}
                            <div className="table-label">
                                <span className="table-label-text">Warrants</span>
                            </div>
                            <div className="tasks-table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#ID</th>
                                        <th>На гражданина</th>
                                        <th>Уровень розыска</th>
                                        <th>Причина</th>
                                        <th>Статус</th>
                                        <th>Агент</th>
                                        <th>Последние изменения</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentItems.filter(t => t.status < 3).map((task, index) => (
                                        <tr key={index} id="table-row" onClick={() => selectTask(task)}>
                                            <th>{index + 1}</th>
                                            <th>{task.target}</th>
                                            <th>{task.level}</th>
                                            <th>{task.reason}</th>
                                            <th className={`status ${task.status === 0 ? "not-started" : task.status === 1 ? "on-verify" : "done"}`}>
                                                <div className="status-indicator"></div>
                                                {task.status === 0 ? "Не начато" : task.status === 1 ? "Выполняется" : "Выполнено"}
                                            </th>
                                            <th>{task.agent ? getMemberByID(task.agent).nickname : "Назначается"}</th>
                                            <th>{task.date ? task.date : "Изменений не было"}</th>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                {currentPage > 1 && (
                                    <i className="bi bi-arrow-left"
                                       onClick={handleBackPage}></i>
                                )}
                                <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                {totalPages > 0 && (
                                    <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                )}
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
}

export default Warrants;
