import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function PreviewCanceledTask({backButton, selectedTask, getMemberByID}) {

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Просмотр задания</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <span className="point-answer">{selectedTask.name}</span>

                    <span className="point-header">Описание</span>
                    <span className="point-answer">{selectedTask.description}</span>

                    <span className="point-header">Проверено пользователем</span>
                    <span className="point-answer">{getMemberByID(selectedTask.details.verifier).nickname}</span>

                    <span className="point-header">Проверено пользователем</span>
                    <span className="point-answer">{selectedTask.details.verifiedAt}</span>

                    <span className="point-header">Примечания</span>
                    <textarea id="user-proofs" value={selectedTask.details.notes} disabled={true}/>
                </div>
            </div>
        </>
    );
}

export default PreviewCanceledTask;
