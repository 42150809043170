import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function CreateTicket({backButton, createTicket}) {

    const topics = {
        0: "Сообщение о баге",
        1: "Проблема с разрешениями",
        2: "Проблема с отображением",
        3: "Предложение улучшения",
        4: "Интерфейс пользователя",
        5: "Жалоба",
        6: "Логи",
        7: "Другое"
    };
    const [name, setName] = useState("");
    const [topic, setTopic] = useState(topics[0]);

    const [nameErr, setNameErr] = useState("");

    const handleCreate = () => {
        if(name.length < 6 || name.length > 64){
            setNameErr("Суть вопроса не может быть меньше 6 и больше 64 символов.");
            return;
        }

        createTicket({header: name, topic: topic});
    }


    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Создание нового чата</span>
                <div className="task-container">
                    <span className="point-header">Суть вопроса (кратко)</span>
                    <input type="text" className={nameErr.length > 0 ? "invalid" : ""} id="point-answer-input"
                           value={name} autoComplete={"off"}
                           onChange={(e) => setName(e.target.value)}/>
                    {nameErr.length > 0 && (
                        <span className="error-text">{nameErr}</span>
                    )}

                    <span className="point-header">Тема</span>
                    <select name="divisions" id="divisions" value={topic}
                            onChange={(e) => setTopic(e.target.value)}>
                        {Object.entries(topics).map(([index, option]) => (
                            <option
                                key={index}>{option}</option>
                        ))}
                    </select>

                </div>

                <div className="buttons-container">
                    <button id="send-task" onClick={() => handleCreate()}><i className="bi bi-send-fill"></i> Создать чат
                    </button>
                </div>
            </div>
        </>
    );
}

export default CreateTicket;
