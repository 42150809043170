import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";
import AutocompleteInput from "../Utils/AutocompleteInput";

function DeleteMemberPopup({backButton, selectedMember, ranks, positions, divisions, handleDeactivateUser}) {

    const reasons = {
        0: "По собственному желанию",
        1: "Нарушение",
        2: "Другое",
    };

    const [textReason, setTextReason] = useState(null);
    const [reason, setReason] = useState("По собственному желанию");

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Отключение пользователя</span>
                <div className="task-container">
                    <span className="point-header">Никнейм</span>
                    <span className="point-answer">{selectedMember.nickname}</span>


                    <span className="point-header">Ранг</span>
                    <span className="point-answer">({selectedMember.rank}) {ranks[selectedMember.rank]}</span>

                    <span className="point-header">Отдел</span>
                    <span className="point-answer">{divisions[selectedMember.division]}</span>

                    <span className="point-header">Должность</span>
                    <span className="point-answer">{positions[selectedMember.division][selectedMember.position]}</span>

                    <span className="point-header">Причина</span>
                    <select name="divisions" id="divisions" value={reason}
                            onChange={(e) => setReason(e.target.value)}>
                        {Object.entries(reasons).map(([index, option]) => (
                            <option
                                key={index}>{option}</option>
                        ))}
                    </select>

                    {reason === "Другое" && (
                        <>
                            <span className="point-header">Причина</span>
                            <input type="text" id="point-answer-input" value={textReason} autoComplete={"off"}
                                   onChange={(e) => setTextReason(e.target.value)}/>
                        </>
                    )}

                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => handleDeactivateUser(
                        {
                            userID: selectedMember.ID,
                            reason: reason,
                            reasonText: textReason,
                        }
                    )} style={{background: 'rgb(var(--red-color))'}}><i className="bi bi-send-fill"></i> Отключить пользователя
                </button>

                </div>
            </div>
        </>
    );
}

export default DeleteMemberPopup;
