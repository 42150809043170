import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function EndAcademyPopup({backButton, stages, ranks, selectedMember, endAcademy, divisions}) {

    const [division, setDivision] = useState(divisions[0]);

    function getDivisionID(value) {
        return Object.keys(divisions).find(key => divisions[key] === value);
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Конец академии</span>
                <div className="task-container">
                    <span className="point-header">Никнейм академика</span>
                    <span className="point-answer">{selectedMember.nickname}</span>

                    <span className="point-header">Ранг</span>
                    <span className="point-answer">({selectedMember.rank}) {ranks[selectedMember.rank]} -> ({selectedMember.rank + 1}) {ranks[selectedMember.rank + 1]}</span>

                    <span className="point-header">Балл академии</span>
                    <span className="point-answer">{selectedMember.academyMark}</span>

                    <span className="point-header">Выберите отдел</span>
                    <select name="divisions" id="divisions" value={division}
                            onChange={(e) => setDivision(e.target.value)}>
                        {Object.entries(divisions).map(([index, divisionSelect]) => (
                            <option
                                key={index}>{divisionSelect}</option>
                        ))}
                    </select>

                </div>
                <div className="buttons-container">
                <button id="send-task" onClick={() => endAcademy({
                    userID: selectedMember.ID,
                    divisionID: getDivisionID(division)
                })}><i className="bi bi-send-fill"></i> Закончить академию</button>
                </div>
            </div>
        </>
    );
}

export default EndAcademyPopup;
