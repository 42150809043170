import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function InactiveCheck({backButton, selectedTask, members, checkRequest}) {

    const options = {
        0: "Одобрено",
        1: "Отказано"
    };
    const [status, setStatus] = useState("Одобрено");
    const [academyExtension, setAcademyExtension] = useState(false);

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Запрос на неактив</span>
                <div className="task-container">
                    <span className="point-header">Неактив от</span>
                    <span className="point-answer">{selectedTask.dateFrom}</span>

                    <span className="point-header">Неактив от</span>
                    <span className="point-answer">{selectedTask.dateTo}</span>

                    <span className="point-header">Всего дней</span>
                    <span className="point-answer">{selectedTask.totalDays}</span>

                    <span className="point-header">Причина</span>
                    <span className="point-answer">{selectedTask.reason ? selectedTask.reason : "Не указано"}</span>

                    <span className="point-header">Статус</span>
                    <select name="divisions" id="divisions" value={status}
                            onChange={(e) => setStatus(e.target.value)}>
                        {Object.entries(options).map(([index, option]) => (
                            <option
                                key={index}>{option}</option>
                        ))}
                    </select>
                    <label htmlFor="academyExt">
                        <input type="checkbox" id="academyExt" name="academyExt" checked={academyExtension}
                               onChange={(e) => setAcademyExtension(e.target.checked)}/>
                        Продлить академию
                    </label>
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => checkRequest({
                        ID: selectedTask.ID,
                        status: status === "Одобрено" ? 1 : 2,
                        academyExt: academyExtension
                    })}><i className="bi bi-send-fill"></i> Отправить
                    </button>
                </div>
            </div>
        </>
    );
}

export default InactiveCheck;
