import "../styles/tasks.css"
import {useEffect, useState} from "react";
import CheckTaskPopup from "./CheckTaskPopup";
import CheckDivisionTaskPopup from "./CheckDivisionTaskPopup";
import PersonalTaskCheckPopup from "./PersonalTaskCheckPopup";
import CheckPointTask from "./CheckPointTask";

function CheckTasks({userData, pointVerify, updatePointVerifyTasks, sendData, members, verifyTasks, shouldDisplay, updatePersonalTasks, point_assignments, updateVerifyTasks, personalTasks, updateDivisionTasks, divisionTasks}) {

    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedUser, setSelectedUser] = useState("Все");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [userNames, setUserNames] = useState([]);
    const [selectedTask, setSelectedTask] = useState({});
    const [checkTaskPopup, setCheckTaskPopup] = useState(false);
    const [checkDivisionTaskPopup, setCheckDivisionTaskPopup] = useState(false);
    const [checkPersonalTask, setCheckPersonalTask] = useState(false);
    const [checkPointTask, setCheckPointTask] = useState(false);

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' }; // Возвращает 'Unknown', если член не найден
    }

    const loadUserNames = () => {
        const userIds = verifyTasks.map(task => task.sender);
        const uniqueUserIds = Array.from(new Set(userIds));
        const names = ["Все", ...uniqueUserIds.map(id => {
            const member = getMemberByID(id);
            return member ? member.nickname : 'Unknown';
        })];
        setUserNames(names);
    }

    const getPointAssignmentByID = (id) => {
        return point_assignments.find(assignment => assignment.ID === id);
    }

    useEffect(() => {
        loadUserNames();
    }, [members, verifyTasks]);

    const getSelected = () => {
        switch(selectedCategory) {
            case 1:
                return divisionTasks;
            case 2:
                return personalTasks;
            case 0:
            default:
                if (Array.isArray(pointVerify)) {
                    return pointVerify;
                } else if (pointVerify && typeof pointVerify === 'object') {
                    return [pointVerify];
                } else {
                    return [];
                }
        }
    }

    useEffect(() => {
        const tasks = getSelected();
        if (!Array.isArray(tasks)) {
            console.error("Expected an array but received:", tasks);
            return;
        }

        const filteredTasks = selectedUser === "Все"
            ? verifyTasks
            : verifyTasks.filter(task => getMemberByID(task.sender)?.nickname === selectedUser);

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems((selectedCategory === 0 ? filteredTasks : tasks).slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil((selectedCategory === 0 ? filteredTasks : tasks).length / itemsPerPage));
    }, [currentPage, verifyTasks, selectedUser, selectedCategory, divisionTasks, personalTasks, pointVerify]);

    const handleNextPage = () => {
        if (currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handleBackPage = () => {
        if (currentPage - 1 > 0) {
            setCurrentPage(currentPage - 1);
        }
    }

    const handleSelectTask = (task) => {
        setSelectedTask(task);
        if(selectedCategory === 0) setCheckTaskPopup(true);
        if(selectedCategory === 1) setCheckDivisionTaskPopup(true);
        if(selectedCategory === 2) setCheckPersonalTask(true);
        if(selectedCategory === 3) setCheckPointTask(true);
    }

    const closePopups = () => {
        setCheckTaskPopup(false);
        setCheckDivisionTaskPopup(false);
        setCheckPersonalTask(false);
        setCheckPointTask(false);
    }

    const handleCheckTask = async (data) => {
        const response = await sendData("CHECK_ACADEMY_TASK", "UserManager", data);
        if(response.response_code === 200){
            updateVerifyTasks(data.ID, "delete");
            closePopups();
        }
    }

    const checkDivisionTask = async (data) => {
        const response = await sendData("CHECK_DIVISION_TASK", "UserManager", data);
        if(response.response_code === 200){
            updateDivisionTasks({
                taskID: data.taskID,
                status: data.status === 0 ? 0 : 2,
                doneAt: response.doneAt,
                doneBy: data.status === 0 ? null : data.userID
            }, "edit");
            closePopups();
        }
    }

    const handlePersonalCheck = async (data) => {
        const response = await sendData("CHECK_PERSONAL_TASK", "UserManager", data);
        if(response.response_code === 200){
            updatePersonalTasks({taskID: data.taskID, status: data.status}, "edit");
            closePopups();
        }
    }

    const handlePointTaskCheck = async (data) => {
        const response = await sendData("CHECK_POINT_ASSIGNMENT", "UserManager", data);
        if(response.response_code === 200){
            updatePointVerifyTasks(selectedTask.ID, "delete");
            closePopups();
        }
    }

    return (
        <>
            {checkTaskPopup && (
                <CheckTaskPopup userData={userData} selectedTask={selectedTask} getMemberByID={getMemberByID} backButton={closePopups} handleCheckTask={handleCheckTask}/>
            )}
            {checkDivisionTaskPopup && (
                <CheckDivisionTaskPopup selectedTask={selectedTask} getMemberByID={getMemberByID} backButton={closePopups} handleCheckTask={checkDivisionTask}/>
            )}
            {checkPersonalTask && (
                <PersonalTaskCheckPopup selectedTask={selectedTask} getMemberByID={getMemberByID} backButton={closePopups} handleCheckTask={handlePersonalCheck}/>
            )}
            {checkPointTask && (
                <CheckPointTask selectedTask={selectedTask} getMemberByID={getMemberByID} backButton={closePopups} getAssignmentByID={getPointAssignmentByID} handleCheckTask={handlePointTaskCheck}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i className="bi bi-body-text"></i> Проверка заданий</span>
                    <div className="task-category-list">
                        {shouldDisplay(userData.admin > 0, userData.academyPerm > 0) && (
                            <div className={`category-option ${selectedCategory === 0 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(0)}>Академия
                            </div>
                        )}
                        {shouldDisplay(userData.admin > 0, userData.divisionCheck > 0, userData.allDivisionCheckPerm > 0) && (
                            <div className={`category-option ${selectedCategory === 1 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(1)}>Отдел
                            </div>
                        )}
                        {shouldDisplay(userData.admin > 0, userData.academyPerm > 0) && (
                            <div className={`category-option ${selectedCategory === 2 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(2)}>Персональные
                            </div>
                        )}
                        {shouldDisplay(userData.admin > 0, userData.punishPerm > 0) && (
                            <div className={`category-option ${selectedCategory === 3 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(3)}>На баллы
                            </div>
                        )}
                    </div>
                    {selectedCategory === 0 && (
                        <>
                            <select name="divisions" id="divisions" value={selectedUser}
                                    onChange={(e) => setSelectedUser(e.target.value)}>
                                {userNames.map((user, index) => (
                                    <option
                                        key={index}>{user}</option>
                                ))}
                            </select>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Academy Assignments</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Отправитель</th>
                                            <th>Название</th>
                                            <th>Описание</th>
                                            <th>Максимальный балл</th>
                                            <th>Дата отправки</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.map((task, index) => (
                                            <tr key={index} id="table-row" onClick={() => handleSelectTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{getMemberByID(task.sender).nickname}</th>
                                                <th>{task.name}</th>
                                                <th id="description">{task.description}</th>
                                                <th>{task.maxPoints}</th>
                                                <th>{task.date}</th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {selectedCategory === 1 && (
                        <>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Division Assignments</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Отправитель</th>
                                            <th>Название</th>
                                            <th>Описание</th>
                                            <th>Отправлено</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.filter(t => t.status === 1).map((task, index) => (
                                            <tr key={index} id="table-row" onClick={() => handleSelectTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{getMemberByID(task.doneBy).nickname}</th>
                                                <th>{task.name}</th>
                                                <th id="description">{task.description}</th>
                                                <th>{task.doneAt}</th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {selectedCategory === 2 && (
                        <>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Personal Assignments</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Отправитель</th>
                                            <th>Название</th>
                                            <th>Описание</th>
                                            <th>Отправлено</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.filter(t => t.status === 1).map((task, index) => (
                                            <tr key={index} id="table-row" onClick={() => handleSelectTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{getMemberByID(task.receiver).nickname}</th>
                                                <th>{task.name}</th>
                                                <th id="description">{task.description}</th>
                                                <th>{task.createdAt}</th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {selectedCategory === 3 && (
                        <>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Warn/Workoff Assignments</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Название</th>
                                            <th>Описание</th>
                                            <th>Баллы</th>
                                            <th>Отправитель</th>
                                            <th>Отправлено</th>
                                            <th>Тип</th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.map((task, index) => (
                                            <tr key={index} id="table-row" onClick={() => handleSelectTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{task.name}</th>
                                                <th id="description">{task.description}</th>
                                                <th>{task.maxPoints}</th>
                                                <th>{getMemberByID(task.sender).nickname}</th>
                                                <th>{task.createdAt}</th>
                                                <th>{task.type === "warn" ? "Выговор" : "Workoff"}</th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default CheckTasks;
