import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function TaskEditPopup({backButton, selectedTask, stages, editAcademyTask, deleteAcademyTask}) {

    const [name, setName] = useState(selectedTask.name);
    const [description, setDescription] = useState(selectedTask.description);
    const [maxPoints, setMaxPoints] = useState(selectedTask.maxPoints);
    const [stage, setStage] = useState(stages[selectedTask.stage - 1] ? stages[selectedTask.stage - 1].name : stages[0].name);

    const handleMaxPoints = (e) => {
        let raw_points = e.target.value;
        if(raw_points < 1){
            raw_points = 1;
        }
        setMaxPoints(raw_points);
    }

    const resetChanges = () => {
        setName(selectedTask.name);
        setDescription(selectedTask.description);
        setStage(stages[selectedTask.stage - 1].name);
        setMaxPoints(selectedTask.maxPoints);
    }

    const getStageID = () => {
        return stages.find(st => st.name === stage).stage;
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Изменение задания</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <input type="text" id="point-answer-input" value={name} onChange={(e) => setName(e.target.value)}/>

                    <span className="point-header">Описание</span>
                    <textarea id="user-proofs" value={description} onChange={(e) => setDescription(e.target.value)}/>

                    <span className="point-header">Максимальный балл</span>
                    <input type="number" id="point-answer-input" value={maxPoints}
                           onChange={(e) => handleMaxPoints(e)}/>

                    <span className="point-header">Этап</span>
                    <select name="divisions" id="divisions" value={stage}
                            onChange={(e) => setStage(e.target.value)}>
                        {stages.map((stage, index) => (
                            <option
                                key={index}>{stage.name}</option>
                        ))}
                    </select>

                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => editAcademyTask({taskID: selectedTask.ID, name: name, description: description, maxPoints: maxPoints, stage: getStageID()})}><i className="bi bi-send-fill"></i> Сохранить изменения</button>
                    <button id="reset-task" onClick={resetChanges}><i className="bi bi-eraser-fill"></i> Сбросить изменения</button>
                    <button id="delete-task" onClick={() => deleteAcademyTask(selectedTask.ID)}><i className="bi bi-trash-fill"></i> Удалить задание</button>
                </div>
            </div>
        </>
    );
}

export default TaskEditPopup;
