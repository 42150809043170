import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function DivisionTaskEditPopup({backButton, selectedTask, saveChangesDivisionTask, deleteDivisionTask}) {

    const [name, setName] = useState(selectedTask.name);
    const [description, setDescription] = useState(selectedTask.description);
    const [doneTo, setDoneTo] = useState(selectedTask.doneTo);

    const [nameError, setNameError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");

    const resetChanges = () => {
        setName(selectedTask.name);
        setDescription(selectedTask.description);
        setDoneTo(selectedTask.doneTo);
    }

    const handleSendRequest = () => {
        let error = false;

        if(name.length < 6 || name.length > 64){
            setNameError("Название не может быть меньше 6 и больше 64 символов.");
            error = true;
        }

        if(description.length > 1024){
            setDescriptionError("Описание не может быть больше 1024 символов.")
            error = true;
        }

        if(error) return;
        saveChangesDivisionTask({
            taskID: selectedTask.ID,
            name: name,
            description: description,
            doneTo: doneTo
        })
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Изменение задания</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <input type="text" id="point-answer-input" className={nameError.length > 0 ? "invalid" : ""} value={name} onChange={(e) => setName(e.target.value)}/>
                    {nameError.length > 0 && (
                        <span className="error-text">{nameError}</span>
                    )}

                    <span className="point-header">Описание</span>
                    <textarea id="user-proofs" value={description} className={descriptionError.length > 0 ? "invalid" : ""} onChange={(e) => setDescription(e.target.value)}/>
                    {descriptionError.length > 0 && (
                        <span className="error-text">{descriptionError}</span>
                    )}

                    <span className="point-header">Выполнить до</span>
                    <input type="date" id="point-answer-input" value={doneTo}
                           onChange={(e) => setDoneTo(e.target.value)}/>

                </div>
                <div className="buttons-container">
                    {(selectedTask.status === 0 || selectedTask.status === 2) && (
                    <>
                        <button id="send-task" onClick={handleSendRequest}><i className="bi bi-send-fill"></i> Сохранить изменения</button>
                        <button id="reset-task" onClick={resetChanges}><i className="bi bi-eraser-fill"></i> Сбросить изменения</button>
                    </>
                    )}
                    <button id="delete-task" onClick={() => deleteDivisionTask(selectedTask.ID)}><i className="bi bi-trash-fill"></i> Удалить задание</button>
                </div>
            </div>
        </>
    );
}

export default DivisionTaskEditPopup;
