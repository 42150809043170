import {useEffect, useState} from "react";
function TechWorksPage() {

    return (
        <div id="tech-works-page">
            <span className="tech-works-header">Технические работы!</span>
            <span className="tech-works-subheader">Сайт временно недоступен.</span>
            <span className="tech-works-text">Приношу свои извинения за предоставленные неудобства.</span>
        </div>
    );
}

export default TechWorksPage;
