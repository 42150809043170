import logotype from "./assets/logotype.png"
import "./styles/mainPage.css"
import {useEffect, useState} from "react";
import pfp from "./pfps/0.jpg";
import UserSettings from "./user/UserSettings";
import Home from "./Home/Home";
import Members from "./Members/Members";
import Tasks from "./Tasks/Tasks";
import AcademyControl from "./AcademyControl/AcademyControl";
import CheckTasks from "./CheckTasks/CheckTasks";
import DivisionTasks from "./DivisionTasks/DivisionTasks";
import PersonalTasks from "./PersonalTasks/PersonalTasks";
import PointAssignments from "./PointAssignments/PointAssignments";
import Requests from "./Requests/Requests";
import DeletedUsers from "./DeletedUsers/DeletedUsers";
import Warrants from "./Warrants/Warrants";
import UsefullLinks from "./UsefulLinks/UsefullLinks";
import DevSupport from "./DevSupport/DevSupport";

function MainPage({sendData, sendNotification}) {

    const [selectedPoint, setSelectedPoint] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isExtended, setIsExtended] = useState(false);
    const [userData, setUserData] = useState({});
    const [allMembers, setAllMembers] = useState([]);
    const [stages, setStages] = useState([]);
    const [divisionTasks, setDivisionTasks] = useState([]);
    const [regRequests, setRegRequests] = useState([]);
    const [academyTasks, setAcademyTasks] = useState([]);
    const [inactiveRequests, setInactiveRequests] = useState([]);
    const [userNotifications, setUserNotifications] = useState([]);
    const [verifyTasks, setVerifyTasks] = useState([]);
    const [personalTasks, setPersonalTasks] = useState([]);
    const [pointVerifyTasks, setPointVerifyTasks] = useState([]);
    const [points_assignments, setPointsAssignments] = useState([]);
    const [doneTasks, setDoneTasks] = useState([]);
    const [declinedTasks, setDeclinedTasks] = useState([]);
    const [config, setConfig] = useState({});
    const [permissions, setPermissions] = useState({
        "userEditPerm": 0,
        "academyPerm": 0,
        "userPermEdit": 0,
        "warrantPerm": 0,
        "regCheck": 0,
        "punishPerm": 0,
        "divisionCheck": 0,
        "voiceExamPerm": 0,
        "inactiveReqCheckPerm": 0,
        "allDivisionCheckPerm": 0,
        "examEditPerm": 0,
        "editTasksPerm": 0,
    });

    const [userSettings, setUserSettings] = useState(false);

    const ranks = {
        1: "Appliciant of FBI",
        2: "Cadet of FBI",
        3: "Probationery Agent of FBI",
        4: "Agent Trainee of FBI",
        5: "Agent of FBI",
        6: "Special Agent of FBI",
        7: "Supervisor Special Agent of FBI",
        8: "Special Agent in Charge of FBI",
        9: "Deputy FBI Field Office Director",
        10: "FBI Field Office Director"
    };

    const divisions = {
        0: "Academy",
        1: "Office of the Director",
        2: "CCRSB",
        3: "HRB [TD]",
        4: "HRB [SD]"
    };

    const headsFrom = {
        0: 1,
        1: 0,
        2: 1,
        3: 1,
        4: 1
    };
    const headIndex = {
        0: 0,
        1: 1,
        2: 2,
        3: 2,
        4: 2
    };

    const positions = {
        0: {
            0: "Academician"
        },
        1: {
            0: "Deputy FBI Field Office Director",
            1: "FBI Field Office Director"
        },
        2:{
            0: "Agent of CCRSB",
            1: "Deputy Head of CCRSB",
            2: "Head of CCRSB"
        },
        3:{
            0: "Agent of HRB [TD]",
            1: "Deputy Head of HRB [TD]",
            2: "Head of HRB [TD]"
        },
        4:{
            0: "Agent of HRB [SD]",
            1: "Deputy Head of HRB [SD]",
            2: "Head of HRB [SD]"
        }
    };

    const selectOption = (index) => {
        setSelectedPoint(index);
        setUserSettings(false);
    }

    const updateMember = (id, data) => {
        setAllMembers(currentUsers => {
            const index = currentUsers.findIndex(user => user.ID === id);
            if (index !== -1) {
                return [
                    ...currentUsers.slice(0, index),
                    { ...currentUsers[index], ...data },
                    ...currentUsers.slice(index + 1)
                ];
            }
            return currentUsers;
        });
    }

    useEffect(() => {
        function handleResize() {
            setIsMobile(window.innerWidth < 768);
        }

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const rotatorIcon = document.querySelector(".rotator-icon");
        if(isExtended){
            rotatorIcon.style.rotate = "180deg";
        }else{
            rotatorIcon.style.rotate = "0deg";
        }
    }, [isExtended]);

    const handleLogoBack = () => {
        setUserSettings(false);
        setSelectedPoint(0)
    }

    useEffect( () => {
        const LoadUser = async () => {
            const uData = await sendData("GET_USER_DATA", "UserManager", {userAgent: navigator.userAgent});
            setUserData(uData.data)
            permissions.userEditPerm = uData.data.userEditPerm;
            permissions.userPermEdit = uData.data.userPermEdit;
            permissions.regCheck = uData.data.regCheck;
            permissions.academyPerm = uData.data.academyPerm;
            permissions.divisionCheck = uData.data.divisionCheck;
            permissions.inactiveReqCheckPerm = uData.data.inactiveReqCheckPerm;
            permissions.punishPerm = uData.data.punishPerm;
            permissions.voiceExamPerm = uData.data.voiceExamPerm;
            permissions.examEditPerm = uData.data.examEditPerm;
            permissions.allDivisionCheckPerm = uData.data.allDivisionCheckPerm;
            permissions.editTasksPerm = uData.data.editTasksPerm;

            const members = await sendData("GET_MEMBERS", "UserManager", {});
            setAllMembers(members.data)

            const stagesData = await sendData("GET_STAGES", "UserManager", {});
            setStages(stagesData.data)

            const configData = await sendData("GET_CONFIG", "UserManager", {});
            setConfig(configData.data)

            const academyData = await sendData("GET_ACADEMY_TASKS", "UserManager", {});
            setAcademyTasks(academyData.data);

            const verifyData = await sendData("GET_VERIFY_TASKS", "UserManager", {});
            setVerifyTasks(verifyData.data);

            const divisionTaskData = await sendData("GET_DIVISION_TASKS", "UserManager", {});
            setDivisionTasks(divisionTaskData.data);

            const doneData = await sendData("GET_DONE_TASKS", "UserManager", {});
            setDoneTasks(doneData.data);

            const declinedData = await sendData("GER_DECLINED_TASKS", "UserManager", {});
            setDeclinedTasks(declinedData.data);

            const pointsAssign = await sendData("GET_POINTS_ASSIGNMENTS", "UserManager", {});
            setPointsAssignments(pointsAssign.data);

            const pointVerifyData = await sendData("GET_POINTS_VERIFY", "UserManager", {});
            setPointVerifyTasks(pointVerifyData.data);

            const personalData = await sendData("GET_PERSONAL_TASKS", "UserManager", {});
            setPersonalTasks(personalData.data);

            const inactiveData = await sendData("GET_INACTIVE_REQUESTS", "UserManager", {});
            setInactiveRequests(inactiveData.data);

            const regRequests = await sendData("GET_REGISTRATION_REQUESTS", "UserManager", {});
            setRegRequests(regRequests.data);

            const notfData = await sendData("GET_USER_NOTIFICATIONS", "UserManager", {});
            setUserNotifications(notfData.data);
        }
        LoadUser();
    }, []);

    const updateAcademyTask = (data, mode) => {
        if(mode === "edit") {
            setAcademyTasks(academyTasks => {
                const index = academyTasks.findIndex(task => task.ID === data.taskID);
                if (index !== -1) {
                    return [
                        ...academyTasks.slice(0, index),
                        {...academyTasks[index], ...data},
                        ...academyTasks.slice(index + 1)
                    ];
                }
                return academyTasks;
            });
        }else if (mode === "delete"){
            setAcademyTasks(academyTasks.filter(task => task.ID !== data));
        }else if(mode === "add"){
            setAcademyTasks(previousTasks => [data, ...previousTasks]);
        }
    }

    const updateStages = (data, mode) => {
        if(mode === "edit") {
            setStages(stages => {
                const index = stages.findIndex(task => task.stage === data.stage);
                if (index !== -1) {
                    return [
                        ...stages.slice(0, index),
                        {...stages[index], ...data},
                        ...stages.slice(index + 1)
                    ];
                }
                return stages;
            });
        }else if (mode === "delete"){
            setStages(stages.filter(task => task.stage !== data));
        }else if(mode === "add"){
            setStages(previousTasks => [data, ...previousTasks]);
        }
    }

    const updateMembers = (data, mode) => {
        if(mode === "edit") {
            setAllMembers(allMembers => {
                const index = allMembers.findIndex(task => task.ID === data.ID);
                if (index !== -1) {
                    return [
                        ...allMembers.slice(0, index),
                        {...allMembers[index], ...data},
                        ...allMembers.slice(index + 1)
                    ];
                }
                return allMembers;
            });
        }else if (mode === "delete"){
            setAllMembers(allMembers.filter(task => task.stage !== data));
        }else if(mode === "add"){
            setAllMembers(previousTasks => [data, ...previousTasks]);
        }
    }

    const updateVerifyTasks = (data, mode) => {
        if(mode === "edit") {
            setVerifyTasks(verifyTasks => {
                const index = verifyTasks.findIndex(task => task.ID === data.ID);
                if (index !== -1) {
                    return [
                        ...verifyTasks.slice(0, index),
                        {...verifyTasks[index], ...data},
                        ...verifyTasks.slice(index + 1)
                    ];
                }
                return verifyTasks;
            });
        }else if (mode === "delete"){
            setVerifyTasks(verifyTasks.filter(task => task.ID !== data));
        }else if(mode === "add"){
            setVerifyTasks(previousTasks => [data, ...previousTasks]);
        }
    }


    const updateDivisionTasks = (data, mode) => {
        if(mode === "edit") {
            setDivisionTasks(divisionTasks => {
                const index = divisionTasks.findIndex(task => task.ID === data.taskID);
                if (index !== -1) {
                    return [
                        ...divisionTasks.slice(0, index),
                        {...divisionTasks[index], ...data},
                        ...divisionTasks.slice(index + 1)
                    ];
                }
                return divisionTasks;
            });
        }else if (mode === "delete"){
            setDivisionTasks(divisionTasks.filter(task => task.ID !== data));
        }else if(mode === "add"){
            setDivisionTasks(previousTasks => [data, ...previousTasks]);
        }
    }

    const updatePersonalTasks = (data, mode) => {
        if(mode === "edit") {
            setPersonalTasks(personalTasks => {
                const index = personalTasks.findIndex(task => task.ID === data.taskID);
                if (index !== -1) {
                    return [
                        ...personalTasks.slice(0, index),
                        {...personalTasks[index], ...data},
                        ...personalTasks.slice(index + 1)
                    ];
                }
                return personalTasks;
            });
        }else if (mode === "delete"){
            setPersonalTasks(personalTasks.filter(task => task.ID !== data));
        }else if(mode === "add"){
            setPersonalTasks(previousTasks => [data, ...previousTasks]);
        }
    }

    const updatePointAssignments = (data, mode) => {
        if(mode === "edit") {
            setPointsAssignments(points_assignments => {
                const index = points_assignments.findIndex(task => task.ID === data.taskID);
                if (index !== -1) {
                    return [
                        ...points_assignments.slice(0, index),
                        {...points_assignments[index], ...data},
                        ...points_assignments.slice(index + 1)
                    ];
                }
                return points_assignments;
            });
        }else if (mode === "delete"){
            setPointsAssignments(points_assignments.filter(task => task.ID !== data));
        }else if(mode === "add"){
            setPointsAssignments(previousTasks => [data, ...previousTasks]);
        }
    }

    const updatePointVerifyTasks = (data, mode) => {
        if(mode === "edit") {
            setPointVerifyTasks(pointVerifyTasks => {
                const index = pointVerifyTasks.findIndex(task => task.ID === data.taskID);
                if (index !== -1) {
                    return [
                        ...pointVerifyTasks.slice(0, index),
                        {...pointVerifyTasks[index], ...data},
                        ...pointVerifyTasks.slice(index + 1)
                    ];
                }
                return pointVerifyTasks;
            });
        }else if (mode === "delete"){
            setPointVerifyTasks(pointVerifyTasks.filter(task => task.ID !== data));
        }else if(mode === "add"){
            setPointVerifyTasks(previousTasks => [data, ...previousTasks]);
        }
    }

    const updateInactiveRequests = (data, mode) => {
        if(mode === "edit") {
            setInactiveRequests(inactiveRequests => {
                const index = inactiveRequests.findIndex(task => task.ID === data.taskID);
                if (index !== -1) {
                    return [
                        ...inactiveRequests.slice(0, index),
                        {...inactiveRequests[index], ...data},
                        ...inactiveRequests.slice(index + 1)
                    ];
                }
                return inactiveRequests;
            });
        }else if (mode === "delete"){
            setInactiveRequests(inactiveRequests.filter(task => task.ID !== data));
        }else if(mode === "add"){
            setInactiveRequests(previousTasks => [data, ...previousTasks]);
        }
    }

    const updateRegRequests = (data, mode) => {
        if(mode === "edit") {
            setRegRequests(regRequests => {
                const index = regRequests.findIndex(task => task.ID === data.taskID);
                if (index !== -1) {
                    return [
                        ...regRequests.slice(0, index),
                        {...regRequests[index], ...data},
                        ...regRequests.slice(index + 1)
                    ];
                }
                return regRequests;
            });
        }else if (mode === "delete"){
            setRegRequests(regRequests.filter(task => task.ID !== data));
        }else if(mode === "add"){
            setRegRequests(previousTasks => [data, ...previousTasks]);
        }
    }


    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (e) {
            return false;
        }
    }

    const openLink = (url, e) => {
        e.stopPropagation();
        if (isValidUrl(url)) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    const handleOpenUserSettings = () => {
        setSelectedPoint(-1);
        setUserSettings(true);
    }

    const shouldDisplay = (...conditions) => {
        return conditions.some(condition => condition);
    };

    const handleLeave = async () => {
        await sendData("HANDLE_LEAVE_ACTION", "UserManager", {});
        window.location.reload();
    }

    return (
        <div id="main-page">
            <div className={`menu-container ${!isExtended ? "not-extended" : ""} ${isMobile ? "mobile" : ""}`}>
                <div id="logotype-container-menu">
                    <img src={logotype} alt="" id="logotype" onClick={handleLogoBack}/>
                    <span className="logotype-name">Data Hub</span>
                </div>

                <div className="hide-menu" onClick={() => setIsExtended(!isExtended)}><i className="rotator-icon bi bi-chevron-right"></i></div>

                <div id="profile-container">
                    <img src={userData.avatar} alt="" id="profile-image"/>
                    <div className="user-information">
                        <span className="user-name">{userData.nickname}</span>
                        <span className="user-rank">{userData.admin === 0 ? ranks[userData.rank] : userData.admin === 1 ? "Administrator" : "Developer"}</span>
                    </div>
                    <div className="user-controls">
                        <span className="user-button" onClick={() =>handleOpenUserSettings()}><i className="bi bi-gear-fill"></i></span>
                        <span className="user-button" onClick={() => handleLeave()}><i className="bi bi-box-arrow-left"></i></span>
                    </div>
                </div>

                <div id="options-container">
                    <div className={`menu-option ${selectedPoint === 0 ? "selected" : ""}`}
                         onClick={() => selectOption(0)}>
                        <i className="bi bi-house-door-fill"></i>
                        <span className="menu-text">Главная</span>
                    </div>
                    <div className={`menu-option ${selectedPoint === 1 ? "selected" : ""}`}
                         onClick={() => selectOption(1)}>
                        <i className="bi bi-people-fill"></i>
                        <span className="menu-text">Состав</span>
                    </div>
                    <div className={`menu-option ${selectedPoint === 2 ? "selected" : ""}`}
                         onClick={() => selectOption(2)}>
                        <i className="bi bi-briefcase-fill"></i>
                        <span className="menu-text">Задания</span>
                    </div>
                    <div className={`menu-option ${selectedPoint === 4 ? "selected" : ""}`}
                         onClick={() => selectOption(4)}>
                        <i className="bi bi-star-fill"></i>
                        <span className="menu-text">Ордера</span>
                    </div>
                    <div className={`menu-option ${selectedPoint === 11 ? "selected" : ""}`}
                         onClick={() => selectOption(11)}>
                        <i className="bi bi-link-45deg"></i>
                        <span className="menu-text">Полезные ссылки</span>
                    </div>
                    {shouldDisplay(userData.admin > 0, userData.voiceExamPerm > 0, userData.userEditPerm > 0,
                        userData.divisionCheck > 0, userData.academyPerm > 0, userData.inactiveReqCheckPerm > 0, userData.regCheck > 0, userData.allDivisionCheckPerm > 0, userData.editTasksPerm > 0) && (
                        <>
                            <div className="divider">Руководство</div>
                            {shouldDisplay(userData.userEditPerm > 0, userData.admin > 0) && (
                                <div className={`menu-option ${selectedPoint === 5 ? "selected" : ""}`}
                                     onClick={() => selectOption(5)}>
                                    <i className="bi bi-person-fill-slash"></i>
                                    <span className="menu-text">Удаленные пользователи</span>
                                </div>
                            )}
                            {shouldDisplay(userData.divisionCheck > 0, userData.allDivisionCheckPerm > 0, userData.editTasksPerm > 0, userData.academyPerm > 0, userData.admin > 0) && (
                                <div className={`menu-option ${selectedPoint === 6 ? "selected" : ""}`}
                                     onClick={() => selectOption(6)}>
                                    <i className="bi bi-body-text"></i>
                                    <span className="menu-text">Проверка заданий</span>
                                </div>
                            )}
                            {shouldDisplay(userData.inactiveReqCheckPerm > 0, userData.regCheck > 0, userData.admin > 0) && (
                                <div className={`menu-option ${selectedPoint === 3 ? "selected" : ""}`}
                                     onClick={() => selectOption(3)}>
                                    <i className="bi bi-file-earmark-text-fill"></i>
                                    <span className="menu-text">Запросы</span>
                                </div>
                            )}
                        </>
                    )}
                    {shouldDisplay(userData.admin > 0, userData.academyPerm > 0, userData.divisionCheck > 0, userData.userEditPerm > 0, userData.examEditPerm > 0) && (
                        <>
                            <div className="divider">Создание</div>
                            {shouldDisplay(userData.admin > 0, userData.academyPerm > 0) && (
                                <div className={`menu-option ${selectedPoint === 7 ? "selected" : ""}`}
                                     onClick={() => selectOption(7)}>
                                    <i className="bi bi-backpack-fill"></i>
                                    <span className="menu-text">Управление академией</span>
                                </div>
                            )}
                            {shouldDisplay(userData.admin > 0, userData.divisionCheck > 0) && (
                                <div className={`menu-option ${selectedPoint === 8 ? "selected" : ""}`}
                                     onClick={() => selectOption(8)}>
                                    <i className="bi bi-person-lines-fill"></i>
                                    <span className="menu-text">Задания отделов</span>
                                </div>
                            )}
                            {shouldDisplay(userData.admin > 0, userData.editTasksPerm > 0) && (
                                <div className={`menu-option ${selectedPoint === 9 ? "selected" : ""}`}
                                     onClick={() => selectOption(9)}>
                                    <i className="bi bi-clipboard2-data-fill"></i>
                                    <span className="menu-text">Персональные задания</span>
                                </div>
                            )}
                            {shouldDisplay(userData.admin > 0, userData.editTasksPerm > 0) && (
                                <div className={`menu-option ${selectedPoint === 10 ? "selected" : ""}`}
                                     onClick={() => selectOption(10)}>
                                    <i className="bi bi-piggy-bank-fill"></i>
                                    <span className="menu-text">Задания на баллы</span>
                                </div>
                            )}
                        </>
                    )}
                    <div className="divider">Другое</div>
                    <div className={`menu-option ${selectedPoint === 12 ? "selected" : ""}`}
                         onClick={() => selectOption(12)}>
                        <i className="bi bi-chat-dots-fill"></i>
                        <span className="menu-text">Связь с разработчиком</span>
                    </div>
                </div>
            </div>
            <div className="content-container">
                {userSettings && (
                    <UserSettings sendData={sendData} sendNotification={sendNotification} userData={userData}
                                  ranks={ranks} inactiveList={inactiveRequests} members={allMembers}/>
                )}
                {selectedPoint === 0 && (
                    <Home academyTasks={academyTasks} verifyTasks={verifyTasks} doneTasks={doneTasks} notifications={userNotifications} setMenuOption={selectOption} divisionTasks={divisionTasks} userData={userData} ranks={ranks} divisions={divisions} positions={positions} members={allMembers} stages={stages} config={config} headIndex={headIndex}/>
                )}
                {selectedPoint === 1 && (
                    <Members academyTasks={academyTasks} shdDisplay={shouldDisplay} userData={userData} members={allMembers} sendData={sendData} divisions={divisions} openLink={openLink} positions={positions} ranks={ranks} permissions={permissions} updateMember={updateMember}/>
                )}
                {selectedPoint === 2 && (
                    <Tasks shouldDisplay={shouldDisplay} openLink={openLink} myVerify={pointVerifyTasks} point_assignments={points_assignments} personalTasks={personalTasks} updatePersonalTasks={updatePersonalTasks} divisionTasks={divisionTasks} updateDivisionTasks={updateDivisionTasks} members={allMembers} userData={userData} sendData={sendData} academyTasks={academyTasks} verifyTasks={verifyTasks} doneTasks={doneTasks} declinedTasks={declinedTasks}/>
                )}
                {selectedPoint === 3 && (
                    <Requests updateRequests={updateInactiveRequests} updateRegRequests={updateRegRequests} openLink={openLink} userData={userData} regRequests={regRequests} members={allMembers} sendData={sendData} shouldDisplay={shouldDisplay} inactiveRequests={inactiveRequests}/>
                )}
                {selectedPoint === 4 && (
                    <Warrants isValidURL={isValidUrl} userData={userData} sendData={sendData} members={allMembers} shouldDisplay={shouldDisplay} openLink={openLink}/>
                )}
                {selectedPoint === 5 && (
                    <DeletedUsers updateUsers={updateMembers} sendData={sendData} members={allMembers} ranks={ranks} divisions={divisions} positions={positions}/>
                )}
                {selectedPoint === 6 && (
                    <CheckTasks updatePointVerifyTasks={updatePointVerifyTasks} updateVerifyTasks={updateVerifyTasks} pointVerify={pointVerifyTasks} point_assignments={points_assignments} personalTasks={personalTasks} updatePersonalTasks={updatePersonalTasks} updateDivisionTasks={updateDivisionTasks} divisionTasks={divisionTasks} shouldDisplay={shouldDisplay} members={allMembers} academyTasks={academyTasks} verifyTasks={verifyTasks} userData={userData} sendData={sendData}/>
                )}
                {selectedPoint === 7 && (
                    <AcademyControl divisions={divisions} ranks={ranks} members={allMembers} updateStages={updateStages} updateTask={updateAcademyTask} academyTasks={academyTasks} sendData={sendData} userData={userData} stages={stages}/>
                )}
                {selectedPoint === 8 && (
                    <DivisionTasks updateDivisionTasks={updateDivisionTasks} divisions={divisions} members={allMembers} userData={userData} sendData={sendData} divisionTasks={divisionTasks} shouldDisplay={shouldDisplay}/>
                )}
                {selectedPoint === 9 && (
                    <PersonalTasks sendData={sendData} userData={userData} assignments={personalTasks} members={allMembers} updatePersonalTasks={updatePersonalTasks}/>
                )}
                {selectedPoint === 10 && (
                    <PointAssignments userData={userData} updatePointAssignments={updatePointAssignments} sendData={sendData} point_assignments={points_assignments} shouldDisplay={shouldDisplay}/>
                )}
                {selectedPoint === 11 && (
                    <UsefullLinks openLink={openLink} userData={userData} sendData={sendData} members={allMembers} shouldDisplay={shouldDisplay} isValidURL={isValidUrl}/>
                )}
                {selectedPoint === 12 && (
                    <DevSupport sendData={sendData} members={allMembers} openLink={openLink} isValidURL={isValidUrl} userData={userData} shouldDisplay={shouldDisplay}/>
                )}
            </div>
        </div>
    );
}

export default MainPage;
