import logotype from "./assets/logotype.png"
import "./styles/login.css"
import servers from "./assets/servers_logo.png"
import {useEffect, useState} from "react";
import MainPage from "./MainPage";
import TechWorksPage from "./TechWorksPage";
import ErrorRaised from "./ErrorRaised";

function Login() {

    const [type, setType] = useState("LOGIN");

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [nickname, setNickname] = useState("");
    const [vk, setVK] = useState("");
    const [forum, setForum] = useState("");
    const [techWorks, setTechWorks] = useState(false);

    // =======
    const [loginErr,setLoginErr] = useState("");
    const [passwordErr,setPasswordErr] = useState("");
    const [nicknameErr,setNicknameErr] = useState("");
    const [vkErr,setVkErr] = useState("");
    const [forumErr,setForumErr] = useState("");
    const [notificationText, setNotificationText] = useState("");
    const [notificationCode, setNotificationCode] = useState(0);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [logged, setLogged] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [errorRaised, setErrorRaised] = useState(false);

    useEffect(() => {
        alert('С 4 числа следующего месяца сайт прекратит работу. Пожалуйста, не забудь перенести все свои данные заранее.');
    }, []);

    const sendData = async (mode, filename, data) => {
        const url = "https://kfbase.xyz/Server/"+ filename +".php";
        data = {
            mode: mode,
            ...data
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
                credentials: 'include'
            });
            const responseBody = await response.text();


            const errorKeywords = ["Fatal error", "Warning", "Notice", "SQL Error", "Exception"];
            const warningKeywords = ["Deprecated", "Notice"];

            let isError = errorKeywords.some(keyword => responseBody.includes(keyword));
            let isWarning = warningKeywords.some(keyword => responseBody.includes(keyword));

            if(isError || isWarning){
                if(mode !== "SEND_ERROR_WARNING_REPORT"){
                    await sendData("SEND_ERROR_WARNING_REPORT", "UserManager", {code: mode, message: responseBody});
                }
            }
            const responseData = JSON.parse(responseBody);
            switch (responseData.response_code){
                case 404:
                {
                    window.location.reload();
                    break;
                }
                case 750:
                {
                    setTechWorks(true);
                    break;
                }
                default: break;
            }

            if(responseData.response_code === 418 || responseData.response_code === 200){
                sendNotification(responseData.response_code, responseData.message);
                if(mode === "AUTH_ACTION" && type === "LOGIN" && responseData.response_code === 200) {
                    setTimeout(() => {
                        setLogged(true);
                    }, 2500)
                }
            }

            return responseData;
        } catch (error) {
            console.error('Ошибка при отправке формы:', error);
            throw error;
        }
    }

    const containsCyrillic = (text) => {
        const cyrillicPattern = /[а-яА-ЯЁё]/;
        return cyrillicPattern.test(text);
    };

    const handleTypeChange = () => {
        setType(type === "LOGIN" ? "REGISTRATION" : "LOGIN");
        setLoginErr("");
        setPasswordErr("");
        setNicknameErr("");
        setVkErr("");
        setForumErr("");

        setLogin("");
        setPassword("");
        setNickname("");
        setVK("");
        setForum("");
    }

    const validateURL = (url) => {
        const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
        return urlPattern.test(url);
    };

    const checkForErrors = () => {
        let error = false;

        if(login.length < 6 || login.length > 24){
            setLoginErr("Логин не может быть меньше 6 и больше 24 символов.")
            error = true;
        }else {
            if(containsCyrillic(login)){
                setLoginErr("Логин может содержать кириллицу.")
                error = true;
            }
        }

        if(password.length < 6 || password.length > 32){
            setPasswordErr("Пароль не может быть меньше 6 и больше 32 символов.")
            error = true;
        }else{
            const passwordPattern = /^[a-zA-Z0-9]*$/;
            if(!passwordPattern.test(password)) {
                setPasswordErr("Пароль не может содержать кириллицу или спец. символы.")
                error = true;
            }
        }

        if(type === "REGISTRATION") {

            if (nickname.length < 4 || password.length > 24) {
                setNicknameErr("Никнейм не может быть меньше 4 и больше 24 символов.")
                error = true;
            }else{
                if(containsCyrillic(nickname)){
                    setNicknameErr("Никнейм не может содержать кириллицу.")
                    error = true;
                }
            }

            if(!validateURL(vk)){
                setVkErr("Недопустимая ссылка на профиль VKontakte.")
                error = true;
            }

            if(!validateURL(forum)){
                setForumErr("Недопустимая ссылка на профиль Forum.")
                error = true;
            }
        }

        return error;
    }

    const handleAuthAction = async () => {
        const hasError = checkForErrors();
        if(!hasError){
            const response = await sendData("AUTH_ACTION", "auth", {
                login: login,
                password: password,
                nickname: nickname,
                vk: vk,
                forum: forum,
                action: type,
                remember: rememberMe
            });


        }
    }

    useState(() => {
        const checkCookies = async () => {
            const response = await sendData("CHECK_COOKIES", "auth", {});
            if(response.response_code === 201){
                setLogged(true);
            }
        }

        checkCookies();
    }, [])

    const handleLoginChange = (e) => {
        setLogin(e.target.value);
        setLoginErr("");
    }
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordErr("");
    }
    const handleNicknameChange = (e) => {
        setNickname(e.target.value);
        setNicknameErr("");
    }
    const handleVKChange = (e) => {
        setVK(e.target.value);
        setVkErr("");
    }
    const handleForumChange = (e) => {
        setForum(e.target.value);
        setForumErr("");
    }

    const sendNotification = (responseCode, message) =>
    {
        const textElement = document.querySelector('.notification-text');
        const iconElement = document.querySelector('.notification-icon');

        textElement.style.display = 'none';
        textElement.style.opacity = '0';
        iconElement.style.opacity = '1';
        iconElement.style.display = 'flex';

        setNotificationVisible(true);

        setNotificationText(message);
        setNotificationCode(responseCode);

        setTimeout(() => {
            textElement.style.display = 'flex';
            iconElement.style.display = 'none';
            textElement.style.opacity = '1';
        }, 1500);


        setTimeout(() => {
            setNotificationVisible(false);
        }, 4500);
    }

    const closeError = () => {
        setErrorRaised(false);
    }

    return (
        <div>
            {techWorks && (
                <TechWorksPage/>
            )}
            {errorRaised && (
                <ErrorRaised closeError={closeError}/>
            )}
            <div id="status-notification-container" className={`${notificationVisible ? "visible" : ""}`}>
                <div className={`notification-container`} onClick={() => setNotificationVisible(false)}>
                    <div className="status-notification">
                        {notificationCode === 418 ? (
                            <div className="notification-icon"><i className="status status-red bi bi-x-lg"></i></div>
                        ) : (
                            <div className="notification-icon"><i className="status bi bi-check2"></i></div>
                        )}
                        <div className="notification-text">{notificationText}</div>
                    </div>
                </div>
            </div>
            {!logged ? (
                <>
                    <img src={logotype} alt="" id="top-logo"/>

                    {type === "LOGIN" ? (
                        <div id="login-container">
                            <div className="left-panel">
                                <div id="logotype-container">
                                    <img src={logotype} alt="" id="logotype"/>
                                    <span className="logotype-name">Data Hub</span>
                                </div>
                                <div className="welcome-message">С возвращением!</div>
                                <div className="welcome-subheader">Введите логин и пароль, чтобы войти в систему.</div>
                                <div className="input-container">
                                    <input type="text" id="login-input" className={loginErr.length > 0 ? "invalid" : ""}
                                           placeholder="Введите логин" value={login}
                                           onChange={(e) => handleLoginChange(e)}></input>
                                    {loginErr.length > 0 && (
                                        <span className="error-text">{loginErr}</span>
                                    )}

                                    <input type="password" id="login-input"
                                           className={passwordErr.length > 0 ? "invalid" : ""}
                                           placeholder="Введите пароль" value={password}
                                           onChange={(e) => handlePasswordChange(e)}></input>
                                    {passwordErr.length > 0 && (
                                        <span className="error-text">{passwordErr}</span>
                                    )}
                                </div>
                                <label htmlFor="remember">
                                    <input type="checkbox" id="remember" name="remember" checked={rememberMe}
                                           onChange={(e) => setRememberMe(e.target.checked)}/>
                                    Запомнить меня
                                </label>
                                <div className="button-container">
                                    <button id="login-button" onClick={handleAuthAction}>Войти</button>
                                    <span className="has-no-account">У вас еще нет учетной записи?</span>
                                    <span className="has-no-account-clickable"
                                          onClick={handleTypeChange}>Подать запрос</span>
                                </div>
                            </div>
                            <div className="right-panel">
                                <div className="panel-container">
                                    <img src={logotype} alt="" id="logotype"/>
                                    <img src={servers} alt="" id="servers-logo"/>
                                    <span className="panel-description">Это пространство, где каждый фрагмент информации упорядочен и защищён, обеспечивая бесперебойную работу и оперативное реагирование.</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div id="login-container">
                            <div className="left-panel">
                                <div id="logotype-container">
                                    <img src={logotype} alt="" id="logotype"/>
                                    <span className="logotype-name">Data Hub</span>
                                </div>
                                <div className="welcome-message">Добро пожаловать!</div>
                                <div className="welcome-subheader">Заполните поля ниже для подачи запроса на
                                    регистрацию.
                                </div>
                                <div className="input-container">
                                    <input type="text" id="login-input" className={loginErr.length > 0 ? "invalid" : ""}
                                           placeholder="Введите логин" value={login}
                                           onChange={(e) => handleLoginChange(e)}></input>
                                    {loginErr.length > 0 && (
                                        <span className="error-text">{loginErr}</span>
                                    )}
                                    <input type="text" id="nickname-input" placeholder="Введите никнейм"
                                           className={nicknameErr.length > 0 ? "invalid" : ""} autoComplete={"off"}
                                           value={nickname} onChange={(e) => handleNicknameChange(e)}></input>
                                    {nicknameErr.length > 0 && (
                                        <span className="error-text">{nicknameErr}</span>
                                    )}
                                    <input type="password" id="login-input"
                                           className={passwordErr.length > 0 ? "invalid" : ""}
                                           placeholder="Введите пароль" value={password}
                                           onChange={(e) => handlePasswordChange(e)}></input>
                                    {passwordErr.length > 0 && (
                                        <span className="error-text">{passwordErr}</span>
                                    )}
                                    <input type="text" id="vk-link-input" className={vkErr.length > 0 ? "invalid" : ""}
                                           placeholder="Вставьте ссылку на профиль ВК" autoComplete={"off"} value={vk}
                                           onChange={(e) => handleVKChange(e)}></input>
                                    {vkErr.length > 0 && (
                                        <span className="error-text">{vkErr}</span>
                                    )}
                                    <input type="text" id="forum-link-input"
                                           className={forumErr.length > 0 ? "invalid" : ""}
                                           placeholder="Вставьте ссылку на профиль форума" autoComplete={"off"}
                                           value={forum} onChange={(e) => handleForumChange(e)}></input>
                                    {forumErr.length > 0 && (
                                        <span className="error-text">{forumErr}</span>
                                    )}
                                </div>
                                <div className="button-container">
                                    <button id="login-button" onClick={handleAuthAction}>Подать запрос</button>
                                    <span className="has-no-account">У вас уже есть учетная запись?</span>
                                    <span className="has-no-account-clickable"
                                          onClick={handleTypeChange}>Присоединиться</span>
                                </div>
                            </div>
                            <div className="right-panel">
                                <div className="panel-container">
                                    <img src={logotype} alt="" id="logotype"/>
                                    <img src={servers} alt="" id="servers-logo"/>
                                    <span className="panel-description">Это пространство, где каждый фрагмент информации упорядочен и защищён, обеспечивая бесперебойную работу и оперативное реагирование.</span>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <MainPage sendData={sendData} sendNotification={sendNotification}/>
            )}
        </div>
    );
}

export default Login;
