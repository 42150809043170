import "../styles/userSettings.css"
import {useEffect, useState} from "react";
import pfp from "../pfps/0.jpg";
import AvatarSelection from "./AvatarSelection";
import RequestInactive from "./RequestInactive";

function UserSettings({sendData, inactiveList, members, sendNotification, userData, ranks}) {

    const [selectedOption, setSelectedOptions] = useState(0)
    const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false)
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false)
    const [isNewRepeatPasswordVisible, setIsNewRepeatPasswordVisible] = useState(false)
    const [avatarSelection, setAvatarSelection] = useState(false)
    const [inactiveRequestPopup, setInactiveRequestPopup] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [userPunishes, setUserPunishes] = useState([]);

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newRepeatPassword, setNewRepeatPassword] = useState("")

    const [passwordErr, setPasswordErr] = useState("");
    const [passwordRepeatErr, setPasswordRepeatErr] = useState("");

    const getSelected = () => {
        switch(selectedOption) {
            case 1:
                return inactiveList;
            case 2:
                return userPunishes;
            default: return [];
        }
    }

    useEffect(() => {
        const getPunishes = async () => {
            const response = await sendData("GET_PUNISH_LIST", "UserManager", {});
            setUserPunishes(response.data);
        }

        getPunishes();
    }, []);

    useEffect(() => {
        const tasks = getSelected();
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(tasks.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil((tasks.length / itemsPerPage)));
    }, [currentPage, selectedOption, inactiveList]);

    const handleNextPage = () => {
        if (currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' }; // Возвращает 'Unknown', если член не найден
    }
    const handleBackPage = () => {
        if (currentPage - 1 > 0) {
            setCurrentPage(currentPage - 1);
        }
    }


    const handleSelection = (index) => {
        setSelectedOptions(index);
    }

    const closePopups = () => {
        setAvatarSelection(false);
        setInactiveRequestPopup(false);
    }

    const resetData = () => {
        setOldPassword("");
        setNewPassword("");
        setNewRepeatPassword("");
    }

    const saveChanges = async () => {
        let error = false;
        let changingPassword = false;

        if(oldPassword.length > 0 || newPassword.length > 0 || newRepeatPassword.length > 0) {
            changingPassword = true;
            if (newPassword.length < 6 || newPassword.length > 32) {
                setPasswordErr("Пароль не может быть меньше 6 и больше 32 символов.")
                error = true;
            } else {
                const passwordPattern = /^[a-zA-Z0-9]*$/;
                if (!passwordPattern.test(newPassword)) {
                    setPasswordErr("Пароль не может содержать кириллицу или спец. символы.")
                    error = true;
                }
            }

            if (newRepeatPassword.length === 0 || newPassword !== newRepeatPassword) {
                setPasswordRepeatErr("Пароли не совпадают");
                error = true;
            }
        }

        if(oldPassword.length > 0 || newPassword.length > 0 || newRepeatPassword.length > 0) {
            if (error === false) {
                const response = await sendData("CHANGE_USER_DATA", "UserManager", {
                    changingPassword: changingPassword,
                    oldPassword: oldPassword,
                    newPassword: newPassword
                });
            }
        }else{
            sendNotification(418, "Вы ничего не изменили.");
        }
    }

    const createInactiveRequest = async (data) => {
        const response = await sendData("INACTIVE_REQUEST", "UserManager", data);
        if(response.response_code === 200){
            closePopups();
        }
    }

    return (
        <>
            {avatarSelection && (
                <AvatarSelection backButton={closePopups} sendNotification={sendNotification} userData={userData}/>
            )}
            {inactiveRequestPopup && (
                <RequestInactive createInactiveRequest={createInactiveRequest} backButton={closePopups} />
            )}
            <div className="user-settings-header">
                <div className="user-info">
                    <img src={userData.avatar} alt="" id="user-settings-pfp" onClick={() => setAvatarSelection(true)}></img>
                    <div className="user-name-rank">
                        <span className="user-info-name">{userData.nickname}</span>
                        <span className="user-info-rank">{userData.admin === 0 ? ranks[userData.rank] : userData.admin === 1 ? "Administrator" : "Developer"}</span>
                    </div>
                </div>
                <div className="user-settings-options">
                    <span className={`user-option ${selectedOption === 0 ? "selected" : ""}`} onClick={() => handleSelection(0)}>Общие настройки</span>
                    <span className={`user-option ${selectedOption === 1 ? "selected" : ""}`} onClick={() => handleSelection(1)}>История неактивов</span>
                    <span className={`user-option ${selectedOption === 2 ? "selected" : ""}`} onClick={() => handleSelection(2)}>История наказаний</span>
                </div>
                <div id="selected-content">
                    {selectedOption === 0 && (
                        <>
                            <div className="user-buttons-container">
                                <button id="request-inactive" onClick={() => setInactiveRequestPopup(true)}><i className="bi bi-hourglass-split"></i> Запросить
                                    неактив
                                </button>
                            </div>
                            <div className="main-content">
                                <div className="user-inputs">
                                    <span className="input-header">Старый пароль</span>
                                    <div className="input-w-icon">
                                        <input type={isOldPasswordVisible ? "text" : "password"} value={oldPassword}
                                               onChange={(e) => setOldPassword(e.target.value)}
                                               id="user-nickname-change"/>
                                        {isOldPasswordVisible ? (
                                            <i className="bi bi-eye-fill visible"
                                               onClick={() => setIsOldPasswordVisible(!isOldPasswordVisible)}></i>
                                        ) : (
                                            <i className="bi bi-eye-slash-fill"
                                               onClick={() => setIsOldPasswordVisible(!isOldPasswordVisible)}></i>
                                        )}
                                    </div>

                                    <span className="input-header">Новый пароль</span>
                                    <div className="input-w-icon">
                                        <input type={isNewPasswordVisible ? "text" : "password"}
                                               className={passwordErr.length > 0 ? "invalid" : ""} value={newPassword}
                                               onChange={(e) => setNewPassword(e.target.value)}
                                               id="user-nickname-change"/>
                                        {isNewPasswordVisible ? (
                                            <i className="bi bi-eye-fill visible"
                                               onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}></i>
                                        ) : (
                                            <i className="bi bi-eye-slash-fill"
                                               onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}></i>
                                        )}
                                    </div>

                                    <span className="input-header">Повторите новый пароль</span>
                                    <div className="input-w-icon">
                                        <input type={isNewRepeatPasswordVisible ? "text" : "password"}
                                               className={passwordRepeatErr.length > 0 ? "invalid" : ""}
                                               value={newRepeatPassword}
                                               onChange={(e) => setNewRepeatPassword(e.target.value)}
                                               id="user-nickname-change"/>
                                        {isNewRepeatPasswordVisible ? (
                                            <i className="bi bi-eye-fill visible"
                                               onClick={() => setIsNewRepeatPasswordVisible(!isNewRepeatPasswordVisible)}></i>
                                        ) : (
                                            <i className="bi bi-eye-slash-fill"
                                               onClick={() => setIsNewRepeatPasswordVisible(!isNewRepeatPasswordVisible)}></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div id="buttons-container">
                                <button id="save-changes" onClick={saveChanges}><i
                                    className="bi bi-floppy-fill"></i> Сохранить изменения
                                </button>
                                <button id="cancel-changes" onClick={resetData}><i
                                    className="bi bi-arrow-return-left"></i> Отменить
                                    изменения
                                </button>
                            </div>
                        </>
                    )}
                    {selectedOption === 1 && (
                        <>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Academy Assignments</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Неактив от</th>
                                            <th>Неактив до</th>
                                            <th>Создано</th>
                                            <th>Кол-во дней</th>
                                            <th>Статус</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.map((task, index) => (
                                            <tr key={index} id="table-row">
                                                <th>{index + 1}</th>
                                                <th>{task.dateFrom}</th>
                                                <th>{task.dateTo}</th>
                                                <th>{task.createdAt}</th>
                                                <th>{task.totalDays}</th>
                                                <th className={`status ${task.status === 0 ? "not-started" : task.status === 1 ? "done" : "not-started"}`}>
                                                    <div className="status-indicator"></div>
                                                    {task.status === 0 ? "Не проверено" : task.status === 1 ? "Одобрено" : "Отказано"}
                                                </th>

                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {selectedOption === 2 && (
                        <>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Academy Assignments</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Выдал</th>
                                            <th>Причина</th>
                                            <th>Срок</th>
                                            <th>Дата выдачи</th>
                                            <th>Тип</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.map((task, index) => (
                                            <tr key={index} id="table-row">
                                                <th>{index + 1}</th>
                                                <th>{getMemberByID(task.author).nickname}</th>
                                                <th>{task.reason}</th>
                                                <th>{task.duration ? task.duration : "Не требуется"}</th>
                                                <th>{task.date}</th>
                                                <th>{task.type === "pfpdelete" ? "Удаление фотографии" : task.type === "pfpban" ? "Бан фото профиля" : task.type === "warn" ? "Выговор" : "Workoff"}</th>

                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default UserSettings;
