import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";
import AutocompleteInput from "../Utils/AutocompleteInput";

function InviteUser({backButton, members, selectedChat, invite}) {

    const [selectedUserID, setSelectedUserID] = useState(null);


    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Приглашение пользователя в чат #{selectedChat.chatUID}</span>
                <div className="task-container">
                    <span className="point-header">Выберите пользователя</span>
                    <AutocompleteInput users={members} setUserID={setSelectedUserID} selectedID={selectedUserID}/>

                </div>

                <div className="buttons-container">
                    <button id="send-task" onClick={() => invite({userID: selectedUserID})}><i className="bi bi-send-fill"></i> Пригласить пользователя
                    </button>
                </div>
            </div>
        </>
    );
}

export default InviteUser;
