import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function ChangeUserData({backButton, selectedTask, changeData}) {

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const checkIfEmpty = () => {
        return login.trim() !== ''; 
    };

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Изменение данных пользователя</span>
                <div className="task-container">
                    <span className="point-header">Новый логин</span>
                    <input type="text" id="point-answer-input" value={login} autoComplete={"off"} placeholder="Оставьте пустым, если не хотите менять логина"
                           onChange={(e) => setLogin(e.target.value)}/>

                    <span className="point-header">Новый пароль</span>
                    <input type="text" id="point-answer-input" value={password} autoComplete={"off"}
                           onChange={(e) => setPassword(e.target.value)}/>

                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => changeData({
                        userID: selectedTask.ID,
                        changeLogin: checkIfEmpty(),
                        login: login,
                        password: password,
                    })}><i className="bi bi-send-fill"></i> Отправить
                    </button>
                </div>
            </div>
        </>
    );
}

export default ChangeUserData;
