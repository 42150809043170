import "../styles/members.css"
import {useEffect, useState} from "react";
import UserEditor from "./UserEditor";

function Members({userData, shdDisplay, sendData, members, academyTasks, divisions, openLink, ranks, positions, permissions, updateMember}) {

    const [selectedDivision, setSelectedDivision] = useState(0);
    const [allDivisions, setAllDivisions] = useState(["All", ...Object.values(divisions), "Administration"]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;
    const indexOfLastItem = (currentPage) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [currentItems, setCurrentItems] = useState(members.sort((a, b) => b.rank - a.rank).slice(indexOfFirstItem, indexOfLastItem));
    const [totalPages, setTotalPages] = useState(Math.ceil(members.length / itemsPerPage));
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [userEditor, setUserEditor] = useState(false);

    const getFilteredItems = (searchFrom) => {
        return searchFrom
            .filter(member => {
                return member.nickname.toLowerCase().includes(searchQuery);
            })
            .sort((a, b) => b.rank - a.rank);
    };

    const getSelected = () => {
        const activeMembers = members.filter(member => member.deactivated === 0);
        if(selectedDivision === 0) return activeMembers.filter(member => member.admin === 0);
        if(allDivisions[selectedDivision] !== "Administration") return activeMembers.filter(member => member.division === selectedDivision - 1 && member.admin === 0);
        if(allDivisions[selectedDivision] === "Administration") return activeMembers.filter(member => member.admin > 0).sort((a, b) => b.admin - a.admin);
        return activeMembers;
    }

    useEffect(() => {
        const activeMembers = getSelected();
        const filtered = getFilteredItems(activeMembers);

        const newTotalPages = Math.ceil(filtered.length / itemsPerPage);

        if (currentPage > newTotalPages) {
            setCurrentPage(newTotalPages === 0 ? 1 : 0);
        }

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const newCurrentItems = filtered.slice(indexOfFirstItem, indexOfLastItem);

        setCurrentItems(newCurrentItems);
        setTotalPages(newTotalPages);

    }, [searchQuery, currentPage, members, selectedDivision]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    const userCanEdit = (user) => {
        if(user.admin === 1 && userData.admin === 0){
            return false;
        }
        if(userData.admin === 0 && userData.userEditPerm === 0 && userData.academyPerm === 0 && userData.rank < 9 && userData.division !== 5){
            return false;
        }
        setSelectedUser(user);
        setUserEditor(true);
        return true;
    }

    const closeUserEditor = () => {
        setUserEditor(false);
        setSelectedUser({});
    }

    return (
        <>
            {userEditor ? (
                <UserEditor shdDisplay={shdDisplay} closeUserEditor={closeUserEditor} academyTasks={academyTasks} members={members} updateMember={updateMember} permissions={permissions} userData={userData} divisions={divisions} sendData={sendData} ranks={ranks} positions={positions} openLink={openLink} selectedMember={selectedUser}/>
            ) : (
                <div id="main-page">
                    <div className="main-members-container">
                        <span className="members-header"><i class="bi bi-people-fill"></i> Текущий состав</span>
                        <div className="divisions-list">
                            {Object.entries(allDivisions).map(([index, item]) => (
                                <span className={`division-option ${selectedDivision === Number(index) ? "selected" : ""}`} onClick={() => setSelectedDivision(Number(index))}>{item}</span>
                            ))}
                        </div>
                        <div className="members-container">
                            <div className="table-label">
                                <span className="table-label-text">Members</span>
                            </div>
                            <input type="text" autoComplete={"off"} value={searchQuery} onChange={(event) => handleSearchChange(event)} id="search" placeholder="Поиск.."/>
                            <div className="table-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Никнейм</th>
                                            <th>Ранг</th>
                                            <th>Отдел</th>
                                            <th>Должность</th>
                                            <th>Выговоры</th>
                                            <th>Workoff</th>
                                            <th>Неактив</th>
                                            <th>VKontakte</th>
                                            <th>Forum</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {selectedDivision === 0 ? (
                                        <>
                                            {currentItems.filter(member => member.admin === 0).map((member, index) => (
                                                <tr id="table-row" key={index} onClick={() => userCanEdit(member)}>
                                                    <td>#{index + 1}</td>
                                                    <td id="table-user-container">
                                                        <img src={member.avatar} id="table-user-pfp"></img>
                                                        <span className="table-user-name">{member.nickname}</span>
                                                    </td>
                                                    <td>({member.rank}) {ranks[member.rank]}</td>
                                                    <td>{divisions[member.division]}</td>
                                                    <td>{positions[member.division][member.position]}</td>
                                                    <td>{member.warn}</td>
                                                    <td>{member.workoff}</td>
                                                    <td>{member.inactive ? member.inactive : "Отсутствует"}</td>
                                                    <td><span className="link" onClick={(e)=> openLink(member.vkontakte, e)}>VKontakte</span></td>
                                                    <td><span className="link" onClick={(e)=> openLink(member.forum, e)}>Forum</span></td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : allDivisions[selectedDivision] !== "Administration" ? (
                                        <>
                                            {currentItems.filter(member => member.division === selectedDivision - 1 && member.admin === 0).map((member, index) => (
                                                <tr id="table-row" key={index} onClick={() => userCanEdit(member)}>
                                                    <td>#{index + 1}</td>
                                                    <td id="table-user-container">
                                                        <img src={member.avatar} id="table-user-pfp"></img>
                                                        <span className="table-user-name">{member.nickname}</span>
                                                    </td>
                                                    <td>({member.rank}) {ranks[member.rank]}</td>
                                                    <td>{divisions[member.division]}</td>
                                                    <td>{positions[member.division][member.position]}</td>
                                                    <td>{member.warn}</td>
                                                    <td>{member.workoff}</td>
                                                    <td>{member.inactive ? member.inactive : "Отсутствует"}</td>
                                                    <td><span className="link" onClick={(e)=> openLink(member.vkontakte, e)}>VKontakte</span></td>
                                                    <td><span className="link" onClick={(e)=> openLink(member.forum, e)}>Forum</span></td>
                                                </tr>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                        {currentItems.filter(member => member.admin > 0).sort((a, b) => b.admin - a.admin).map((member, index) => (
                                            <tr id="table-row" key={index} onClick={() => userCanEdit(member)}>
                                                <td>#{index + 1}</td>
                                                <td id="table-user-container">
                                                    <img src={member.avatar} id="table-user-pfp"></img>
                                                    <span className="table-user-name">{member.nickname}</span>
                                                </td>
                                                <td>{member.admin === 1 ? "Administrator" : "Developer"}</td>
                                                <td>{member.admin === 1 ? "Administrator" : "Developer"}</td>
                                                <td>{member.admin === 1 ? "Administrator" : "Developer"}</td>
                                                <td>{member.warn}</td>
                                                <td>{member.workoff}</td>
                                                <td>{member.inactive ? member.inactive : "Отсутствует"}</td>
                                                <td><span className="link"
                                                          onClick={(e) => openLink(member.vkontakte, e)}>VKontakte</span>
                                                </td>
                                                <td><span className="link"
                                                          onClick={(e) => openLink(member.forum, e)}>Forum</span></td>
                                            </tr>
                                        ))}
                                        </>
                                    )}
                                    <>
                                    </>
                                    </tbody>
                                </table>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Members;
