import "../styles/academy.css"
import {useEffect, useState} from "react";
import TaskEditPopup from "./TaskEditPopup";
import TaskCreatePopup from "./TaskCreatePopup";
import StageEditPopup from "./StageEditPopup";
import StageCreatePopup from "./StageCreatePopup";
import EndAcademyPopup from "./EndAcademyPopup";

function AcademyControl({updateStages, updateTask, members, ranks, divisions, userData, sendData, academyTasks, stages}) {

    const [selectedCategory, setSelectedCategory] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTask, setSelectedTask] = useState({});
    const itemsPerPage = 12;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [taskEditPopup, setTaskEditPopup] = useState(false);
    const [createNewTaskPopup, setCreateNewTaskPopup] = useState(false);
    const [stageEditPopup, setStageEditPopup] = useState(false);
    const [academyEndPopup, setAcademyEndPopup] = useState(false);
    const [createNewStagePopup, setCreateNewStagePopup] = useState(false);
    const [readyAcademicians, setReadyAcademicians] = useState([]);

    useEffect(() => {
        const academicians = members.filter(member => member.division === 0);
        const ready = academicians.filter(academician => {
            const tasksCount = academyTasks.filter(task => task.stage === academician.rank).length;
            return tasksCount <= academician.academyProgress;
        });
        setReadyAcademicians(ready);
    }, [members, academyTasks]);

    const getSelected = () => {
        switch (selectedCategory){
            case 0:
                return academyTasks;
            case 1:
                return stages;
            case 2:
                return readyAcademicians;
            default: return [];
        }
    }

    useEffect(() => {
        const tasks = getSelected();

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(tasks.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(tasks.length / itemsPerPage));
    }, [currentPage, academyTasks, stages, readyAcademicians, selectedCategory]);

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    const handleClickAcademyTask = (task) => {
        setSelectedTask(task);
        setTaskEditPopup(true);
    }

    const handleClickStage = (task) => {
        setSelectedTask(task);
        setStageEditPopup(true);
    }

    const closePopups = () => {
        setTaskEditPopup(false);
        setCreateNewTaskPopup(false);
        setCreateNewStagePopup(false);
        setAcademyEndPopup(false);
        setStageEditPopup(false);
    }

    const editAcademyTask = async (newData) => {
        const response = await sendData("EDIT_ACADEMY_TASK", "UserManager", newData);
        if(response.response_code === 200){
            updateTask(newData, "edit");
            closePopups();
        }
    }

    const deleteAcademyTask = async (id) => {
        const response = await sendData("DELETE_ACADEMY_TASK", "UserManager", {taskID: id});
        if(response.response_code === 200){
            updateTask(id, "delete");
            closePopups();
        }
    }

    const createNewAcademyTask = async (data) => {
        const response = await sendData("CREATE_ACADEMY_TASK", "UserManager", data);
        if(response.response_code === 200){
            data.ID = response.lastID;
            updateTask(data, "add");
            closePopups();
        }
    }

    const saveStageChanges = async (data) => {
        const response = await sendData("SAVE_STAGE_CHANGES", "UserManager", data);
        if(response.response_code === 200){
            updateStages(data, "edit");
            closePopups();
        }
    }

    const deleteStage = async (id) => {
        const response = await sendData("DELETE_STAGE", "UserManager", {stage: id});
        if(response.response_code === 200){
            updateStages(id, "delete");
            closePopups();
        }
    }

    const createStage = async (data) => {
        const response = await sendData("CREATE_NEW_STAGE", "UserManager", data);
        if(response.response_code === 200){
            data.ID = response.lastID;
            updateStages(data, "add");
            closePopups();
        }
    }

    const rankupUser = async (user) => {
        if(user.rank + 1 > stages.length) {
            setSelectedTask(user);
            setAcademyEndPopup(true);
        }else{
            const response = await sendData("RANK_UP_ACADEMICIAN", "UserManager", {
                userID: user.ID,
            });
            if(response.response_code === 200){
                setReadyAcademicians(readyAcademicians.filter(u => u.ID !== user.ID));
            }
        }
    }

    const endUserAcademy = async (data) => {
        const response = await sendData("END_ACADEMY", "UserManager", data);
        if(response.response_code === 200){
            setReadyAcademicians(readyAcademicians.filter(user => user.ID !== data.userID));
        }
    }

    return (
        <>
            {taskEditPopup && (
                <TaskEditPopup deleteAcademyTask={deleteAcademyTask} selectedTask={selectedTask} backButton={closePopups} stages={stages} editAcademyTask={editAcademyTask}/>
            )}
            {createNewTaskPopup && (
                <TaskCreatePopup stages={stages} backButton={closePopups} createNewAcademyTask={createNewAcademyTask} />
            )}
            {stageEditPopup && (
                <StageEditPopup stages={stages} backButton={closePopups} selectedTask={selectedTask} deleteStage={deleteStage} saveStageChanges={saveStageChanges}/>
            )}
            {createNewStagePopup && (
                <StageCreatePopup stages={stages} backButton={closePopups} createStage={createStage}/>
            )}
            {academyEndPopup && (
                <EndAcademyPopup backButton={closePopups} ranks={ranks} selectedMember={selectedTask} stages={stages} divisions={divisions} endAcademy={endUserAcademy}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i className="bi bi-backpack-fill"></i> Управление академией</span>
                    <div className="task-category-list">
                        <div className={`category-option ${selectedCategory === 0 ? "selected" : ""}`}
                             onClick={() => setSelectedCategory(0)}>Задания академии
                        </div>
                        <div className={`category-option ${selectedCategory === 1 ? "selected" : ""}`}
                             onClick={() => setSelectedCategory(1)}>Этапы академии
                        </div>
                        <div className={`category-option ${selectedCategory === 2 ? "selected" : ""}`}
                             onClick={() => setSelectedCategory(2)}>Академики
                        </div>
                    </div>
                    {selectedCategory === 0 && (
                        <>
                            <button id="new-task" onClick={() => setCreateNewTaskPopup(true)}><i className="bi bi-pen-fill"></i> Создать новое задание</button>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Academy Assignments</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Название</th>
                                            <th>Описание</th>
                                            <th>Максимальный балл</th>
                                            <th>Этап</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.sort((a, b) => b.stage - a.stage).map((task, index) => (
                                            <tr key={index} id="table-row" onClick={() => handleClickAcademyTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{task.name}</th>
                                                <th id="description">{task.description}</th>
                                                <th>{task.maxPoints}</th>
                                                <th>({task.stage}) {stages[task.stage - 1] ? stages[task.stage - 1].name : "Неизвестно"}</th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {selectedCategory === 1 && (
                        <>
                            <button id="new-task" onClick={() => setCreateNewStagePopup(true)}><i
                                className="bi bi-pen-fill"></i> Создать новый этап
                            </button>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Stages</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Название</th>
                                            <th>Этап</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.map((stage, index) => (
                                            <tr key={index} id="table-row" onClick={() => handleClickStage(stage)}>
                                                <th>{index + 1}</th>
                                                <th>{stage.name}</th>
                                                <th>{stage.stage}</th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {selectedCategory === 2 && (
                        <>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">Academicians who completed the stage</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Никнейм академика</th>
                                            <th>Этап</th>
                                            <th>Баллы</th>
                                            <th>Действие</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.map((user, index) => (
                                            <tr key={index} id="table-row">
                                                <th>{index + 1}</th>
                                                <th>{user.nickname}</th>
                                                <th>{stages[user.rank - 1] ? stages[user.rank - 1].name : "Неизвестно"}</th>
                                                <th>{user.academyMark}</th>
                                                <th id="action-th">
                                                    <button id="rankup-user" onClick={() => rankupUser(user)}>Повысить</button>
                                                </th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default AcademyControl;
