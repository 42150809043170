import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";
import AutocompleteInput from "../Utils/AutocompleteInput";

function PersonalTaskPreview({backButton, sendData, selectedTask, cancelPersonalTask, members, handleSavePersonalTask, handlePersonalTaskDelete}) {

    const [name, setName] = useState(selectedTask.name);
    const [description, setDescription] = useState(selectedTask.description);
    const [reason, setReason] = useState(selectedTask.reason);
    const [selectedUserID, setSelectedUserID] = useState(selectedTask.receiver);


    const resetChanges = () => {
        setName(selectedTask.name);
        setDescription(selectedTask.description);
        setReason(selectedTask.reason);
        setSelectedUserID(selectedTask.receiver);
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Просмотр задания</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <input type="text" id="point-answer-input" value={name} onChange={(e) => setName(e.target.value)}/>

                    <span className="point-header">Описание</span>
                    <textarea id="user-proofs" value={description} onChange={(e) => setDescription(e.target.value)}/>

                    <span className="point-header">Причина</span>
                    <input type="text" id="point-answer-input" value={reason} onChange={(e) => setReason(e.target.value)}/>

                    <span className="point-header">Пользователь</span>
                    <AutocompleteInput users={members.filter(member => member.deactivated === 0)} setUserID={setSelectedUserID} selectedID={selectedUserID}/>

                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => handleSavePersonalTask({
                        receiver: selectedUserID,
                        reason: reason,
                        name: name,
                        description: description,
                        raw_receiver: selectedTask.receiver,
                        taskID: selectedTask.ID
                    })}><i className="bi bi-send-fill"></i> Сохранить изменения
                    </button>
                    <button id="reset-task" onClick={resetChanges}><i className="bi bi-eraser-fill"></i> Сбросить
                        изменения
                    </button>
                    <button id="delete-task" onClick={() => handlePersonalTaskDelete(selectedTask)}><i
                        className="bi bi-trash-fill"></i> Удалить задание
                    </button>
                </div>
            </div>
        </>
    );
}

export default PersonalTaskPreview;
