import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function UserAcademyTaskNotStarted({backButton, selectedMember, getMemberByID, selectedTask, userData, handleCheckTask}) {

    const [name, setName] = useState(selectedTask.name);
    const [description, setDescription] = useState(selectedTask.description);
    const [maxPoints, setMaxPoints] = useState(selectedTask.maxPoints);
    const [notes, setNotes] = useState("");
    const [points, setPoints] = useState(0);

    const handlePoints = (e) => {
        let raw_points = e.target.value;
        if(raw_points < 0){
            raw_points = 0;
        }else if(raw_points > maxPoints){
            raw_points = maxPoints;
        }
        setPoints(raw_points);
    }

    const handleSendCheck = () => {
        const body = {
            ID: selectedTask.ID,
            taskID: selectedTask.ID,
            task_id: selectedTask.ID,
            userID: selectedMember.ID,
            name: name,
            description: description,
            points: points,
            notes: notes,
            status: 1,
        };

        handleCheckTask(body);
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Задание академии</span>
                <div className="task-container-scrollable">
                    <div className="task-container">
                        <span className="point-header">Название</span>
                        <span className="point-answer">{selectedTask.name}</span>

                        <span className="point-header">Описание</span>
                        <span className="point-answer">{selectedTask.description}</span>

                        <span className="point-header">Пользователь</span>
                        <span className="point-answer">{selectedMember.nickname}</span>

                        <span className="point-header">Максимальный балл</span>
                        <span className="point-answer">{selectedTask.maxPoints}</span>

                        <span className="point-header">Примечания к заданию</span>
                        <textarea id="user-proofs" value={notes} onChange={(e) => setNotes(e.target.value)}/>

                        <span className="point-header">Оценка</span>
                        <input type={"number"} id="point-answer-input" autoComplete={"off"} value={points} onChange={(e) => handlePoints(e)}/>
                    </div>
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={handleSendCheck}><i className="bi bi-send-fill"></i> Одобрить</button>
                </div>
            </div>
        </>
    );
}

export default UserAcademyTaskNotStarted;
