import "../styles/tasks.css"
import '../styles/devSupport.css'
import {useEffect, useState} from "react";
import OpenedChat from "./OpenedChat";
import CreateTicket from "./CreateTicket";
import contextMenu from "../Utils/ContextMenu";

function DevSupport({sendData, userData, members, isValidURL, shouldDisplay, openLink}) {

    const [myChats, setMyChats] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTask, setSelectedTask] = useState({});
    const itemsPerPage = 8;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(0);


    const [createTicketPopup, setCreateTicketPopup] = useState(false);
    const [chatOpened, setChatOpened] = useState(false);
    const [chatInfo, setChatInfo] = useState({});

    useEffect(() => {
        const getLinks = async () => {
            const data = await sendData("GET_MY_CHATS", "ChatManager", {});
            setMyChats(data.data);
        }

        getLinks();
    }, []);

    const getSelected =  () => {
        switch (selectedCategory) {
            case 0: return myChats.filter(chat => chat.status === 0);
            case 1: return myChats.filter(chat => chat.status === 1);
            case 2: return myChats.filter(chat => chat.saved === 1);
            default: return [];
        }
    }

    useEffect(() => {
        const tasks = getSelected();

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(tasks.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(tasks.length / itemsPerPage));
    }, [currentPage, myChats, selectedCategory]);

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' };
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    const closeCreationPopup = () => {
        setCreateTicketPopup(false);
    }

    const openChat = (chat) => {
        closeCreationPopup();
        setChatInfo(chat);
        setChatOpened(true);
    }

    const closeChat = () => {
        setChatOpened(false);
        setChatInfo({});
    }

    const updateTicket = (data, mode) => {
        if(mode === "edit") {
            setMyChats(myChats => {
                const index = myChats.findIndex(task => task.ID === data.ID);
                if (index !== -1) {
                    return [
                        ...myChats.slice(0, index),
                        {...myChats[index], ...data},
                        ...myChats.slice(index + 1)
                    ];
                }
                return myChats;
            });
        }else if(mode === "add"){
            setMyChats(previousTasks => [data, ...previousTasks]);
        }else if(mode === "delete"){
            setMyChats(myChats.filter(task => task.ID !== data));
        }
    }

    const createTicket = async (data) => {
        const response = await sendData("CREATE_TICKET", "ChatManager", data);
        if(response.response_code === 200){
            data.initiator = userData.ID;
            data.chatUID = response.chatUID;
            data.status = 0;
            data.lastMessage = response.lastMsg;
            updateTicket(data, "add");
            closeCreationPopup();
        }
    }

    return (
        <>
            {createTicketPopup && (
                <CreateTicket backButton={closeCreationPopup} createTicket={createTicket}/>
            )}
            {chatOpened ? (
                <OpenedChat openLink={openLink} updateChat={updateTicket} closeChat={closeChat} getMemberByID={getMemberByID} shouldDisplay={shouldDisplay} sendData={sendData} userData={userData} members={members} isValidURL={isValidURL} selectedChat={chatInfo}/>
            ) : (
                <div id="main-page">
                    <div className="main-tasks-container">
                        <span className="tasks-header"><i className="bi bi-chat-dots-fill"></i> Связь с разработчиком</span>
                            <div className="task-category-list">
                                <div className={`category-option ${selectedCategory === 0 ? "selected" : ""}`}
                                     onClick={() => setSelectedCategory(0)}>Активные
                                </div>
                                <div className={`category-option ${selectedCategory === 1 ? "selected" : ""}`}
                                     onClick={() => setSelectedCategory(1)}>Закрытые
                                </div>
                                {shouldDisplay(userData.admin === 2) && (
                                    <div className={`category-option ${selectedCategory === 2 ? "selected" : ""}`}
                                         onClick={() => setSelectedCategory(2)}>Сохраненные
                                    </div>
                                )}
                            </div>
                        <>
                            <div className="not-done-tasks-container">
                                <button id="new-task" onClick={() => setCreateTicketPopup(true)}
                                        style={{marginBottom: '1%', marginTop: '1%'}}>
                                    <i className="bi bi-pen-fill"></i> Создать новый тикет
                                </button>
                                <div className="tasks-table-container">
                                    {currentItems.map((question, index) => (
                                        <div key={index} id="support-my-chat" onClick={() => openChat(question)}>
                                            <div className="support-chat-header">
                                                <img src={getMemberByID(question.initiator).avatar}
                                                     className="support-user-pfp"></img>
                                                <span
                                                    className="support-chat-username">{getMemberByID(question.initiator).nickname}</span>
                                            </div>
                                            <span className="support-chat-header">{question.header}</span>
                                            <div
                                                className={`status-container ${question.status === 0 ? "open" : "closed"}`}>
                                                <div className="support-indicator"></div>
                                                <span className="support-chat-status">{question.status === 0 ? "Открыто" : "Закрыто"}</span>
                                            </div>
                                            <div className="last-answer-support">
                                                <i className="bi bi-chat-dots-fill"></i>
                                                <span className="last-answer">{question.lastMessage}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="pagination">
                                    {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            )}
        </>
    );
}

export default DevSupport;
