import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function WarrantPreview({backButton, selectedTask, members, shouldDisplay, handleOrderDelete, handleSaveOrderChanges, userData, getMemberByID, handleCancelWarrant, openLink, takeOrder, handleDoneWarrant}) {

    const [targetName, setTargetName] = useState(selectedTask.target);
    const [level, setLevel] = useState(selectedTask.level);
    const [reason, setReason] = useState(selectedTask.reason);
    const [warrantLink, setWarrantLink] = useState(selectedTask.warrantLink);
    const [proofs, setProofs] = useState(selectedTask.proofs ? selectedTask.proofs : "");

    const [proofsErr, setProofsErr] = useState("");

    useEffect(() => {
        setProofsErr("");
    }, [proofs]);

    const handleDoneOrder = () => {
        if(proofs.length < 1){
            setProofsErr("Вы ничего не ввели в поле для доказательств.");
            return;
        }

        handleDoneWarrant({ID: selectedTask.ID, proofs: proofs});
    }

    const handleLevel = (e) => {
        let raw_level = e.target.value;
        if(raw_level < 1){
            raw_level = 1;
        }else if(raw_level > 6){
            raw_level = 6;
        }
        setLevel(raw_level);
    }

    const shdDisplay = (f1, f2) => {
        return f1 && f2;
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Ордер</span>
                <div className="task-container">
                    <span className="point-header">Цель</span>
                    {shouldDisplay(userData.admin > 0, userData.warrantPerm > 0) ? (
                        <input type="text" id="point-answer-input" value={targetName} disabled={selectedTask.status === 2} onChange={(e) => setTargetName(e.target.value)}/>
                    ) : (
                        <span className="point-answer">{selectedTask.target}</span>
                    )}
                    <span className="point-header">Уровень розыска</span>
                    {shouldDisplay(userData.admin > 0, userData.warrantPerm > 0) ? (
                        <input type="number" id="point-answer-input" value={level} disabled={selectedTask.status === 2} onChange={(e) => handleLevel(e)}/>
                    ) : (
                        <span className="point-answer">{selectedTask.level} уровень розыска</span>
                    )}

                    <span className="point-header">Причина</span>
                    {shouldDisplay(userData.admin > 0, userData.warrantPerm > 0) ? (
                        <input type="text" id="point-answer-input" value={reason} disabled={selectedTask.status === 2} onChange={(e) => setReason(e.target.value)}/>
                    ) : (
                        <span className="point-answer">{selectedTask.reason}</span>
                    )}

                    <span className="point-header">Ссылка на ордер</span>
                    {shouldDisplay(userData.admin > 0, userData.warrantPerm > 0) ? (
                        <input type="text" id="point-answer-input" value={warrantLink} disabled={selectedTask.status === 2} onChange={(e) => setWarrantLink(e.target.value)}/>
                    ) : (
                        <span className="point-answer" onClick={() => openLink(selectedTask.warrantLink)}><span
                            className="link">{selectedTask.warrantLink}</span></span>
                    )}

                    {selectedTask.agent && (
                        <>
                            <span className="point-header">Агент</span>
                            <span className="point-answer">{getMemberByID(selectedTask.agent).nickname}</span>

                        </>
                    )}

                    {shdDisplay(selectedTask.agent === userData.ID, selectedTask.status === 1) && (
                        <>
                            <span className="point-header">Доказательства выполнения</span>
                            <input type="text" id="point-answer-input" value={proofs} className={proofsErr.length > 0 ? "invalid" : ""}
                                   onChange={(e) => setProofs(e.target.value)}/>
                            {proofsErr.length > 0 && (
                                <span className="error-text">{proofsErr}</span>
                            )}
                        </>
                    )}
                    {selectedTask.status === 2 && (
                        <>
                            <span className="point-header">Доказательства выполнения</span>
                            <span className="point-answer"><span className="link"
                                                                 onClick={() => openLink(proofs)}>{selectedTask.proofs}</span></span>
                        </>
                    )}

                </div>
                <div className="buttons-container">
                    {!selectedTask.agent ? (
                        <button id="send-task" onClick={() => takeOrder(selectedTask)}><i className="bi bi-send-fill"></i> Взяться за выполнение
                        </button>
                    ) : (
                    <>
                        {shdDisplay(userData.ID === selectedTask.agent, selectedTask.status < 2) && (
                            <>
                                <button id="send-task" onClick={handleDoneOrder}><i
                                    className="bi bi-send-fill"></i> Выполнить ордер
                                </button>
                                <button id="send-task" style={{background: 'rgb(var(--red-color))'}} onClick={() => handleCancelWarrant({ID: selectedTask.ID})}>
                                    <i className="bi bi-x-square-fill"></i> Отказаться от ордера
                                </button>
                            </>
                        )}
                    </>
                )}
                    {shouldDisplay(userData.admin > 0, userData.warrantPerm > 0) && (
                        <>
                            {selectedTask.status < 1 && (
                                <button id="send-task" onClick={() => handleSaveOrderChanges({
                                    ID: selectedTask.ID,
                                    target: targetName,
                                    reason: reason,
                                    level: level,
                                    warrantLink: warrantLink
                                })} style={{background: 'white', color: 'black'}}><i className="bi bi-floppy-fill"></i> Сохранить изменения
                                </button>
                            )}
                            <button id="send-task" style={{background: 'rgb(var(--red-color))'}} onClick={() => handleOrderDelete({ID: selectedTask.ID})}><i className="bi bi-trash-fill"></i> Удалить ордер
                            </button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default WarrantPreview;
