import "../styles/home.css"
import {useEffect, useState} from "react";
import PfpRules from "./PfpRules";

function Home({divisionTasks, academyTasks, verifyTasks, doneTasks, userData, ranks, divisions, setMenuOption, positions, notifications, members, stages, config, headIndex}) {

    const [userAcademyTasks, setUserAcademyTasks] = useState([]);
    const [rulesPopup, setRulesPopup] = useState(false);

    function getDeclension(number, titles) {
        const cases = [2, 0, 1, 1, 1, 2];
        return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    const getMemberByID = (id) => {
        return members.find(member => member.ID === id);
    }

    useEffect(() => {
        const updateTasks = () => {
            const newTasks = academyTasks.map(task => {
                let status = "not started";
                let details = {};

                if (verifyTasks.some(t => t.task_id === task.ID)) {
                    status = "on verification";
                    details = verifyTasks.find(t => t.task_id === task.ID);
                } else if (doneTasks.some(t => t.task_id === task.ID)) {
                    status = "completed";
                    details = doneTasks.find(t => t.task_id === task.ID);
                }

                return { ...task, status, details };
            });
            setUserAcademyTasks(newTasks);
        };
        updateTasks();
    }, [academyTasks, verifyTasks, doneTasks]);

    const closeRules = () => {
        setRulesPopup(false);
    }

    return (
        <>
            {rulesPopup && (
                <PfpRules backButton={closeRules} />
            )}
            <div id="home-page">
                <div className="user-header">
                    <div className="user-container">
                        <img src={userData.avatar} alt="" id="header-user-pfp"></img>
                        <span className="header-user-name">Здравствуй, {userData.nickname}👋</span>
                    </div>
                </div>
                <div className="bottom-part">
                    <div className="left-panel">
                        <div className="user-information-container">
                            <span className="information-header"><i className="bi bi-activity"></i> Информация</span>
                            <div className="information-point">
                                <i className="bi bi-person-vcard-fill"></i>
                                <span className="information-answer">Ваш никнейм - {userData.nickname}</span>
                            </div>
                            <div className="information-point">
                                <i className="bi bi-briefcase-fill"></i>
                                <span className="information-answer">Директор - {members.find(member => member.rank === 10) ? members.find(member => member.rank === 10).nickname : "Отсутствует"}</span>
                            </div>
                            <div className="information-point">
                                <i className="bi bi-capslock-fill"></i>
                                <span
                                    className="information-answer">Ваш ранг - ({userData.rank}) {ranks[userData.rank]}</span>
                            </div>
                            <div className="information-point">
                                <i className="bi bi-diagram-3-fill"></i>
                                <span
                                    className="information-answer">Ваш отдел - {divisions[userData.division]}</span>
                            </div>
                            <div className="information-point">
                                <i className="bi bi-star-fill"></i>
                                <span className="information-answer">Ваша должность - {
                                    userData && positions[userData.division] && positions[userData.division][userData.position]
                                        ? positions[userData.division][userData.position]
                                        : 'Загрузка...'
                                }</span>
                            </div>
                            <div className="information-point">
                                <i className="bi bi-star-fill"></i>
                                <span className="information-answer">Неактив - {userData.inactive === null ? "Отсутствует" : userData.inactive}</span>
                            </div>
                            <div className="information-point">
                                <i className="bi bi-exclamation-triangle-fill"></i>
                                <span
                                    className="information-answer">У вас {userData.warn} {getDeclension(userData.warn, ["выговор", "выговора", "выговоров"])}</span>
                            </div>
                            <div className="information-point">
                                <i className="bi bi-piggy-bank-fill"></i>
                                <span
                                    className="information-answer">У вас {userData.warnPoints} {getDeclension(userData.warnPoints, ["балл", "балла", "баллов"])} на снятие выговора</span>
                            </div>
                            <div className="information-point">
                                <i className="bi bi-hammer"></i>
                                <span
                                    className="information-answer">У вас {userData.workoff} Workoff</span>
                            </div>
                            <div className="information-point">
                                <i className="bi bi-piggy-bank-fill"></i>
                                <span
                                    className="information-answer">У вас {userData.workoffPoints} {getDeclension(userData.warnPoints, ["балл", "балла", "баллов"])} на снятие Workoff</span>
                            </div>
                        </div>
                        <div className="user-information-container">
                            <span className="information-header"><i
                                className="bi bi-cup-hot-fill"></i> {divisions[userData.division]}</span>
                            {userData.division === 0 ? (
                                <>
                                    <div className="information-point">
                                        <i className="bi bi-mortarboard-fill"></i>
                                        <span
                                            className="information-answer">На данный момент Вы являетесь академиком.</span>
                                    </div>
                                    <div className="information-point">
                                        <i className="bi bi-star-fill"></i>
                                        <span
                                            className="information-answer">Глава академии - {members.find(member => member.division === 0 && member.position === 2) ? members.find(member => member.division === 0 && member.position === 2).nickname : "Отсутствует"}</span>
                                    </div>
                                    <div className="information-point">
                                        <i className="bi bi-piggy-bank-fill"></i>
                                        <span
                                            className="information-answer">У вас {userData.academyMark} {getDeclension(userData.academyMark, ["балл", "балла", "баллов"])} академии</span>
                                    </div>
                                    <div className="information-point">
                                        <i className="bi bi-box-fill"></i>
                                        <span
                                            className="information-answer">Текущий этап - {stages[userData.rank - 1] ? stages[userData.rank - 1].name : "Неизвестно"}</span>
                                    </div>
                                    <div className="information-point">
                                        <i className="bi bi-calculator-fill"></i>
                                        <span
                                            className="information-answer">Осталось выполнить 1 задание</span>
                                    </div>
                                    <div className="information-point">
                                        <i className="bi bi-hourglass-split"></i>
                                        <span
                                            className="information-answer">Академия до {userData.academyUntil}</span>
                                    </div>
                                    <div className="information-point">
                                        <i className="bi bi-hourglass-split"></i>
                                        <span
                                            className="information-answer">Академия продлена на {userData.academyExtension} {getDeclension(userData.academyExtension, ["день", "дня", "дней"])}</span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="information-point">
                                        <i className="bi bi-mortarboard-fill"></i>
                                        <span
                                            className="information-answer">Вы являетесь частью отдела {divisions[userData.division]}.</span>
                                    </div>
                                    <div className="information-point">
                                        <i className="bi bi-star-fill"></i>
                                        <span
                                            className="information-answer">Глава отдела - {members.find(member => member.division === userData.division && member.position === headIndex[userData.division]) ? members.find(member => member.division === userData.division && member.position === headIndex[userData.division]).nickname : "Отсутствует"}</span>
                                    </div>
                                    <div className="information-point">
                                        <i className="bi bi-list-task"></i>
                                        <span
                                            className="information-answer">Не выполненных заданий отдела - 2</span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="right-panel">
                        <div className="tasks-container">
                            {userData.division > 0 ? (
                            <>
                                <span className="task-container-header"><i
                                    className="bi bi-cup-hot-fill"></i> {divisions[userData.division]}</span>
                                <span className="tasks-container-information">Ниже представлен краткий перечень заданий, назначенных вашему отделу.</span>
                                <div className="table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Название</th>
                                            <th>Выполнить до</th>
                                            <th>Автор</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {divisionTasks.filter(t => t.division === userData.division).slice(0, 4).map((task) => (
                                                <tr id="table-row">
                                                    <td>{task.name}</td>
                                                    <td>{task.doneTo}</td>
                                                    <td>{getMemberByID(task.createdBy).nickname}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <button id="more-division-tasks" onClick={() => setMenuOption(2)}><i className="bi bi-list-ol"></i> Просмотреть все задания
                                    </button>
                                </div>
                            </>
                            ) : (
                                <>
                                    <span className="task-container-header"><i
                                        className="bi bi-cup-hot-fill"></i> {divisions[userData.division]}</span>
                                    <span className="tasks-container-information">Добро пожаловать в академию Территориального Офиса Федерального Бюро Расследований.</span>
                                    <span className="tasks-container-information">Ниже представлен краткий перечень не выполненных заданий академии.</span>
                                    <div className="table-container">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>Название</th>
                                                <th>Автор</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {userAcademyTasks.filter(t => t.status === "not started").slice(0, 4).map((task) => (
                                                <tr id="table-row">
                                                    <td>{task.name}</td>
                                                    <td>{getMemberByID(task.createdBy).nickname}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        <button id="more-division-tasks" onClick={() => setMenuOption(2)}><i
                                            className="bi bi-list-ol"></i> Просмотреть все задания
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="right-edge-panel">
                        <div className="profile-information">
                            <span className="profile-information-header"><i
                                className="bi bi-person-vcard-fill"></i> Профиль</span>
                            <div className="profile-information-point">
                                <i className="bi bi-clock-fill"></i>
                                <span className="profile-information-answer">Вы с нами от {userData.regDate}</span>
                            </div>
                            <div className="profile-information-point">
                                <i className="bi bi-ban-fill"></i>
                                <span
                                    className="profile-information-answer">Блокировка фотографии профиля - {userData.pfpBan ? userData.pfpBan : "Отсутствует"}</span>
                            </div>
                            <div className="profile-information-point link" onClick={() => setRulesPopup(true)}>
                                <i className="bi bi-link-45deg"></i>
                                <span
                                    className="profile-information-answer">Правила для изображений профиля</span>
                            </div>
                        </div>
                        <div className="notifications-container">
                            <span className="notifications-container-header"><i
                                className="bi bi-bell-fill"></i> Оповещения</span>
                            <div className="notifications-list">
                                {notifications.slice().reverse().map((notification) => (
                                    <div className="notification">
                                        <div className="notification-header">
                                        <span className="notification-header-text"><i
                                            className="bi bi-bell-fill"></i> Оповещение</span>
                                            <span className="notification-date">{notification.sent}</span>
                                        </div>
                                        <span className="notification-main-text">{notification.text}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
