import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function PointTaskEditPopup({backButton, selectedTask, saveChanges, deleteStage}) {

    const [name, setName] = useState(selectedTask.name);
    const [description,setDescription] = useState(selectedTask.description);
    const [points,setPoints] = useState(selectedTask.points);
    const [type, setType] = useState(selectedTask.type);

    const types = {
        0: "warn",
        1: "workoff"
    };

    const handlePoints = (e) => {
        let raw_points = parseInt(e.target.value, 10);
        if(raw_points < 1){
            raw_points = 1;
        }
        setPoints(raw_points);
    }

    const handleSaveStage = () => {
        saveChanges({taskID: selectedTask.ID, name: name, description: description, points:points, type: type});
    }


    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Задание на баллы</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <input type="text" id="point-answer-input" value={name} autoComplete={"off"}
                           onChange={(e) => setName(e.target.value)}/>

                    <span className="point-header">Описание</span>
                    <textarea id="user-proofs"
                              value={description} onChange={(e) => setDescription(e.target.value)}/>

                    <span className="point-header">Баллы</span>
                    <input type="number" id="point-answer-input" value={points} autoComplete={"off"}
                           onChange={(e) => handlePoints(e)}/>

                    <span className="point-header">Тип</span>
                    <select name="divisions" id="divisions" value={type}
                            onChange={(e) => setType(e.target.value)}>
                        {Object.entries(types).map(([index, option]) => (
                            <option
                                key={index}>{option}</option>
                        ))}
                    </select>
                </div>


                <div className="buttons-container">
                    <button id="send-task" onClick={handleSaveStage}><i className="bi bi-send-fill"></i> Сохранить
                        изменения
                    </button>
                    <button id="delete-task" onClick={() => deleteStage({taskID: selectedTask.ID})}><i
                        className="bi bi-trash-fill"></i> Удалить задание
                    </button>
                </div>
            </div>
        </>
    );
}

export default PointTaskEditPopup;
