import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function PfpBanPopup({backButton, sendData, selectedMember, userData}) {

    const [reason, setReason] = useState("");
    const [days, setDays] = useState(0);
    const [reasonErr, setReasonErr] = useState("");
    const [daysErr, setDaysErr] = useState("");

    const getMaxUserBan = () => {
        if(userData.admin === 0) return 30;
        return 2000;
    }

    const handleDays = (e) => {
        let raw_days = e.target.value;
        if(raw_days > getMaxUserBan()){
            raw_days = getMaxUserBan();
        }
        setDays(raw_days);
        setDaysErr("");
    }

    const handleReason = (e) => {
        setReason(e.target.value);
        setReasonErr("");
    }

    const banPfp = async () => {
        let error = false;
        if(reason.length < 4 || reason.length > 64){
            setReasonErr("Причина не может быть меньше 4 и больше 64 символов.");
            error = true;
        }
        if(days <= 1 || days > getMaxUserBan()){
            setDaysErr("Количество дней не может превышать " + getMaxUserBan() + " или быть меньше 1.");
            error = true;
        }

        if(error) return;

        const response = await sendData("BAN_USER_PFP", "UserManager", {
            ID: selectedMember.ID,
            reason: reason,
            days: days
        })

        if(response.response_code === 200){
            selectedMember.avatar = response.defaultImage;
            backButton();
        }


    }

    return (
        <>
            <div className="darker"></div>
            <div id="pfp-ban-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="pfp-ban-popup-header">Блокировка смены изображения профиля</span>
                <div className="main-container">
                    <span className="pfp-ban-text">Вы собираетесь заблокировать возможность изменения изображения профиля для пользователя {selectedMember.nickname}. После блокировки пользователь не сможет загрузить новое изображение профиля в течение указанного срока, а текущее изображение будет удалено.
                    </span>
                    <div className="user-edit-input-container">
                        <span className={`user-edit-input-header`}>Причина</span>
                        <input type="text" id="user-nickname" className={reasonErr.length > 0 ? "invalid" : ""} autoComplete={"off"} value={reason}
                               onChange={(e) => handleReason(e)}/>
                        {reasonErr.length > 0 && (
                            <span className="error-text">{reasonErr}</span>
                        )}
                    </div>
                    <div className="user-edit-input-container">
                        <span className="user-edit-input-header">Дни</span>
                        <input type="number" id="user-nickname" className={daysErr.length > 0 ? "invalid" : ""} autoComplete={"off"} value={days}
                               onChange={(e) => handleDays(e)}/>
                        {daysErr.length > 0 && (
                            <span className="error-text">{daysErr}</span>
                        )}
                    </div>

                    <button id="ban-pfp" onClick={banPfp}><i class="bi bi-ban-fill"></i> Заблокировать</button>
                </div>
            </div>
        </>
    );
}

export default PfpBanPopup;
