import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";
import AutocompleteInput from "../Utils/AutocompleteInput";

function CreateNewPersonalTask({backButton, members, createPersonalTask}) {

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [reason, setReason] = useState("");
    const [selectedUserID, setSelectedUserID] = useState(null);

    const [nameErr, setNameErr] = useState("");
    const [userErr, setUserErr] = useState("");

    const handeCreate = (data) => {
        let error = false;
        if(name.length < 4 || name.length > 64){
            setNameErr("Название не может быть меньше 4 и больше 64 символов");
            error = true;
        }
        if(selectedUserID == null){
            setUserErr("Вы не выбрали пользователя");
            error = true;
        }

        if(error) return;
        createPersonalTask(data);
    }


    useEffect(() => {
        setNameErr("");
        setUserErr("");
    }, [name, selectedUserID]);

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Создание персонального задания</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <input type="text" id="point-answer-input" className={nameErr.length > 0 ? "invalid" : ""} value={name} onChange={(e) => setName(e.target.value)}/>
                    {nameErr.length > 0 && (
                        <span className="error-text">{nameErr}</span>
                    )}

                    <span className="point-header">Описание</span>
                    <textarea id="user-proofs" value={description} onChange={(e) => setDescription(e.target.value)}/>

                    <span className="point-header">Причина</span>
                    <input type="text" id="point-answer-input" value={reason} onChange={(e) => setReason(e.target.value)}/>

                    <span className="point-header">Пользователь</span>
                    <AutocompleteInput users={members.filter(member => member.deactivated === 0)} setUserID={setSelectedUserID} selectedID={selectedUserID}/>
                    {userErr.length > 0 && (
                        <span className="error-text">{userErr}</span>
                    )}

                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => handeCreate({
                        receiver: selectedUserID,
                        reason: reason,
                        name: name,
                        description: description
                    })}><i className="bi bi-send-fill"></i> Создать новое задание
                    </button>
                </div>
            </div>
        </>
    );
}

export default CreateNewPersonalTask;
