import React from 'react';

const ContextMenu = ({ xPos, yPos, menuItems }) => {
    if (!xPos || !yPos) {
        return null;
    }

    const style = {
        top: yPos,
        left: xPos,
    }

    return (
        <ul id="context-menu" style={style}>
            {menuItems.map((item, index) => (
                <li key={index} onClick={item.onClick}>
                    {item.text}
                </li>
            ))}
        </ul>
    );
};

export default ContextMenu;