import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function SendPointTask({backButton, selectedTask, sendVerifyTask}) {

    const [proofs, setProofs] = useState("");

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Задание на баллы</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <span className="point-answer">{selectedTask.name}</span>

                    <span className="point-header">Описание</span>
                    <span className="point-answer">{selectedTask.description}</span>

                    <span className="point-header">Баллы</span>
                    <span className="point-answer">{selectedTask.points}</span>

                    <span className="point-header">Доказательства/примечания</span>
                    <textarea id="user-proofs" value={proofs} onChange={(e) => setProofs(e.target.value)}/>
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => sendVerifyTask({taskID: selectedTask.ID, proofs: proofs})}><i className="bi bi-send-fill"></i> Отправить на проверку</button>
                </div>
            </div>
        </>
    );
}

export default SendPointTask;
