import "../styles/userEditor.css"
import {useEffect, useState} from "react";
import PfpBanPopup from "./PfpBanPopup";
import PfpDelete from "./PfpDelete";
import WarnPopup from "./WarnPopup";
import WorkoffPopup from "./WorkoffPopup";
import UserAcademyTaskNotStarted from "./UserAcademyTaskNotStarted";
import UserAcademyTaskVerify from "./UserAcademyTaskVerify";
import UserDoneTaskAcademyPopup from "./UserDoneTaskAcademyPopup";
import DeleteMemberPopup from "./DeleteMemberPopup";
import ChangeUserData from "./ChangeUserData";

function UserEditor({userData, sendData, shdDisplay, selectedMember, academyTasks, divisions, openLink, members, ranks, positions, closeUserEditor, permissions, updateMember}) {

    const [nickname, setNickname] = useState(selectedMember.nickname);
    const [rank, setRank] = useState(selectedMember.rank);
    const [warns, setWarns] = useState(selectedMember.warn);
    const [warnPoints, setWarnPoints] = useState(selectedMember.warnPoints);
    const [workoff, setWorkoff] = useState(selectedMember.workoff);
    const [workoffPoints, setWorkoffPoints] = useState(selectedMember.workoffPoints);
    const [division, setDivision] = useState(divisions[selectedMember.division]);
    const [position, setPosition] = useState(positions[selectedMember.division][selectedMember.position]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [selectedTask, setSelectedTask] = useState({});
    const [showNotStartedAcademyPopup, setShowNotStartedAcademyPopup] = useState(false);
    const [showVerifyAcademyPopup, setShowVerifyAcademyPopup] = useState(false);
    const [showDoneTaskAcademyPopup, setShowDoneTaskAcademyPopup] = useState(false);

    const [userVerifyTasks, setUserVerifyTasks] = useState([]);
    const [userDoneTasks, setUserDoneTasks] = useState([]);
    const [userPunishData, setUserPunishData] = useState([]);
    const [userUnPunishData, setUserUnPunishData] = useState([]);
    const [userPersonalTaskHistory, setUserPersonalTaskHistory] = useState([]);
    const [deactivatePopup, setDeactivatePopup] = useState(false);

    const [permissionsCopy, setPermissionsCopy] = useState({
        "userEditPerm": 0,
        "academyPerm": 0,
        "userPermEdit": 0,
        "regCheck": 0,
        "punishPerm": 0,
        "warrantPerm": 0,
        "divisionCheck": 0,
        "voiceExamPerm": 0,
        "inactiveReqCheckPerm": 0,
        "allDivisionCheckPerm": 0,
        "examEditPerm": 0,
        "editTasksPerm": 0,
    });

    const [pfpBanPopup, setPfpBanPopup] = useState(false);
    const [pfpDelete, setPfpDelete] = useState(false);

    const [warnPopup, setWarnPopup] = useState(false);
    const [workoffPopup, setWorkoffPopup] = useState(false);
    const [userEditPopup, setUserEditPopup] = useState(false);
    const [userAcademyTasks, setUserAcademyTasks] = useState([]);

    const [vk, setVK] = useState(selectedMember.vkontakte);
    const [forum, setForum] = useState(selectedMember.forum);

    useEffect(() => {
        const getUserData = async () => {
            const verData = await sendData("GET_USER_VERIFY_TASKS_BY_ID", "UserManager", {ID: selectedMember.ID});
            setUserVerifyTasks(verData.data ? verData.data : []);

            const doneData = await sendData("GET_USER_DONE_TASKS_BY_ID", "UserManager", {ID: selectedMember.ID});
            setUserDoneTasks(doneData.data ? doneData.data : []);

            const punishData = await sendData("GET_USER_PUNISHES_BY_ID", "UserManager", {ID: selectedMember.ID});
            setUserPunishData(punishData.data ? punishData.data : []);

            const unpunishData = await sendData("GET_USER_UNPUNISH_DATA_BY_ID", "UserManager", {ID: selectedMember.ID});
            setUserUnPunishData(unpunishData.data ? unpunishData.data : []);

            const userPersonalHistoryData = await sendData("GET_USER_PERSONAL_TASK_HISTORY_BY_ID", "UserManager", {ID: selectedMember.ID});
            setUserPersonalTaskHistory(userPersonalHistoryData.data ? userPersonalHistoryData.data : []);
        }

        getUserData();
    }, [selectedMember]);

    const autoSelector = () => {
        if(userData.admin > 0 || userData.punishPerm > 0 || userData.userEditPerm > 0 ) return 0;
        return 1;
    }

    const [selectedOption, setSelectedOption] = useState(autoSelector());

    const getSelected = () => {
        switch(selectedOption) {
            case 1:
                return userAcademyTasks;
            case 2:
                return userPunishData;
            case 3:
                return userUnPunishData;
            case 4:
                return userPersonalTaskHistory;
            default: return [];
        }
    }

    useEffect(() => {
        const tasks = getSelected();
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(tasks.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil((tasks.length / itemsPerPage)));
    }, [currentPage, selectedOption, userAcademyTasks, userPunishData, userUnPunishData]);

    useEffect(() => {
        const updateTasks = () => {
            const newTasks = academyTasks.map(task => {
                let status = "not started";
                let details = {};

                if (userVerifyTasks.some(t => t.task_id === task.ID)) {
                    status = "on verification";
                    details = userVerifyTasks.find(t => t.task_id === task.ID);
                } else if (userDoneTasks.some(t => t.task_id === task.ID)) {
                    status = "completed";
                    details = userDoneTasks.find(t => t.task_id === task.ID);
                }

                return { ...task, status, details };
            });
            setUserAcademyTasks(newTasks);
        };
        updateTasks();
    }, [academyTasks, userVerifyTasks, userDoneTasks]);

    const handleNextPage = () => {
        if (currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' };
    }
    const handleBackPage = () => {
        if (currentPage - 1 > 0) {
            setCurrentPage(currentPage - 1);
        }
    }


    const handleRankChange = (e) => {
        let raw_rank = e.target.value;
        if(raw_rank > 10){
            raw_rank = 10;
        }else if(raw_rank < 1){
            raw_rank = 1;
        }
        setRank(raw_rank);
    }

    useEffect(() => {
        const getUserPermissions = async () => {
            const response = await sendData("GET_USER_DATA_BY_ID", "UserManager", {ID: selectedMember.ID});
            const newPermissions = {
                ...permissions,
                userEditPerm: response.data.userEditPerm,
                userPermEdit: response.data.userPermEdit,
                regCheck: response.data.regCheck,
                academyPerm: response.data.academyPerm,
                punishPerm: response.data.punishPerm,
                divisionCheck: response.data.divisionCheck,
                inactiveReqCheckPerm: response.data.inactiveReqCheckPerm,
                voiceExamPerm: response.data.voiceExamPerm,
                examEditPerm: response.data.examEditPerm,
                warrantPerm: response.data.warrantPerm,
                allDivisionCheckPerm: response.data.allDivisionCheckPerm,
                editTasksPerm: response.data.editTasksPerm,
            };
            setPermissionsCopy(newPermissions)
        }

        getUserPermissions();
    }, [selectedMember]);


    function getDivisionId(value) {
        const divisionId = Object.keys(divisions).find(key => divisions[key] === value);
        return divisionId;
    }

    const handleDivisionChange = (e) => {
        const divisionValue = e.target.value;
        setDivision(divisionValue);

        const divId = getDivisionId(divisionValue);

        if (divId !== undefined && positions[divId]) {
            const firstPositionKey = Object.keys(positions[divId])[0];
            const firstPosition = positions[divId][firstPositionKey];
            setPosition(firstPosition);
        }
    }

    const handleWarnChange = (e) => {
        let raw_warn = e.target.value;
        if(raw_warn > 3){
            raw_warn = 3;
        }else if(raw_warn < 0){
            raw_warn = 0;
        }
        setWarns(raw_warn);
    }

    const handleWarnPoints = (e) => {
        let raw_warnPoints = e.target.value;
        if(raw_warnPoints < 0){
            raw_warnPoints = 0;
        }
        setWarnPoints(raw_warnPoints);
    }

    const handleWorkoffChange = (e) => {
        let raw_warn = e.target.value;
        if(raw_warn > 3){
            raw_warn = 3;
        }else if(raw_warn < 0){
            raw_warn = 0;
        }
        setWorkoff(raw_warn);
    }

    const handleWorkoffPoints = (e) => {
        let raw_warnPoints = e.target.value;
        if(raw_warnPoints < 0){
            raw_warnPoints = 0;
        }
        setWorkoffPoints(raw_warnPoints);
    }

    const handleChangePermission = (permission) => {
        if(userData.userPermEdit === 1 || userData.admin > 0) {
            setPermissionsCopy(prevPermissions => {
                return {
                    ...prevPermissions,
                    [permission]: prevPermissions[permission] === 0 ? 1 : 0
                };
            });
        }
    }

    const allowedPermissions = Object.entries(permissionsCopy)
        .filter(([key, value]) => value === 1)
        .map(([key, value]) => key);

    const notAllowedPermissions = Object.entries(permissionsCopy)
        .filter(([key, value]) => value === 0)
        .map(([key, value]) => key);

    const cancelChanges = () => {
        setNickname(selectedMember.nickname);
        setWarns(selectedMember.warn);
        setRank(selectedMember.rank);
        setWorkoff(selectedMember.workoff);
        setWorkoffPoints(selectedMember.workoffPoints);
        setWarnPoints(selectedMember.warnPoints);
        setPermissionsCopy(permissions);
        setDivision(divisions[selectedMember.division]);
        setPosition(positions[selectedMember.division][selectedMember.position]);
    }

    const closePopups = () => {
        setPfpBanPopup(false);
        setShowNotStartedAcademyPopup(false);
        setPfpDelete(false);
        setWarnPopup(false);
        setShowDoneTaskAcademyPopup(false);
        setShowVerifyAcademyPopup(false);
        setWorkoffPopup(false);
        setDeactivatePopup(false);
        setUserEditPopup(false);
    }

    const selectAcademyTask = (task) => {
        setSelectedTask(task);
        if(task.status === "not started") setShowNotStartedAcademyPopup(true);
        if(task.status === "on verification") setShowVerifyAcademyPopup(true);
        if(task.status === "completed") setShowDoneTaskAcademyPopup(true);
    }

    const removeWarn = async () => {
        const response = await sendData("REMOVE_USER_WARN", "UserManager", {
            ID: selectedMember.ID
        });

        if(response.response_code === 200) {
            selectedMember.warn = selectedMember.warn - 1;
        }
    }

    function findPositionKey(divisionValue, positionValue) {
        const divisionKey = Object.keys(divisions).find(key => divisions[key] === divisionValue);
        if (!divisionKey) {
            console.error("Division not found");
            return null;
        }

        const positionDict = positions[divisionKey];
        if (!positionDict) {
            console.error("Position dictionary not found for the division");
            return null;
        }

        const positionKey = Object.keys(positionDict).find(key => positionDict[key] === positionValue);
        if (!positionKey) {
            console.error("Position not found in the given division");
            return null;
        }

        return Number(positionKey);
    }

    const handleForceDoneTask = async (data) => {
        const response = await sendData("FORCE_DONE_ACADEMY_TASK", "UserManager", data);
        if(response.response_code === 200){
            closePopups();
        }
        selectedTask.status = "completed";
        const newData = data;
        newData.ID = response.lastID;
        selectedTask.details = newData;
    }

    const handleForceTaskCheck = async (data) => {
        const response = await sendData("FORCE_CHECK_ACADEMY_TASK", "UserManager", data);
        if(response.response_code === 200){
            closePopups();
        }

        selectedTask.status = data.status === 0 ? "not started" : "completed";
        selectedTask.details = data;
    }

    const handleForceTaskUpdate = async (data) => {
        const response = await sendData("FORCE_UPDATE_ACADEMY_TASK", "UserManager", data);
        if(response.response_code === 200){
            closePopups();
        }

        if(data.status === 0){
            selectedTask.status = "not started";
            selectedTask.details = {};
        }else{
            selectedTask.details.points = data.points;
        }
    }

    const removeWorkoff = async () => {
        const response = await sendData("REMOVE_USER_WORKOFF", "UserManager", {
            ID: selectedMember.ID
        });

        if(response.response_code === 200) {
            selectedMember.workoff = selectedMember.workoff - 1;
        }
    }

    const shouldDisplayPunishes = () => {
        return userData.punishPerm === 1 || userData.admin > 0;
    }

    const saveChanges = async () => {
        const body = {
            ID: selectedMember.ID,
            nickname: nickname,
            rank: rank,
            division: getDivisionId(division),
            position: findPositionKey(division, position),
            vkontakte: vk,
            forum: forum,
            warn: warns,
            warnPoints: warnPoints,
            workoff: workoff,
            workoffPoints: workoffPoints,
            userEditPerm: permissionsCopy.userEditPerm,
            academyPerm: permissionsCopy.academyPerm,
            warrantPerm: permissionsCopy.warrantPerm,
            userPermEdit: permissionsCopy.userPermEdit,
            regCheck: permissionsCopy.regCheck,
            allDivisionCheckPerm: permissionsCopy.allDivisionCheckPerm,
            editTasksPerm: permissionsCopy.editTasksPerm,
            punishPerm: permissionsCopy.punishPerm,
            voiceExamPerm: permissionsCopy.voiceExamPerm,
            examEditPerm: permissionsCopy.examEditPerm,
            inactiveReqCheckPerm: permissionsCopy.inactiveReqCheckPerm,
            divisionCheck: permissionsCopy.divisionCheck
        };
        const response = await sendData("SAVE_USER_PROFILE_CHANGES", "UserManager", body);
        if(response.response_code === 200){
            updateMember(selectedMember.ID, body);
        }
    }

    const unbanUserPfp = async () => {
        const response = await sendData("UNBAN_USER_PFP", "UserManager", {userID: selectedMember.ID});
    }

    const shouldDisplay = (f1, f2) => {
        return f1 && f2;
    }

    const deactivateUser = async () => {
        setDeactivatePopup(true);
    }

    const handleDeactivateUser = async (data) => {
        const response = await sendData("DEACTIVATE_USER", "UserManager", data);

        if(response.response_code === 200){
            closePopups();
            closeUserEditor();
        }
    }

    const changeUserLoginData = async (data) => {
        const response = await sendData("CHANGE_USER_LOGIN_DATA", "UserManager", data);
        if(response.response_code === 200){
            closePopups();
        }
    }

    return (
        <>
            {showNotStartedAcademyPopup && (
                <UserAcademyTaskNotStarted backButton={closePopups} selectedTask={selectedTask} getMemberByID={getMemberByID} selectedMember={selectedMember} handleCheckTask={handleForceDoneTask} />
            )}
            {showVerifyAcademyPopup && (
                <UserAcademyTaskVerify selectedTask={selectedTask} backButton={closePopups} selectedMember={selectedMember} handleCheckTask={handleForceTaskCheck}/>
            )}
            {showDoneTaskAcademyPopup && (
                <UserDoneTaskAcademyPopup selectedTask={selectedTask} backButton={closePopups} selectedMember={selectedMember} handleCheckTask={handleForceTaskUpdate}/>
            )}
            {deactivatePopup && (
                <DeleteMemberPopup selectedMember={selectedMember} backButton={closePopups} ranks={ranks} divisions={divisions} positions={positions} handleDeactivateUser={handleDeactivateUser}/>
            )}
            {userEditPopup && (
                <ChangeUserData backButton={closePopups} selectedTask={selectedMember} changeData={changeUserLoginData}/>
            )}
            <div id="main-page">
                {pfpBanPopup && (
                    <PfpBanPopup sendData={sendData} backButton={closePopups} selectedMember={selectedMember} userData={userData}/>
                )}
                {pfpDelete && (
                    <PfpDelete sendData={sendData} backButton={closePopups} selectedMember={selectedMember} userData={userData}/>
                )}
                {warnPopup && (
                    <WarnPopup selectedMember={selectedMember} userData={userData} backButton={closePopups} sendData={sendData}/>
                )}
                {workoffPopup && (
                    <WorkoffPopup userData={userData} backButton={closePopups} sendData={sendData} selectedMember={selectedMember}/>
                )}
                <div className="back-button" onClick={closeUserEditor}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="selected-user-name">Редактирование пользователя {selectedMember.nickname} #{selectedMember.ID}</span>
                <div className="user-edit-content">
                    <div className="user-settings-container">
                        <div className="user-editor-header">
                            <img src={selectedMember.avatar} alt={""} id="user-editor-pfp"></img>
                            <div className="user-edit-name-container">
                                <span className="user-editor-nickname">{selectedMember.nickname}</span>
                                <span className="user-editor-rank">{selectedMember.admin === 0 ? ranks[selectedMember.rank] : selectedMember.admin === 1 ? "Administrator" : "Developer"}</span>
                                <span className="user-editor-rank">{selectedMember.lastLog}</span>
                            </div>
                        </div>
                        <div id="option-selection">
                            {shdDisplay(userData.admin > 0, userData.punishPerm > 0, userData.userEditPerm > 0) && (
                                <span className={`option ${selectedOption === 0 ? "selected" : ""}`}
                                      onClick={() => setSelectedOption(0)}>Общее</span>
                            )}
                            {shdDisplay(userData.admin > 0, userData.academyPerm > 0) && (
                                <span className={`option ${selectedOption === 1 ? "selected" : ""}`}
                                      onClick={() => setSelectedOption(1)}>Академия</span>
                            )}
                            {shdDisplay(userData.admin > 0, userData.userEditPerm > 0) && (
                            <span className={`option ${selectedOption === 2 ? "selected" : ""}`}
                                  onClick={() => setSelectedOption(2)}>История наказаний</span>
                            )}
                            {shdDisplay(userData.admin > 0, userData.userEditPerm > 0) && (
                            <span className={`option ${selectedOption === 3 ? "selected" : ""}`}
                                  onClick={() => setSelectedOption(3)}>История снятия наказаний</span>
                            )}
                            {shdDisplay(userData.admin > 0, userData.userEditPerm > 0) && (
                            <span className={`option ${selectedOption === 4 ? "selected" : ""}`}
                                  onClick={() => setSelectedOption(4)}>История персональных заданий</span>
                            )}
                        </div>
                        {selectedOption === 0 && (
                            <>
                            {shouldDisplayPunishes() && (
                                <div className="user-punishment-list">
                                    <button id="punish" onClick={() => setPfpDelete(true)}><i class="bi bi-image-fill"></i> Удалить изображение профиля</button>
                                    {selectedMember.pfpBan ? (
                                        <button id="punish" onClick={()=> unbanUserPfp()}><i class="bi bi-ban-fill"></i> Разблокировать смену изображения профиля</button>
                                    ) : (
                                        <button id="punish" onClick={()=> setPfpBanPopup(true)}><i class="bi bi-ban-fill"></i> Заблокировать смену изображения профиля</button>
                                    )}
                                    <button id="punish" onClick={() => setWarnPopup(true)}><i class="bi bi-exclamation-triangle-fill"></i> Выдать выговор</button>
                                    <button id="punish" onClick={() => setWorkoffPopup(true)}><i class="bi bi-hammer"></i> Выдать Workoff</button>
                                    {selectedMember.warn > 0 && (
                                        <button id="punish" onClick={removeWarn}><i className="bi bi-check-square-fill"></i> Снять выговор
                                        </button>
                                    )}
                                    {selectedMember.workoff > 0 && (
                                        <button id="punish" onClick={removeWorkoff}><i className="bi bi-check-square-fill"></i> Снять Workoff
                                        </button>
                                    )}
                                </div>
                            )}
                            <div className="settings-container">
                            <div className="left-panel">
                                    <div className="user-edit-input-container">
                                        <span className="user-edit-input-header">Никнейм</span>
                                        <input type="text" id="user-nickname" autoComplete={"off"} value={nickname}
                                               onChange={(e) => setNickname(e.target.value)}/>
                                    </div>

                                    <div className="user-edit-input-container">
                                        <span className="user-edit-input-header">Ранг</span>
                                        <input type="number" id="user-nickname" autoComplete={"off"} value={rank}
                                               onChange={(e) => handleRankChange(e)}/>
                                    </div>

                                    <div className="user-edit-input-container">
                                        <span className="user-edit-input-header">Отдел</span>
                                        <select name="divisions" id="divisions" value={division}
                                                onChange={(e) => handleDivisionChange(e)}>
                                            {Object.entries(divisions).map(([index, item]) => (
                                                <option
                                                    key={index}>{item}</option>
                                            ))}
                                        </select>

                                        <span className="user-edit-input-header">Должность</span>
                                        <select name="position" id="position" value={position}
                                                onChange={(e) => setPosition(e.target.value)}>
                                            {Object.entries(positions[getDivisionId(division)]).map(([index, item]) => (
                                                <option
                                                    key={index}>{item}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="user-edit-input-container">
                                        <span className="user-edit-input-header">VKontakte</span>
                                        <input type="text" id="user-nickname" autoComplete={"off"} value={vk}
                                               onChange={(e) => setVK(e.target.value)}/>
                                    </div>

                                    <div className="user-edit-input-container">
                                        <span className="user-edit-input-header">Forum</span>
                                        <input type="text" id="user-nickname" autoComplete={"off"} value={forum}
                                               onChange={(e) => setForum(e.target.value)}/>
                                    </div>
                                </div>
                                {shouldDisplay(userData.userEditPerm === 1, userData.punishPerm === 1) && (
                                    <div className="left-panel">
                                        <div className="user-edit-input-container">
                                            <span className="user-edit-input-header">Выговоры</span>
                                            <input type="number" id="user-nickname" autoComplete={"off"} value={warns}
                                                   onChange={(e) => handleWarnChange(e)}/>
                                        </div>
                                        <div className="user-edit-input-container">
                                            <span className="user-edit-input-header">Баллы на снятие выговора</span>
                                            <input type="number" id="user-nickname" autoComplete={"off"} value={warnPoints}
                                                   onChange={(e) => handleWarnPoints(e)}/>
                                        </div>
                                        <div className="user-edit-input-container">
                                            <span className="user-edit-input-header">Workoff</span>
                                            <input type="number" id="user-nickname" autoComplete={"off"} value={workoff}
                                                   onChange={(e) => handleWorkoffChange(e)}/>
                                        </div>
                                        <div className="user-edit-input-container">
                                            <span className="user-edit-input-header">Баллы на снятие выговора</span>
                                            <input type="number" id="user-nickname" autoComplete={"off"} value={workoffPoints}
                                                   onChange={(e) => handleWorkoffPoints(e)}/>
                                        </div>
                                    </div>
                                )}
                                <div className="main-permissions-container">
                                    <div className="included-permissions">
                                        <div className="permissions-header">
                                            <span className="permissions-header-text">Разрешения предоставлены</span>
                                        </div>
                                        <div className="permissions-container">
                                            {allowedPermissions.map((value, index) => (
                                                <span className="permission"
                                                      onClick={() => handleChangePermission(value)}>{value}</span>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="not-included-permissions">
                                        <div className="permissions-header">
                                            <span className="permissions-header-text">Разрешения не предоставлены</span>
                                        </div>
                                        <div className="permissions-container">
                                            {notAllowedPermissions.map((value, index) => (
                                                <span className="permission"
                                                      onClick={() => handleChangePermission(value)}>{value}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button id="save-changes" onClick={saveChanges}><i class="bi bi-floppy-fill"></i> Сохранить изменения</button>
                                <button id="cancel-changes" onClick={cancelChanges}><i class="bi bi-floppy-fill"></i> Отменить изменения</button>
                                <button id="delete-user" onClick={deactivateUser}><i className="bi bi-trash-fill"></i> Отключить
                                    пользователя
                                </button>
                                {userData.admin > 0 && (
                                    <button id="cancel-user" style={{marginTop: '2%'}} onClick={() => setUserEditPopup(true)}><i
                                        className="bi bi-gear-fill"></i> Изменить данные пользователя
                                    </button>
                                )}
                            </div>
                            </>
                        )}
                        {selectedOption === 1 && (
                            <div className="table-container">
                                <>
                                    {shdDisplay(userData.admin > 0, userData.academyPerm > 0) && (
                                        <div className="not-done-tasks-container">
                                            <div className="table-label">
                                                <span className="table-label-text">Academy Assignments</span>
                                            </div>
                                            <div className="tasks-table-container">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>#ID</th>
                                                        <th>Название</th>
                                                        <th id="description">Описание</th>
                                                        <th>Статус</th>
                                                        <th>Баллы</th>
                                                        <th>Отправлено</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {currentItems.map((task, index) => (
                                                        <tr key={index} id="table-row" onClick={() => selectAcademyTask(task)}>
                                                            <th>{index + 1}</th>
                                                            <th>{task.name}</th>
                                                            <th id="description">{task.description}</th>
                                                            <th className={`status ${task.status === "not started" ? "not-started" : task.status === "on verification" ? "on-verify" : "done"}`}>
                                                                <div className="status-indicator"></div>
                                                                {task.status === "not started" ? "Не начато" : task.status === "on verification" ? "На проверке" : "Выполнено"}
                                                            </th>
                                                            <th>{task.status === "not started" ? "Не начато" : task.status === "on verification" ? "Ожидает проверки" : (task.details && task.details.points)}</th>
                                                            <th>{task.status === "not started" ? "Не начато" : task.status === "on verification" ? task.details.date : (task.details && task.details.verifiedAt)}</th>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="pagination">
                                                {currentPage > 1 && (
                                                    <i className="bi bi-arrow-left"
                                                       onClick={handleBackPage}></i>
                                                )}
                                                <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                                {totalPages > 0 && (
                                                    <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            </div>
                        )}
                        {selectedOption === 2 && (
                            <div className="table-container">
                                <>
                                    {shdDisplay(userData.admin > 0, userData.userEditPerm > 0) && (
                                        <div className="not-done-tasks-container">
                                            <div className="table-label">
                                                <span className="table-label-text">Punishment History</span>
                                            </div>
                                            <div className="tasks-table-container">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>#ID</th>
                                                        <th>Выдал</th>
                                                        <th>Дата выдачи</th>
                                                        <th>Причина</th>
                                                        <th>Тип</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {currentItems.map((task, index) => (
                                                        <tr key={index} id="table-row" onClick={() => selectAcademyTask(task)}>
                                                            <th>{index + 1}</th>
                                                            <th id="th-user-container">
                                                                <img src={getMemberByID(task.author).avatar}
                                                                     className="th-user-pfp"></img>
                                                                <span
                                                                    className="th-user-name">{getMemberByID(task.author).nickname}</span>
                                                            </th>
                                                            <th>{task.date}</th>
                                                            <th>{task.reason}</th>
                                                            <th>{task.type === "pfpdelete" ? "Удаление фотографии" : task.type === "pfpban" ? "Бан фото профиля" : task.type === "warn" ? "Выговор" : "Workoff"}</th>

                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="pagination">
                                                {currentPage > 1 && (
                                                    <i className="bi bi-arrow-left"
                                                       onClick={handleBackPage}></i>
                                                )}
                                                <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                                {totalPages > 0 && (
                                                    <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            </div>
                        )}
                        {selectedOption === 3 && (
                            <div className="table-container">
                                <>
                                    {shdDisplay(userData.admin > 0, userData.userEditPerm > 0) && (
                                        <div className="not-done-tasks-container">
                                            <div className="table-label">
                                                <span className="table-label-text">History of the removal of punishments</span>
                                            </div>
                                            <div className="tasks-table-container">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>#ID</th>
                                                        <th>Снял</th>
                                                        <th>Дата снятия</th>
                                                        <th>Причина</th>
                                                        <th>Тип</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {currentItems.map((task, index) => (
                                                        <tr key={index} id="table-row" onClick={() => selectAcademyTask(task)}>
                                                            <th>{index + 1}</th>
                                                            <th id="th-user-container">
                                                                <img src={getMemberByID(task.author).avatar} className="th-user-pfp"></img>
                                                                <span className="th-user-name">{getMemberByID(task.author).nickname}</span>
                                                            </th>
                                                            <th>{task.date}</th>
                                                            <th>{task.reason}</th>
                                                            <th>{task.type === "pfpdelete" ? "Удаление фотографии" : task.type === "pfpban" ? "Бан фото профиля" : task.type === "warn" ? "Выговор" : "Workoff"}</th>

                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="pagination">
                                                {currentPage > 1 && (
                                                    <i className="bi bi-arrow-left"
                                                       onClick={handleBackPage}></i>
                                                )}
                                                <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                                {totalPages > 0 && (
                                                    <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            </div>
                        )}
                        {selectedOption === 4 && (
                            <div className="table-container">
                                <>
                                    {shdDisplay(userData.admin > 0, userData.userEditPerm > 0) && (
                                        <div className="not-done-tasks-container">
                                            <div className="table-label">
                                                <span className="table-label-text">Personal Task History</span>
                                            </div>
                                            <div className="tasks-table-container">
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th>#ID</th>
                                                        <th>Название</th>
                                                        <th>Назначил</th>
                                                        <th>Причина</th>
                                                        <th>Выполнено</th>
                                                        <th>Редактировал</th>
                                                        <th>Дата редактирования</th>
                                                        <th>Назначено</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {currentItems.map((task, index) => (
                                                        <tr key={index} id="table-row" onClick={() => selectAcademyTask(task)}>
                                                            <th>{index + 1}</th>
                                                            <th>{task.name}</th>
                                                            <th id="th-user-container">
                                                                <img src={getMemberByID(task.createdBy).avatar}
                                                                     className="th-user-pfp"></img>
                                                                <span
                                                                    className="th-user-name">{getMemberByID(task.createdBy).nickname}</span>
                                                            </th>
                                                            <th>{task.reason}</th>
                                                            <th>{task.doneAt}</th>
                                                            <th>{task.createdAt}</th>
                                                            <th>{task.lastEditBy ? getMemberByID(task.lastEditBy).nickname : "Не редактировано"}</th>
                                                            <th>{task.lastEditAt ? task.lastEditAt : "Не редактировано"}</th>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="pagination">
                                                {currentPage > 1 && (
                                                    <i className="bi bi-arrow-left"
                                                       onClick={handleBackPage}></i>
                                                )}
                                                <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                                {totalPages > 0 && (
                                                    <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
}

export default UserEditor;
