import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function WarrantCreatePopup({backButton, handleCreateWarrant, isValidURL}) {

    const [targetName, setTargetName] = useState("");
    const [level, setLevel] = useState(1);
    const [reason, setReason] = useState("");
    const [warrantLink, setWarrantLink] = useState("");

    const [targetErr, setTargetErr] = useState("");
    const [reasonErr, setReasonErr] = useState("");
    const [warrantError, setWarrantError] = useState("");

    const handleLevel = (e) => {
        let raw_level = e.target.value;
        if(raw_level < 1){
            raw_level = 1;
        }else if(raw_level > 6){
            raw_level = 6;
        }
        setLevel(raw_level);
    }

    useEffect(() => {
        setTargetErr("");
        setReasonErr("");
        setWarrantError("");
    }, [targetName, reason, warrantLink]);

    const createWarrant = () => {
        let error = false;
        if(targetName.length < 4 || targetName.length > 24){
            error = true;
            setTargetErr("Недопустимый ник цели");
        }
        if(reason.length < 2 || reason.length > 24){
            error = true;
            setReasonErr("Причина должна быть от 2 до 24 символов.");
        }

        if(!isValidURL(warrantLink)){
            error = true;
            setWarrantError("Недопустимая ссылка на ордер.");
        }

        if(error) return;

        handleCreateWarrant({
            reason: reason,
            warrantLink: warrantLink,
            target: targetName,
            level: level
        });

    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Ордер</span>
                <div className="task-container">
                    <span className="point-header">Цель</span>
                    <input type="text" id="point-answer-input" className={targetErr.length > 0 && "invalid"} value={targetName} onChange={(e) => setTargetName(e.target.value)}/>
                    {targetErr.length > 0 && (
                        <span className="error-text">{targetErr}</span>
                    )}

                    <span className="point-header">Уровень розыска</span>
                    <input type="number" id="point-answer-input" value={level} onChange={(e) => handleLevel(e)}/>

                    <span className="point-header">Причина</span>
                    <input type="text" id="point-answer-input" className={reasonErr.length > 0 && "invalid"} value={reason} onChange={(e) => setReason(e.target.value)}/>
                    {reasonErr.length > 0 && (
                        <span className="error-text">{reasonErr}</span>
                    )}

                    <span className="point-header">Ссылка на ордер</span>
                    <input type="text" id="point-answer-input" className={warrantError.length > 0 && "invalid"} value={warrantLink} onChange={(e) => setWarrantLink(e.target.value)}/>
                    {warrantError.length > 0 && (
                        <span className="error-text">{warrantError}</span>
                    )}
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={createWarrant}><i
                        className="bi bi-send-fill"></i> Создать ордер
                    </button>
                </div>
            </div>
        </>
    );
}

export default WarrantCreatePopup;
