import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";
import AutocompleteInput from "../Utils/AutocompleteInput";

function DeletedMemberPreview({backButton, selectedTask, sendData, ranks, positions, divisions, getMemberByID, activateAccount}) {

    const [deleteUserInfo, setDeleteUserInfo] = useState({});

    useEffect(() => {
        const getUserDeleteInfo = async () => {
            const deleteInfo = await sendData("GET_USER_DEACTIVATED_INFO_BY_ID", "UserManager", {
                userID: selectedTask.ID,
                deactivateID: selectedTask.deactivateID
            });
            setDeleteUserInfo(deleteInfo.data);
        }

        getUserDeleteInfo();
    }, [selectedTask]);

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Удаленный пользователь</span>
                <div className="task-container">
                    <span className="point-header">Никнейм</span>
                    <span className="point-answer">{selectedTask.nickname}</span>

                    <span className="point-header">Ранг</span>
                    <span className="point-answer">({selectedTask.rank}) {ranks[selectedTask.rank]}</span>

                    <span className="point-header">Отдел</span>
                    <span className="point-answer">{divisions[selectedTask.division]}</span>

                    <span className="point-header">Должность</span>
                    <span className="point-answer">{positions[selectedTask.division][selectedTask.position]}</span>

                    <span className="point-header">Причина</span>
                    <span className="point-answer">{deleteUserInfo.reason}</span>

                    {deleteUserInfo.reasonText ? (
                    <>
                        <span className="point-header">Дополнительная информация</span>
                        <span className="point-answer">{deleteUserInfo.reasonText}</span>
                    </>
                    ) : (
                        <>
                            <span className="point-header">Дополнительная информация</span>
                            <span className="point-answer">Не указано</span>
                        </>
                    )}

                    <span className="point-header">Удален пользователем</span>
                    <span className="point-answer">{getMemberByID(deleteUserInfo.deletedBy).nickname}</span>

                </div>
                <div className="buttons-container">
                    <button id="send-task" style={{background: 'rgb(var(--green-color))', color: 'black'}} onClick={() => activateAccount({
                        userID: selectedTask.ID,
                        deactivateID: selectedTask.deactivateID,
                    })}><i className="bi bi-send-fill"></i> Включить аккаунт
                    </button>

                </div>
            </div>
        </>
    );
}

export default DeletedMemberPreview;
