import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function PreviewVerifyTask({backButton, sendData, selectedTask, userData, cancelVerifyTask}) {

    const [proofs, setProofs] = useState("");

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Задание на проверке</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <span className="point-answer">{selectedTask.name}</span>

                    <span className="point-header">Описание</span>
                    <span className="point-answer">{selectedTask.description}</span>

                    <span className="point-header">Максимальный балл</span>
                    <span className="point-answer">{selectedTask.maxPoints}</span>

                    <span className="point-header">Доказательства/примечания</span>
                    <textarea id="user-proofs" value={selectedTask.details.proofs} disabled={true}/>
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => cancelVerifyTask(selectedTask)}><i className="bi bi-eraser-fill"></i> Убрать задание
                        с проверки
                    </button>
                </div>
            </div>
        </>
    );
}

export default PreviewVerifyTask;
