import "../styles/tasks.css"
import {useEffect, useState} from "react";
import PointTaskEditPopup from "./PointTaskEditPopup";
import PointTaskCreatePopup from "./PointTaskCreatePopup";

function PointsAssignments({userData, updatePointAssignments, sendData, shouldDisplay, point_assignments}) {

    const [selectedCategory, setSelectedCategory] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTask, setSelectedTask] = useState({});
    const itemsPerPage = 12;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [showTaskEditPopup, setShowTaskEditPopup] = useState(false);
    const [createTaskPopup, setCreateTaskPopup] = useState(false);


    useEffect(() => {
        const filteredTasks = point_assignments.filter(task => task.type === (selectedCategory === 0 ? "warn" : "workoff"));


        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(filteredTasks.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(filteredTasks.length / itemsPerPage));
    }, [currentPage, point_assignments, selectedCategory]);

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    const handleSelectEditAssignment = (task) => {
        setSelectedTask(task);
        setShowTaskEditPopup(true);
    }

    const closePopups = () => {
        setShowTaskEditPopup(false);
        setCreateTaskPopup(false);
    }

    const saveTaskChanges = async (data) => {
        const response = await sendData("UPDATE_POINT_TASK", "UserManager", data);
        if(response.response_code === 200){
            updatePointAssignments(data, "edit");
            closePopups();
        }
    }

    const handleDeleteTask = async (data) => {
        const response = await sendData("DELETE_POINT_TASK", "UserManager", data);
        if(response.response_code === 200){
            updatePointAssignments(data.taskID, "delete");
            closePopups();
        }
    }

    const handleCreateNewTask = async (data) => {
        const response = await sendData("CREATE_POINT_TASK", "UserManager", data);
        if(response.response_code === 200){
            data.ID = response.lastID;
            updatePointAssignments(data, "add");
            closePopups();
        }
    }

    return (
        <>
            {showTaskEditPopup && (
                <PointTaskEditPopup selectedTask={selectedTask} backButton={closePopups} saveChanges={saveTaskChanges} deleteStage={handleDeleteTask}/>
            )}
            {createTaskPopup && (
                <PointTaskCreatePopup saveChanges={handleCreateNewTask} backButton={closePopups}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i className="bi bi-piggy-bank-fill"></i> Задания на баллы</span>
                    <div className="task-category-list">
                        {shouldDisplay(userData.admin > 0, userData.academyPerm > 0) && (
                            <div className={`category-option ${selectedCategory === 0 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(0)}>Выговоры
                            </div>
                        )}
                        {shouldDisplay(userData.admin > 0, userData.divisionCheck > 0) && (
                            <div className={`category-option ${selectedCategory === 1 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(1)}>Workoff
                            </div>
                        )}
                    </div>
                    <button id="new-task" onClick={() => setCreateTaskPopup(true)}><i
                        className="bi bi-pen-fill"></i> Создать новое задание
                    </button>
                    <div className="not-done-tasks-container">
                        <div className="table-label">
                            <span className="table-label-text">Warn Assignments</span>
                        </div>
                        <div className="tasks-table-container">
                            <table>
                                <thead>
                                <tr>
                                    <th>#ID</th>
                                    <th>Название</th>
                                    <th>Описание</th>
                                    <th>Количество баллов</th>
                                </tr>
                                </thead>
                                <tbody>
                                {currentItems.map((task, index) => (
                                    <tr key={index} id="table-row" onClick={() => handleSelectEditAssignment(task)}>
                                        <th>{index + 1}</th>
                                        <th>{task.name}</th>
                                        <th>{task.description}</th>
                                        <th>{task.points}</th>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination">
                            {currentPage > 1 && (
                                <i className="bi bi-arrow-left"
                                   onClick={handleBackPage}></i>
                            )}
                            <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                            {totalPages > 0 && (
                                <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PointsAssignments;
