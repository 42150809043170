import "../styles/tasks.css"
import {useEffect, useState} from "react";
import SendVerifyTask from "./SendVerifyTask";
import PreviewVerifyTask from "./PreviewVerifyTask";
import PreviewDoneTask from "./PreviewDoneTask";
import PreviewCanceledTask from "./PreviewCanceledTask";
import PreviewDivisionTaskPopup from "../DivisionTasks/PreviewDivisionTaskPopup";
import PreviewVerifyDivisionTask from "../DivisionTasks/PreviewVerifyDivisionTask";
import SendPersonalTask from "./SendPersonalTask";
import PreviewVerifyPersonalTask from "./PreviewVerifyPersonalTask";
import SendPointTask from "./SendPointTask";

function Tasks({userData, shouldDisplay, sendData, openLink, academyTasks, point_assignments, verifyTasks, declinedTasks, updatePersonalTasks, personalTasks, doneTasks, members, divisionTasks, updateDivisionTasks}) {

    const [selectedCategory, setSelectedCategory] = useState(userData.division === 0 ? 0 : 1);
    const [academySubCategory, setAcademySubCategory] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tasks, setTasks] = useState([]);
    const itemsPerPage = 12;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedTasks, setSelectedTasks] = useState({});
    const [sendVerifyPopup, setSendVerifyPopup] = useState(false);
    const [previewTaskPopup, setPreviewTaskPopup] = useState(false);
    const [previewDoneTaskPopup, setPreviewDoneTaskPopup] = useState(false);
    const [canceledTaskPreviewPopup, setCanceledTaskPreviewPopup] = useState(false);
    const [onVerifyDivisionTaskPreview, setOnVerifyDivisionTaskPreview] = useState(false);

    const [previewPersonalTask, setPreviewPersonalTask] = useState(false);
    const [previewVerifyPersonalTask, setPreviewVerifyPersonalTask] = useState(false);

    const [pointTaskPopup, setPointTaskPopup] = useState(false);

    const [previewDivisionTaskPopup, setPreviewDivisionTaskPopup] = useState(false);

    useEffect(() => {
        const updateTasks = () => {
            const newTasks = academyTasks.map(task => {
                let status = "not started";
                let details = {};

                if (verifyTasks.some(t => t.task_id === task.ID)) {
                    status = "on verification";
                    details = verifyTasks.find(t => t.task_id === task.ID);
                } else if (doneTasks.some(t => t.task_id === task.ID)) {
                    status = "completed";
                    details = doneTasks.find(t => t.task_id === task.ID);
                } else if (declinedTasks.some(t => t.task_id === task.ID)) {
                    status = "declined";
                    details = declinedTasks.find(t => t.task_id === task.ID);
                }

                return { ...task, status, details };
            });
            setTasks(newTasks);
        };
        updateTasks();
    }, [academyTasks, verifyTasks, doneTasks, declinedTasks]);

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }
    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' };
    }

    const getSelected = () => {
        return selectedCategory === 0 ? tasks : selectedCategory === 1 ? divisionTasks : selectedCategory === 2 ? personalTasks
            : selectedCategory === 3 ? point_assignments.filter(task => task.type === "warn") : point_assignments.filter(task => task.type === "workoff");
    }

    const handleSelectPersonalTask = (task) => {
        setSelectedTasks(task);
        if(task.status === 0) setPreviewPersonalTask(true);
        if(task.status === 1) setPreviewVerifyPersonalTask(true);
    }

    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(getSelected().slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(getSelected().length / itemsPerPage));
    }, [currentPage, tasks, divisionTasks, selectedCategory, personalTasks]);

    const closePopups = () => {
        setSendVerifyPopup(false);
        setPreviewTaskPopup(false);
        setPreviewDoneTaskPopup(false);
        setCanceledTaskPreviewPopup(false);
        setPreviewDivisionTaskPopup(false);
        setOnVerifyDivisionTaskPreview(false);
        setPreviewPersonalTask(false);
        setPointTaskPopup(false);
        setPreviewVerifyPersonalTask(false);
    }

    const handleTaskSelect = (task) => {
        setSelectedTasks(task);
        setSendVerifyPopup(true);
    }

    const handleDivisionTaskPreview = (task) => {
        if(task.doneBy === null) {
            setSelectedTasks(task);
            setPreviewDivisionTaskPopup(true);
        }else {
            if(task.doneBy === userData.ID || userData.admin > 0){
                setSelectedTasks(task);
                setOnVerifyDivisionTaskPreview(true);
            }
        }
    }

    const handleDoneTaskPreview = (task) => {
        setSelectedTasks(task);
        setPreviewDoneTaskPopup(true);
    }

    const handleTaskPreview = (task) => {
        setSelectedTasks(task);
        setPreviewTaskPopup(true);
    }

    const handleCanceledTask = (task) => {
        setSelectedTasks(task);
        setCanceledTaskPreviewPopup(true);
    }

    const cancelVerifyTask = async (selectedTask) => {
        const response = await sendData("CANCEL_MY_VERIFY_TASK", "UserManager", {
            taskID: selectedTask.ID
        });

        if(response.response_code === 200){
            const updatedTasks = tasks.map(task => {
                if (task.ID === selectedTask.ID) {
                    return { ...task, status: "not started", details: {} };
                }
                return task;
            });
            setTasks(updatedTasks);
            closePopups();
        }
    }

    const selectPointTask = (task) => {
        setSelectedTasks(task);
        setPointTaskPopup(true);
    }

    const sendDivisionTaskOnVerify = async (data) => {
        const response = await sendData("SEND_DIVISION_TASK_ON_VERIFY", "UserManager", data);
        if(response.response_code === 200){
            data.doneBy = userData.ID;
            data.doneAt = response.doneAt;
            updateDivisionTasks(data, "edit");
            closePopups();
        }
    }

    const removeDivisionTaskFromVerify = async (data) => {
        const response = await sendData("REMOVE_DIVISION_TASK_FROM_VERIFY", "UserManager", data);
        if(response.response_code === 200){
            data.doneBy = null;
            data.doneAt = null;
            updateDivisionTasks(data, "edit");
            closePopups();
        }
    }

    const sendVerifyTask = async (selectedTask, proofs) => {
        const response = await sendData("SEND_VERIFY_TASK", "UserManager", {
            taskID: selectedTask.ID,
            name: selectedTask.name,
            description: selectedTask.description,
            maxPoints: selectedTask.maxPoints,
            proofs: proofs
        });

        if(response.response_code === 200){
            const newDetails = {
                ID: response.lastID,
                proofs: proofs,
                name: selectedTask.name,
                description: selectedTask.description,
                maxPoints: selectedTask.maxPoints,
                task_id: selectedTask.ID
            };
            const updatedTasks = tasks.map(task => {
                if (task.ID === selectedTask.ID) {
                    return { ...task, status: "on verification", details: newDetails };
                }
                return task;
            });
            setTasks(updatedTasks);
            closePopups();
        }
    }

    const sendVerifyPersonalTask = async (data) => {
        const response = await sendData("SEND_PERSONAL_TASK_ON_VERIFY", "UserManager", data);
        if(response.response_code === 200){
            updatePersonalTasks({taskID: data.taskID, status: 1, proofs: data.proofs}, "edit");
            closePopups();
        }
    }

    const cancelVerifyPersonalTask = async (data) => {
        const response = await sendData("CANCEL_MY_VERIFY_PERSONAL_TASK", "UserManager", data);
        if(response.response_code === 200){
            updatePersonalTasks({taskID: data.taskID, status: 0}, "edit");
            closePopups();
        }
    }

    const sendPointTaskVerify = async (data) => {
        const response = await sendData("SEND_VERIFY_POINTS_TASK", "UserManager", data);
        if(response.response_code === 200){
            closePopups();
        }
    }

    return (
        <>
            {sendVerifyPopup && (
                <SendVerifyTask sendData={sendData} userData={userData} openLink={openLink} backButton={closePopups} selectedTask={selectedTasks} sendVerifyTask={sendVerifyTask} />
            )}
            {previewTaskPopup && (
                <PreviewVerifyTask selectedTask={selectedTasks} backButton={closePopups} userData={userData} sendData={sendData} cancelVerifyTask={cancelVerifyTask} />
            )}
            {previewDoneTaskPopup && (
                <PreviewDoneTask selectedTask={selectedTasks} backButton={closePopups} getMemberByID={getMemberByID}/>
            )}
            {canceledTaskPreviewPopup && (
                <PreviewCanceledTask selectedTask={selectedTasks} backButton={closePopups} getMemberByID={getMemberByID}/>
            )}
            {previewDivisionTaskPopup && (
                <PreviewDivisionTaskPopup selectedTask={selectedTasks} backButton={closePopups} sendDivisionTaskOnVerify={sendDivisionTaskOnVerify}/>
            )}
            {onVerifyDivisionTaskPreview && (
                <PreviewVerifyDivisionTask selectedTask={selectedTasks} backButton={closePopups} getMemberByID={getMemberByID} removeDivisionTaskFromVerify={removeDivisionTaskFromVerify}/>
            )}
            {previewPersonalTask && (
                <SendPersonalTask selectedTask={selectedTasks} backButton={closePopups} sendVerifyTask={sendVerifyPersonalTask} getMemberByID={getMemberByID}/>
            )}
            {previewVerifyPersonalTask && (
                <PreviewVerifyPersonalTask selectedTask={selectedTasks} backButton={closePopups} getMemberByID={getMemberByID} cancelVerifyPersonalTask={cancelVerifyPersonalTask}/>
            )}
            {pointTaskPopup && (
                <SendPointTask selectedTask={selectedTasks} backButton={closePopups} sendVerifyTask={sendPointTaskVerify}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i className="bi bi-briefcase-fill"></i> Задания</span>
                    <div className="task-category-list">
                        {shouldDisplay(userData.admin > 0, userData.division === 0) && (
                            <div className={`category-option ${selectedCategory === 0 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(0)}>Академия
                            </div>
                        )}
                        {shouldDisplay(userData.admin > 0, userData.divisionCheck > 0, userData.allDivisionCheckPerm > 0) && (
                            <div className={`category-option ${selectedCategory === 1 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(1)}>Отдел
                            </div>
                        )}
                        {shouldDisplay(userData.admin > 0, userData.academyPerm > 0, userData.editTasksPerm > 0) && (
                            <div className={`category-option ${selectedCategory === 2 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(2)}>Персональные
                            </div>
                        )}
                        <div className={`category-option ${selectedCategory === 3 ? "selected" : ""}`}
                             onClick={() => setSelectedCategory(3)}>Выговоры
                        </div>
                        <div className={`category-option ${selectedCategory === 4 ? "selected" : ""}`}
                             onClick={() => setSelectedCategory(4)}>Workoff
                        </div>
                    </div>
                    {selectedCategory === 0 && (
                        <div className="task-category-list">
                            <div className={`category-option ${academySubCategory === 0 ? "selected" : ""}`}
                                 onClick={() => setAcademySubCategory(0)}>Не начато
                            </div>
                            <div className={`category-option ${academySubCategory === 1 ? "selected" : ""}`}
                                 onClick={() => setAcademySubCategory(1)}>На проверке
                            </div>
                            <div className={`category-option ${academySubCategory === 2 ? "selected" : ""}`}
                                 onClick={() => setAcademySubCategory(2)}>Выполнено
                            </div>
                            <div className={`category-option ${academySubCategory === 3 ? "selected" : ""}`}
                                 onClick={() => setAcademySubCategory(3)}>Отклонено
                            </div>
                        </div>
                    )}
                    {selectedCategory === 0 && (
                        <>
                            {academySubCategory === 0 && (
                                <>
                                    <div className="not-done-tasks-container">
                                        <div className="table-label">
                                            <span className="table-label-text">Not Started Academy Tasks</span>
                                        </div>
                                        <div className="tasks-table-container">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>#ID</th>
                                                    <th>Название</th>
                                                    <th>Описание</th>
                                                    <th>Максимальный балл</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {currentItems.map((task, index) => (
                                                <>
                                                    {(task.status === "not started" || task.status === "declined") && (
                                                        <tr key={index} id="table-row" onClick={() => handleTaskSelect(task)}>
                                                            <th>{index + 1}</th>
                                                            <th>{task.name}</th>
                                                            <th id="description">{task.description}</th>
                                                            <th>{task.maxPoints}</th>
                                                        </tr>
                                                    )}
                                                </>
                                            ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <div className="pagination">
                                            {currentPage > 1 && (
                                                <i className="bi bi-arrow-left"
                                                   onClick={handleBackPage}></i>
                                            )}
                                            <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                            {totalPages > 0 && (
                                                <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {academySubCategory === 1 && (
                                <>
                                    <div className="not-done-tasks-container">
                                        <div className="table-label">
                                            <span className="table-label-text">On Verification</span>
                                        </div>
                                        <div className="tasks-table-container">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>#ID</th>
                                                <th>Название</th>
                                                <th>Описание</th>
                                                <th>Максимальный балл</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {currentItems.map((task, index) => (
                                                <>
                                                    {task.status === "on verification" && (
                                                        <tr key={index} id="table-row" onClick={() => handleTaskPreview(task)}>
                                                            <th>{index + 1}</th>
                                                            <th>{task.name}</th>
                                                            <th id="description">{task.description}</th>
                                                            <th>{task.maxPoints}</th>
                                                        </tr>
                                                    )}
                                                </>
                                            ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <div className="pagination">
                                            {currentPage > 1 && (
                                                <i className="bi bi-arrow-left"
                                                   onClick={handleBackPage}></i>
                                            )}
                                            <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                            {totalPages > 0 && (
                                                <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {academySubCategory === 2 && (
                                <>
                                    <div className="not-done-tasks-container">
                                        <div className="table-label">
                                            <span className="table-label-text">Done Tasks</span>
                                        </div>
                                        <div className="tasks-table-container">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>#ID</th>
                                                <th>Название</th>
                                                <th>Описание</th>
                                                <th>Оценка</th>
                                                <th>Проверено пользователем</th>
                                                <th>Проверено</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {currentItems.map((task, index) => (
                                                <>
                                                    {task.status === "completed" && (
                                                        <tr key={index} id="table-row" onClick={() => handleDoneTaskPreview(task)}>
                                                            <th>{index + 1}</th>
                                                            <th>{task.name}</th>
                                                            <th id="description">{task.description}</th>
                                                            <th>{task.details.points}/{task.maxPoints}</th>
                                                            <th>{getMemberByID(task.details.verifiedBy).nickname}</th>
                                                            <th>{task.details.verifiedAt}</th>
                                                        </tr>
                                                    )}
                                                </>
                                            ))}
                                            </tbody>
                                        </table>
                                        </div>
                                        <div className="pagination">
                                            {currentPage > 1 && (
                                                <i className="bi bi-arrow-left"
                                                   onClick={handleBackPage}></i>
                                            )}
                                            <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                            {totalPages > 0 && (
                                                <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {academySubCategory === 3 && (
                                <>
                                    <div className="not-done-tasks-container">
                                        <div className="table-label">
                                            <span className="table-label-text">Declined Tasks</span>
                                        </div>
                                        <div className="tasks-table-container">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>#ID</th>
                                                    <th>Название</th>
                                                    <th>Описание</th>
                                                    <th>Максимальный балл</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {currentItems.map((task, index) => (
                                                    <>
                                                        {task.status === "declined" && (
                                                            <tr key={index} id="table-row" onClick={() => handleCanceledTask(task)}>
                                                                <th>{index + 1}</th>
                                                                <th>{task.name}</th>
                                                                <th id="description">{task.description}</th>
                                                                <th>{task.maxPoints}</th>
                                                            </tr>
                                                        )}
                                                    </>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pagination">
                                            {currentPage > 1 && (
                                                <i className="bi bi-arrow-left"
                                                   onClick={handleBackPage}></i>
                                            )}
                                            <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                            {totalPages > 0 && (
                                                <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {selectedCategory === 1 && (
                        <div className="not-done-tasks-container">
                            <div className="table-label">
                                <span className="table-label-text">Division Tasks</span>
                            </div>
                            <div className="tasks-table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#ID</th>
                                        <th>Название</th>
                                        <th>Описание</th>
                                        <th>Статус</th>
                                        <th>Выполнено пользователем</th>
                                        <th>Выполнено</th>
                                        <th>Проверено пользователем</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentItems.map((task, index) => (
                                        <>
                                            <tr key={index} id="table-row"
                                                onClick={() => handleDivisionTaskPreview(task)}>
                                                <th>{index + 1}</th>
                                                <th>{task.name}</th>
                                                <th id="description">{task.description}</th>
                                                <th className={`status ${task.status === 0 ? "not-started" : task.status === 1 ? "on-verify" : "done"}`}>
                                                    <div className="status-indicator"></div>
                                                    {task.status === 0 ? "Не начато" : task.status === 1 ? "На проверке" : "Выполнено"}
                                                </th>
                                                <th>{task.doneBy !== null ? getMemberByID(task.doneBy).nickname : "Отсутствует"}</th>
                                                <th>{task.doneAt ? task.doneAt : "Не выполнено"}</th>
                                                <th>{task.verifier ? getMemberByID(task.verifier).nickname : "Не выполнено"}</th>
                                            </tr>
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                {currentPage > 1 && (
                                    <i className="bi bi-arrow-left"
                                       onClick={handleBackPage}></i>
                                )}
                                <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                {totalPages > 0 && (
                                    <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                )}
                            </div>
                        </div>
                    )}
                    {selectedCategory === 2 && (
                        <div className="not-done-tasks-container">
                            <div className="table-label">
                                <span className="table-label-text">Personal Tasks</span>
                            </div>
                            <div className="tasks-table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#ID</th>
                                        <th>Название</th>
                                        <th>Описание</th>
                                        <th>Назначил</th>
                                        <th>Причина</th>
                                        <th>Статус</th>
                                        <th>Создано</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentItems.map((task, index) => (
                                        <>
                                            <tr key={index} id="table-row"
                                                onClick={() => handleSelectPersonalTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{task.name}</th>
                                                <th id="description">{task.description}</th>
                                                <th>{getMemberByID(task.createdBy).nickname}</th>
                                                <th>{task.reason}</th>
                                                <th className={`status ${task.status === 0 ? "not-started" : task.status === 1 ? "on-verify" : "done"}`}>
                                                    <div className="status-indicator"></div>
                                                    {task.status === 0 ? "Не начато" : task.status === 1 ? "На проверке" : "Выполнено"}
                                                </th>
                                                <th>{task.createdAt}</th>
                                            </tr>
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                {currentPage > 1 && (
                                    <i className="bi bi-arrow-left"
                                       onClick={handleBackPage}></i>
                                )}
                                <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                {totalPages > 0 && (
                                    <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                )}
                            </div>
                        </div>
                    )}
                    {shouldDisplay(selectedCategory > 2 && selectedCategory < 5) && (
                        <div className="not-done-tasks-container">
                            <div className="table-label">
                                <span className="table-label-text">{selectedCategory === 3 ? "Warn" : "Workoff"} Tasks</span>
                            </div>
                            <div className="tasks-table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#ID</th>
                                        <th>Название</th>
                                        <th>Описание</th>
                                        <th>Баллы</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentItems.map((task, index) => (
                                        <>
                                            <tr key={index} id="table-row"
                                                onClick={() => selectPointTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{task.name}</th>
                                                <th id="description">{task.description}</th>
                                                <th>{task.points}</th>
                                            </tr>
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                {currentPage > 1 && (
                                    <i className="bi bi-arrow-left"
                                       onClick={handleBackPage}></i>
                                )}
                                <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                {totalPages > 0 && (
                                    <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Tasks;
