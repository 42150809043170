import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function RegCheck({backButton, selectedTask, members, checkRequest}) {

    const options = {
        0: "Одобрено",
        1: "Отказано"
    };
    const [status, setStatus] = useState("Одобрено");
    const [academyExtension, setAcademyExtension] = useState(false);

    const [vk, setVK] = useState(selectedTask.vk);
    const [forum, setForum] = useState(selectedTask.forum);

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Запрос на регистрацию</span>
                <div className="task-container">
                    <span className="point-header">Отправитель</span>
                    <span className="point-answer">{selectedTask.nickname}</span>

                    <span className="point-header">Дата подачи</span>
                    <span className="point-answer">{selectedTask.createdAt}</span>

                    <span className="point-header">VKontakte</span>
                    <input type="text" id="point-answer-input" value={vk} autoComplete={"off"}
                           onChange={(e) => setVK(e.target.value)}/>

                    <span className="point-header">Forum</span>
                    <input type="text" id="point-answer-input" value={forum} autoComplete={"off"}
                           onChange={(e) => setForum(e.target.value)}/>

                    <span className="point-header">Статус</span>
                    <select name="divisions" id="divisions" value={status}
                            onChange={(e) => setStatus(e.target.value)}>
                        {Object.entries(options).map(([index, option]) => (
                            <option
                                key={index}>{option}</option>
                        ))}
                    </select>
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => checkRequest({
                        ID: selectedTask.ID,
                        status: status === "Одобрено" ? 1 : 0,
                        vk: vk,
                        forum: forum
                    })}><i className="bi bi-send-fill"></i> Отправить
                    </button>
                </div>
            </div>
        </>
    );
}

export default RegCheck;
