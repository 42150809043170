import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function RequestInactive({backButton, createInactiveRequest}) {

    const [reason, setReason] = useState("");
    const [reasonErr, setReasonErr] = useState("");

    const [inactiveFrom, setInactiveFrom] = useState("");
    const [inactiveTo, setInactiveTo] = useState("");

    const handleReason = (e) => {
        setReason(e.target.value);
        setReasonErr("");
    }

    return (
        <>
            <div className="darker"></div>
            <div id="pfp-ban-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="pfp-ban-popup-header">Запрос неактива</span>
                <div className="main-container">
                    <div className="user-edit-input-container">
                        <span className={`user-edit-input-header`}>Неактив от</span>
                        <input type="date" id="user-nickname" className={reasonErr.length > 0 ? "invalid" : ""}
                               autoComplete={"off"} value={inactiveFrom}
                               onChange={(e) => setInactiveFrom(e.target.value)}/>

                        <span className={`user-edit-input-header`}>Неактив до</span>
                        <input type="date" id="user-nickname" className={reasonErr.length > 0 ? "invalid" : ""}
                               autoComplete={"off"} value={inactiveTo}
                               onChange={(e) => setInactiveTo(e.target.value)}/>

                        <span className={`user-edit-input-header`}>Причина</span>
                        <input type="text" id="user-nickname" className={reasonErr.length > 0 ? "invalid" : ""}
                               autoComplete={"off"} value={reason}
                               onChange={(e) => handleReason(e)}/>
                        {reasonErr.length > 0 && (
                            <span className="error-text">{reasonErr}</span>
                        )}
                    </div>

                    <button id="ban-pfp" onClick={() => createInactiveRequest({
                        dateFrom: inactiveFrom,
                        dateTo: inactiveTo,
                        reason: reason
                    })}><i className="bi bi-send-fill"></i> Запросить</button>
                </div>
            </div>
        </>
    );
}

export default RequestInactive;
