import "../styles/tasks.css"
import '../styles/devSupport.css'
import {useEffect, useRef, useState} from "react";
import Pusher from "pusher-js";
import InviteUser from "./InviteUser";
import ContextMenu from "../Utils/ContextMenu";
import contextMenu from "../Utils/ContextMenu";
import BanUserSupport from "./BanUserSupport";

function OpenedChat({userData, getMemberByID, sendData, members, isValidURL, shouldDisplay, closeChat, openLink, selectedChat, updateChat}) {

    const [message, setMessage] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const [invitePopup, setInvitePopup] = useState(false);
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [contextMenu, setContextMenu] = useState(false);
    const [contextData, setContextData] = useState({});
    const [selectedUser, setSelectedUser] = useState({});

    const [banUserPopup, setBanUserPopup] = useState(false);

    useEffect(() => {
        const getChatMessages = async () => {
            const data = await sendData("GET_CHAT_MESSAGES", "ChatManager", {chatUID: selectedChat.chatUID});
            setChatMessages(data.data);

            const inUsers = await sendData("GET_INVITED_USERS", "ChatManager", {chatUID: selectedChat.chatUID});
            setInvitedUsers(inUsers.data);
        }

        getChatMessages();
    }, []);

    const updateInvitedUsers = (data, mode) => {
        if(mode === "edit") {
            setInvitedUsers(invitedUsers => {
                const index = invitedUsers.findIndex(task => task.ID === data.ID);
                if (index !== -1) {
                    return [
                        ...invitedUsers.slice(0, index),
                        {...invitedUsers[index], ...data},
                        ...invitedUsers.slice(index + 1)
                    ];
                }
                return invitedUsers;
            });
        }else if(mode === "add"){
            setInvitedUsers(previousTasks => [data, ...previousTasks]);
        }else if(mode === "delete"){
            setInvitedUsers(invitedUsers.filter(task => task.userID !== data));
        }
    }


    useEffect(() => {
        const pusher = new Pusher('25d07c04ea3dc0827f2c', {
            cluster: 'eu',
            encrypted: true
        });

        const channel = pusher.subscribe('chat-' + selectedChat.chatUID);
        channel.bind('got-message', function(data) {
            if(data.action !== undefined){
                switch (data.action){
                    case 0:
                    {
                        updateChat({ID: selectedChat.ID, status: 1}, "edit");
                        selectedChat.status = 1;
                        break;
                    }
                    case 1:
                    {
                        updateChat({ID: selectedChat.ID, saved: 1}, "edit");
                        selectedChat.saved = 1;
                        break;
                    }
                    case 2:
                    {
                        updateChat({ID: selectedChat.ID, saved: 0}, "edit");
                        selectedChat.saved = 0;
                        break;
                    }
                    case 3:
                    {
                        updateChat(selectedChat.ID, "delete");
                        closeChat();
                        break;
                    }
                    case 4:
                    {
                        updateInvitedUsers({chatUID: data.chatUID, userID: data.newUserID}, "add");
                        break;
                    }
                    case 5:
                    {
                        updateInvitedUsers(data.target, "delete");
                        if(userData.ID === data.target){
                            window.location.reload();
                        }
                        break;
                    }
                    default: break;
                }
            }
            setChatMessages(prev => prev.concat(data));
        });

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, []);


    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [chatMessages]);


    const handleMessage = async () => {
        let formattedMessage = message.trim();
        if (!formattedMessage) return;

        const urls = extractUrls(formattedMessage);
        urls.forEach(url => {
            if (isImageUrl(url)) {
                const imgTag = `<img src="${url}" alt="Loaded Image" style="max-width: 100%; height: auto;">`;
                formattedMessage = formattedMessage.replace(url, imgTag);
            } else {
                const linkTag = `<a href="${url}" target="_blank">${url}</a>`;
                formattedMessage = formattedMessage.replace(url, linkTag);
            }
        });

        formattedMessage = formattedMessage.replace(/\n/g, '<br>');
        await sendData("MESSAGE_SENT", "ChatManager", {chatUID: selectedChat.chatUID, message: formattedMessage, sender: userData.ID, type: 'user_message' });
        setMessage('');
    };


    function isImageUrl(url) {
        return /\.(jpeg|jpg|gif|png|svg|blob)$/i.test(url);
    }

    function extractUrls(text) {
        const urlRegex = /https?:\/\/\S+/g;
        const urls = text.match(urlRegex);
        return urls || [];
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (message.trim()) {
                handleMessage();
                setMessage('');
            }
        }
    };

    const closeTicket = async () => {
        const response = await sendData("CLOSE_TICKET", "ChatManager", {chatUID: selectedChat.chatUID});
        if(response.response_code === 200){
            selectedChat.status = 1;
        }
    }

    const saveTicket = async () => {
        await sendData("SAVE_TICKET", "ChatManager", {chatUID: selectedChat.chatUID});
    }

    const unsaveTicket = async () => {
        await sendData("UNSAVE_TICKET", "ChatManager", {chatUID: selectedChat.chatUID});
    }

    const deleteTicket = async () => {
        await sendData("DELETE_TICKET", "ChatManager", {chatUID: selectedChat.chatUID});
    }

    const closePopups = () => {
        setInvitePopup(false);
        setBanUserPopup(false);
    }

    const inviteUser = async (data) => {
        const response = await sendData("INVITE_USER_TO_TICKET", "ChatManager", {chatUID: selectedChat.chatUID, userID: data.userID});
        if(response.response_code === 200){
            closePopups();
        }
    }

    const handleContextMenu = (event, user) => {
        event.preventDefault();
        setSelectedUser(user);
        setContextData({
            xPos: event.pageX,
            yPos: event.pageY
        })
        setContextMenu(true);
    };

    const removeUserFromTicket = async () => {
        await sendData("REMOVE_USER_FROM_TICKET", "ChatManager", {chatUID: selectedChat.chatUID, userID: selectedUser.userID})
    }

    const banUserSupport = async (data) => {
        await sendData("BAN_USER_SUPPORT", "ChatManager", {chatUID: selectedChat.chatUID, userID: selectedUser.userID, date: data.date})
    }



    const menuItems = [
        { text: <span><i className="delete bi bi-trash-fill"></i>Удалить пользователя</span>, onClick: () => removeUserFromTicket() },
        { text: <span><i className="delete bi bi-volume-mute-fill"></i>Заблокировать связь</span>, onClick: () => setBanUserPopup(true) },
    ];


    const handleClick = (event) => {
        if (contextMenu) {
            setContextMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [contextMenu]);

    return (
        <>
            {invitePopup && (
                <InviteUser selectedChat={selectedChat} invite={inviteUser} backButton={closePopups} members={members}/>
            )}
            {contextMenu && (
                <ContextMenu yPos={contextData.yPos} xPos={contextData.xPos} menuItems={menuItems}/>
            )}
            {banUserPopup && (
                <BanUserSupport selectedChat={selectedChat} backButton={closePopups} banUser={banUserSupport}/>
            )}
        <div id="dev-main-container">
            <div className="dev-chat-container">
                <div className="dev-chat-header">
                    <span className="dev-chat-header-text">{selectedChat.header}</span>
                    <div className="right-part">
                        <span className="dev-chat-ticket-data">Тикет [{selectedChat.chatUID}] {selectedChat.topic}</span>
                        <span className="close-chat" onClick={() => closeChat()}><i class="bi bi-x-lg"></i></span>
                    </div>
                </div>
                <div className="chat-container">
                    <div className="right-dev-chat-menu">
                        <div className="chat-members-container">
                            <span className="part-header">Участники чата</span>
                            {members.filter(member => member.admin === 2).map((member, index) => (
                                <>
                                    <div className="chat-user-container">
                                        <img src={member.avatar} className="chat-user-pfp"></img>
                                        <span className="chat-user-name">{member.nickname}</span>
                                        <span className="chat-user-rank">Developer</span>
                                    </div>
                                </>
                            ))}
                            <div className="chat-user-container">
                                <img src={getMemberByID(selectedChat.initiator).avatar} className="chat-user-pfp"></img>
                                <span className="chat-user-name">{getMemberByID(selectedChat.initiator).nickname}</span>
                                <span className="chat-user-rank">Инициатор</span>
                            </div>
                            {invitedUsers.map((user, index) => (
                                <>
                                    <div className="chat-user-container" onContextMenu={(e) => handleContextMenu(e, user)}>
                                        <img src={getMemberByID(user.userID).avatar} className="chat-user-pfp"></img>
                                        <span className="chat-user-name">{getMemberByID(user.userID).nickname}</span>
                                        <span className="chat-user-rank">Приглашенный</span>
                                    </div>
                                </>
                            ))}
                        </div>
                        {shouldDisplay(userData.admin === 2) && (
                            <div className="actions-container">
                                {selectedChat.status === 0 && (
                                    <button id="close-chat" onClick={() => closeTicket()}>Закрыть тикет</button>
                                )}
                                <button id="invite-chat" onClick={() => setInvitePopup(true)}>Пригласить пользователя</button>
                                {selectedChat.saved === 0 ? (
                                    <button id="save-chat" onClick={() => saveTicket()}>Сохранить</button>
                                ) : (
                                    <button id="save-chat" onClick={() => unsaveTicket()}>Убрать из сохраненных</button>
                                )}
                                <button id="delete-chat" onClick={() => deleteTicket()}>Удалить</button>
                            </div>
                        )}
                    </div>
                    <div className="dev-chat">
                        <div className="messages-container">
                            <div className="messages-list">
                                {chatMessages.map((message, index) => (
                                    <>
                                        {message.type === "user_message" ? (
                                            <>
                                            {message.sender === userData.ID ? (
                                                <div id="my-message-container">
                                                    <div className="message-content">
                                                        <div className="message-header">
                                                            <span className="message-header-text">{userData.nickname}</span>
                                                        </div>
                                                        <span className="message-text"
                                                              dangerouslySetInnerHTML={{__html: message.message}}/>
                                                        <div className="message-footer">{message.date}</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div id="other-message-container">
                                                    <div className="message-content">
                                                        <div className="message-header">
                                                            <span
                                                                className="message-header-text">{getMemberByID(message.sender).nickname}</span>
                                                        </div>
                                                        <span className="message-text"
                                                              dangerouslySetInnerHTML={{__html: message.message}}/>
                                                        <div className="message-footer">{message.date}</div>
                                                    </div>
                                                </div>
                                            )}
                                            </>
                                        ) : (
                                            <div className="system-message-container">
                                                <span className="system-message">{message.message}</span>
                                            </div>
                                        )}
                                    </>
                                ))}
                                <div id="chatend" ref={messagesEndRef}/>
                            </div>
                        </div>
                        <div className="chat-input-container">
                            <textarea id="dev-chat-input-area" value={message} onKeyDown={(e) => handleKeyDown(e)}
                                      onChange={(e) => setMessage(e.target.value)} disabled={selectedChat.status === 1}
                                      placeholder="Введите сообщение"></textarea>
                            <button className="send-message-button" disabled={!message.trim()} onClick={() => handleMessage()}><i className="bi bi-send-fill"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default OpenedChat;
