import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";
import pfp from "../pfps/0.jpg";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

function AvatarSelection({backButton, sendData, userData, sendNotification}) {

    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const cropperRef = useRef(null);

    const sendPfp = async (mode, filename, fileBlob) => {
        const url = "https://kfbase.xyz/Server/" + filename + ".php";
        const formData = new FormData();
        formData.append('mode', mode);
        formData.append('avatar', fileBlob);

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
                credentials: 'include'
            });
            const responseBody = await response.text();
            const responseData = JSON.parse(responseBody);

            if (responseData.response_code === 418 || responseData.response_code === 200){
                sendNotification(responseData.response_code, responseData.message);
                if(responseData.response_code === 200){
                    window.location.reload();
                }
            }

            return responseData;
        } catch (error) {
            throw error;
        }
    }

    const photoSelectionEvent = (event) => {
        const file = event.target.files[0];
        setSelectedPhoto(URL.createObjectURL(file));

    };

    const getCroppedImage = async () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        if (typeof cropper.getCroppedCanvas() === 'undefined') {
            return;
        }
        cropper.getCroppedCanvas().toBlob(async (blob) => {
            if (!blob) {
                console.error('Не удалось получить изображение');
                return;
            }
            await sendPfp("SAVE_USER_PFP", "PfpWorker", blob);
        });
    };

    useEffect(() => {
        return () => {
            if (selectedPhoto) {
                URL.revokeObjectURL(selectedPhoto);
            }
        };
    }, [selectedPhoto]);

    const removePhoto = () =>
    {
        setSelectedPhoto(null);
    }
    const saveCroppedImage = async () => {
        await getCroppedImage();
    }

    return (
        <>
            <div className="darker"></div>
            <div id="avatar-selection-container">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="avatar-selection-header">Смена фотографии профиля</span>
                {selectedPhoto === null ? (
                    <div className="new-photo-place">
                        {!userData.pfpBan ? (
                            <>
                                <span
                                    className="photo-container-text">Вы можете изменить свою фотографию профиля.</span>
                                <span className="photo-container-text">Выберите фотографию, которую хотите установить как фото профиля.</span>
                                <input type="file" id="actual-btn" hidden onChange={photoSelectionEvent}/>

                                <label htmlFor="actual-btn">Выберите фотографию</label>
                            </>
                        ) : (
                            <>
                                <span
                                    className="photo-container-text">Вы не можете установить новое фото профиля.</span>
                                <span
                                    className="photo-container-text">На данный момент у Вас есть активная блокировка.</span>
                            </>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="main-photo-container">
                            <div className="photo-container">
                                {selectedPhoto && (
                                    <Cropper
                                        src={selectedPhoto}
                                        style={{height: 400, width: '100%'}}
                                        initialAspectRatio={1}
                                        aspectRatio={1}
                                        guides={false}
                                        ref={cropperRef}
                                        viewMode={1}
                                        autoCropArea={1}
                                        background={false}
                                    />
                                )}
                            </div>
                            <div className="buttons-container">
                                <button id="save-photo" onClick={saveCroppedImage}>Сохранить изображение</button>
                                <button id="cancel-photo" onClick={removePhoto}>Убрать изображение</button>
                            </div>
                        </div>
                    </>
                )}

            </div>
        </>
    );
}

export default AvatarSelection;
