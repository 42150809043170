import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function StageEditPopup({backButton, selectedTask, stages, saveStageChanges, deleteStage}) {

    const [name, setName] = useState(selectedTask.name);
    const [stageErr,setStageErr] = useState("");


    const resetChanges = () => {
        setName(selectedTask.name);
    }

    const handleSaveStage = () => {
        saveStageChanges({stage: selectedTask.stage, name: name});
    }

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Изменение этапа</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <input type="text" id="point-answer-input" value={name} autoComplete={"off"} onChange={(e) => setName(e.target.value)}/>

                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={handleSaveStage}><i className="bi bi-send-fill"></i> Сохранить изменения</button>
                    <button id="delete-task" onClick={() => deleteStage(selectedTask.stage)}><i className="bi bi-trash-fill"></i> Удалить этап</button>
                </div>
            </div>
        </>
    );
}

export default StageEditPopup;
