import "../styles/tasks.css"
import {useEffect, useState} from "react";
import CreateLinkPopup from "./CreateLinkPopup";
import EditUsefulLink from "./EditUsefulLink";

function Warrants({sendData, userData, members, isValidURL, shouldDisplay, openLink}) {

    const [usefulLinks, setUsefulLinks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTask, setSelectedTask] = useState({});
    const itemsPerPage = 8;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    const [editLinkPopup, setEditLinkPopup] = useState(false);
    const [createLinkPopup, setCreateLinkPopup] = useState(false);

    useEffect(() => {
        const getLinks = async () => {
            const data = await sendData("GET_USEFUL_LINKS", "UserManager", {});
            setUsefulLinks(data.data);
        }

        getLinks();
    }, []);

    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(usefulLinks.slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(usefulLinks.length / itemsPerPage));
    }, [currentPage, usefulLinks]);

    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' };
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    const updateUsefullLinks = (data, mode) => {
        if(mode === "edit") {
            setUsefulLinks(usefullLinks => {
                const index = usefullLinks.findIndex(task => task.ID === data.ID);
                if (index !== -1) {
                    return [
                        ...usefullLinks.slice(0, index),
                        {...usefullLinks[index], ...data},
                        ...usefullLinks.slice(index + 1)
                    ];
                }
                return usefullLinks;
            });
        }else if(mode === "add"){
            setUsefulLinks(previousTasks => [data, ...previousTasks]);
        }
    }

    const closePopups = () => {
        setCreateLinkPopup(false);
        setEditLinkPopup(false);
    }

    const handleEditLink = async (data) => {
        const response = await sendData("EDIT_USEFUL_LINK", "UserManager", data);
        if(response.response_code === 200){
            updateUsefullLinks(data, "edit");
            closePopups();
        }
    }

    const handleDeleteLink = async (data) => {
        const response = await sendData("DELETE_USEFUL_LINK", "UserManager", data);
        if(response.response_code === 200){
            data.deleted = 1;
            updateUsefullLinks(data, "edit");
            closePopups();
        }
    }

    const handleCreateLink = async (data) => {
        const response = await sendData("CREATE_USEFUL_LINK", "UserManager", data);
        if(response.response_code === 200){
            data.ID = response.lastID;
            data.createdBy = userData.ID;
            data.deleted = 0;
            updateUsefullLinks(data, "add");
            closePopups();
        }
    }

    const selectLink = (link) => {
        setSelectedTask(link);
        setEditLinkPopup(true);
    }


    return (
        <>
            {createLinkPopup && (
                <CreateLinkPopup isValidURL={isValidURL} createLink={handleCreateLink} backButton={closePopups} />
            )}
            {editLinkPopup && (
                <EditUsefulLink backButton={closePopups} isValidURL={isValidURL} editLink={handleEditLink} selectedLink={selectedTask} handleDeleteLink={handleDeleteLink}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i className="bi bi-link-45deg"></i> Полезные ссылки</span>
                    <>
                        <div className="not-done-tasks-container">
                            <div className="table-label">
                                <span className="table-label-text">Useful Links</span>
                            </div>
                            {shouldDisplay(userData.admin > 0, userData.academyPerm > 0) && (
                                <button id="new-task" onClick={() => setCreateLinkPopup(true)} style={{marginBottom: '1%'}}><i
                                    className="bi bi-pen-fill"></i> Создать новую ссылку
                                </button>
                            )}
                            <div className="tasks-table-container">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>#ID</th>
                                        <th>Ссылка</th>
                                        <th>Создано пользователем</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentItems.filter(l => l.deleted === 0).map((link, index) => (
                                        <tr key={index} id="table-row" onClick={(e) => selectLink(link)}>
                                            <th>{index + 1}</th>
                                            <th><span className="link"
                                                      onClick={(e) => openLink(link.link, e)}>{link.name}</span></th>
                                            <td id="table-user-container">
                                                <img src={getMemberByID(link.createdBy).avatar} id="table-user-pfp"></img>
                                                <span className="table-user-name">{getMemberByID(link.createdBy).nickname}</span>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                {currentPage > 1 && (
                                    <i className="bi bi-arrow-left"
                                       onClick={handleBackPage}></i>
                                )}
                                <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                {totalPages > 0 && (
                                    <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                )}
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
}

export default Warrants;
