import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function CreateLinkPopup({backButton, createLink, isValidURL}) {

    const [name, setName] = useState("");
    const [link, setLink] = useState("");

    const [nameErr, setNameErr] = useState("");
    const [linkErr, setLinkErr] = useState("");

    const handleCreateLink = () => {
        let error = false;
        if(name.length < 4 || nameErr.length > 64) {
            error = true;
            setNameErr("Название не может быть меньше 4 и больше 64 символов.");
        }
        if(!isValidURL(link)){
            error = true;
            setLinkErr("Недопустимая ссылка.");
        }

        if(error) return;

        createLink({name: name, link: link});
    }

    useEffect(() => {
        setNameErr("");
        setLinkErr("");
    }, [name, link]);

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Создание полезной ссылки</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <input type="text" className={nameErr.length > 0 ? "invalid" : ""} id="point-answer-input" value={name} autoComplete={"off"}
                           onChange={(e) => setName(e.target.value)}/>
                    {nameErr.length > 0 && (
                        <span className="error-text">{nameErr}</span>
                    )}

                    <span className="point-header">Ссылка</span>
                    <input type="text" className={linkErr.length > 0 ? "invalid" : ""} id="point-answer-input" value={link} autoComplete={"off"}
                           onChange={(e) => setLink(e.target.value)}/>
                    {linkErr.length > 0 && (
                        <span className="error-text">{linkErr}</span>
                    )}


                </div>

                <div className="buttons-container">
                    <button id="send-task" onClick={() => handleCreateLink()}><i className="bi bi-send-fill"></i> Создать ссылку
                    </button>
                </div>
            </div>
        </>
    );
}

export default CreateLinkPopup;
