import "../styles/tasks.css"
import {useEffect, useState} from "react";
import DivisionTaskEditPopup from "./DivisionTaskEditPopup";
import DivisionTaskCreatePopup from "./DivisionTaskCreatePopup";

function DivisionTasks({userData, updateDivisionTasks, sendData, members, divisionTasks, shouldDisplay, divisions}) {

    const [selectedCategory, setSelectedCategory] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [tasks, setTasks] = useState([]);
    const itemsPerPage = 8;
    const [currentItems, setCurrentItems] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedTask, setSelectedTask] = useState({});
    const [taskEditPopup, setTaskEditPopup] = useState(false);
    const [taskCreatePopup, setTaskCreatePopup] = useState(false);


    const handleNextPage = () => {
        if(currentPage + 1 <= totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const getMemberByID = (id) => {
        const member = members.find(member => member.ID === id);
        return member || { nickname: 'Unknown' };
    }

    const handleBackPage = () => {
        if(currentPage - 1 > 0){
            setCurrentPage(currentPage - 1);
        }
    }

    useEffect(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(divisionTasks.filter(task => task.division === (selectedCategory === 0 ? 2 : selectedCategory === 1 ? 3 : 4)).slice(indexOfFirstItem, indexOfLastItem));
        setTotalPages(Math.ceil(divisionTasks.filter(task => task.division === (selectedCategory === 0 ? 2 : selectedCategory === 1 ? 3 : 4)).length / itemsPerPage));
    }, [currentPage, divisionTasks, selectedCategory]);

    const handleSelectEditDivisionTask = (task) => {
        setSelectedTask(task);
        setTaskEditPopup(true);
    }

    const saveChangesDivisionTask = async (data) => {
        const response = await sendData("EDIT_DIVISION_TASK", "UserManager", data);
        if(response.response_code === 200){
            updateDivisionTasks(data, "edit");
            closePopups();
        }
    }

    const deleteDivisionTask = async (id) => {
        const response = await sendData("DELETE_DIVISION_TASK", "UserManager", {taskID: id});
        if(response.response_code === 200){
            updateDivisionTasks({taskID: id, status: 3}, "edit");
            closePopups();
        }
    }

    const closePopups = () => {
        setTaskEditPopup(false);
        setTaskCreatePopup(false);
    }

    const createNewDivisionTask = async (data) => {
        data.division = selectedCategory + 2;
        const response = await sendData("CREATE_NEW_DIVISION_TASK", "UserManager", data);
        if(response.response_code === 200){
            data.ID = response.lastID;
            data.status = 0;
            updateDivisionTasks(data, "add");
            closePopups();
        }
    }

    return (
        <>
            {taskEditPopup && (
                <DivisionTaskEditPopup deleteDivisionTask={deleteDivisionTask} selectedTask={selectedTask} backButton={closePopups} saveChangesDivisionTask={saveChangesDivisionTask}/>
            )}
            {taskCreatePopup && (
                <DivisionTaskCreatePopup createDivisionTask={createNewDivisionTask} backButton={closePopups}/>
            )}
            <div id="main-page">
                <div className="main-tasks-container">
                    <span className="tasks-header"><i className="bi bi-person-lines-fill"></i> Задания отделов</span>
                    <div className="task-category-list">
                        {shouldDisplay(userData.admin > 0, userData.allDivisionCheckPerm > 0, (userData.divisionCheck > 0 && userData.division === 2)) && (
                            <div className={`category-option ${selectedCategory === 0 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(0)}>CCRSB
                            </div>
                        )}
                        {shouldDisplay(userData.admin > 0, userData.allDivisionCheckPerm > 0, (userData.divisionCheck > 0 && userData.division === 3)) && (
                            <div className={`category-option ${selectedCategory === 1 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(1)}>HRB [TD]
                            </div>
                        )}
                        {shouldDisplay(userData.admin > 0, userData.allDivisionCheckPerm > 0, (userData.divisionCheck > 0 && userData.division === 4)) && (
                            <div className={`category-option ${selectedCategory === 2 ? "selected" : ""}`}
                                 onClick={() => setSelectedCategory(2)}>HRB [SD]
                            </div>
                        )}
                    </div>
                    <button id="new-task" onClick={() => setTaskCreatePopup(true)}><i
                        className="bi bi-pen-fill"></i> Создать новое задание
                    </button>
                        <>
                            <div className="not-done-tasks-container">
                                <div className="table-label">
                                    <span className="table-label-text">{divisions[selectedCategory + 2]} Assignments</span>
                                </div>
                                <div className="tasks-table-container">
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>Название</th>
                                            <th>Описание</th>
                                            <th>Выполнить до</th>
                                            <th>Статус</th>
                                            <th>Создано пользователем</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {currentItems.filter(t => t.status < 3).map((task, index) => (
                                            <tr key={index} id="table-row"
                                                onClick={() => handleSelectEditDivisionTask(task)}>
                                                <th>{index + 1}</th>
                                                <th>{task.name}</th>
                                                <th id="description">{task.description}</th>
                                                <th>{task.doneTo}</th>
                                                <th className={`status ${task.status === 0 ? "not-started" : task.status === 1 ? "on-verify" : "done"}`}>
                                                    <div className="status-indicator"></div>
                                                    {task.status === 0 ? "Не начато" : task.status === 1 ? "На проверке" : "Выполнено"}
                                                </th>
                                                <th>{getMemberByID(task.createdBy).nickname}</th>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                {currentPage > 1 && (
                                        <i className="bi bi-arrow-left"
                                           onClick={handleBackPage}></i>
                                    )}
                                    <span className="pagination-text">Страница {currentPage} из {totalPages}</span>
                                    {totalPages > 0 && (
                                        <i className="bi bi-arrow-right" onClick={handleNextPage}></i>
                                    )}
                                </div>
                            </div>
                        </>
                </div>
            </div>
        </>
    );
}

export default DivisionTasks;
