import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function SendVerifyTask({backButton, selectedTask, sendVerifyTask, openLink}) {

    const [proofs, setProofs] = useState("");
    const [links, setLinks] = useState([]);

    useEffect(() => {
        const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        const foundLinks = selectedTask.description.match(urlPattern) || [];
        setLinks(foundLinks);
      }, [selectedTask.description]);

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup">
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header">Задание</span>
                <div className="task-container">
                    <span className="point-header">Название</span>
                    <span className="point-answer">{selectedTask.name}</span>

                    <span className="point-header">Описание</span>
                    <span className="point-answer">{selectedTask.description}</span>

                    {links.length > 0 && (
                        <>
                        <span className="point-header">Ссылки</span>
                        {links.map((link, index) => 
                            <span className="point-answer link" onClick={(e) => openLink(link, e)}>{link}</span>
                        )}
                        </>
                    )}

                    <span className="point-header">Максимальный балл</span>
                    <span className="point-answer">{selectedTask.maxPoints}</span>

                    <span className="point-header">Доказательства/примечания</span>
                    <textarea id="user-proofs" value={proofs} onChange={(e) => setProofs(e.target.value)}/>
                </div>
                <div className="buttons-container">
                    <button id="send-task" onClick={() => sendVerifyTask(selectedTask, proofs)}><i className="bi bi-send-fill"></i> Отправить на проверку</button>
                </div>
            </div>
        </>
    );
}

export default SendVerifyTask;
