import "../styles/popup.css"
import {useEffect, useRef, useState} from "react";

function PfpRules({backButton}) {

    return (
        <>
            <div className="darker"></div>
            <div id="send-verify-popup" style={{width: '40%'}}>
                <div className="back-button" onClick={backButton}>
                    <i className="bi bi-arrow-left"></i>
                </div>
                <span className="send-verify-header" style={{marginLeft: '10%'}}>Правила изображений профиля</span>
                <div className="task-container-scrollable">
                    <div className="task-container">
                        <span className="rules-article-header">1. Отсутствие неприемлемого контента</span>
                        <span className="rules-article-text">• Запрещается использование аватаров, изображающих или содержащих наготу, порнографический материал или сексуально недвусмысленные изображения.</span>
                        <span className="rules-article-text">• Аватары не должны содержать изображения или символы, которые пропагандируют насилие, ненависть, дискриминацию, расизм, ксенофобию, агрессивный национализм или экстремизм.</span>

                        <span className="rules-article-header">2. Запрет экстремистской символики</span>
                        <span className="rules-article-text">• Строго запрещено использование символов, связанных с экстремистскими или террористическими организациями, включая, но не ограничиваясь, нацистскую символику и прочие подобные знаки.</span>

                        <span className="rules-article-header">3. Уважение частной жизни</span>
                        <span className="rules-article-text">• Не допускается использование изображений, которые могут вторгаться в личную жизнь или нарушать конфиденциальность других лиц без их явного согласия.</span>

                        <span className="rules-article-header">4. Соблюдение правил сайта</span>
                        <span className="rules-article-text">• Все аватары подлежат проверке администрацией сайта. Администрация оставляет за собой право в любое время удалить аватар, который посчитает несоответствующим вышеуказанным правилам, без предварительного уведомления пользователя.</span>

                    </div>
                </div>
            </div>
        </>
    );
}

export default PfpRules;
