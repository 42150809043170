import "./styles/login.css"
import {useEffect, useState} from "react";

function ErrorRaised({closeError}) {

    return (
        <>
            <div className="darker" onClick={() => closeError()}></div>
            <div id="error-raised">
                <span className="error-raised-header">Произошла ошибка на стороне сервера!</span>
                <span className="error-raised-text">Отчет о ошибке был передан разработчику. Приносим свои извинения!</span>
            </div>
        </>
    );
}

export default ErrorRaised;
